namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosUsersVM = function() {
	var self = this;
	self.posId = null;
	var subscriptions = [];

	var PAGE_SIZE = 10;
	this.totalEmployeesCount = ko.observable(0);
	this.posEmployees = ko.observableArray([]);

	this.pagination = new oga.component.pagination.Pagination({
		direction: "asc",
		pageSize: PAGE_SIZE
	});	

	self.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	this.init = function(params) {
		self.posId = params.id;
		subscriptions.push(ko.computed(function() {
			self.loadUsers();
		}).extend({
			deferred: true
		}));
	};

	this.loadUsers = function() {
		self.posEmployees([]);
		oga.restClient.getResource("api/points-of-sale/" + self.posId + "/users", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction()
		}).done(function(resp) {
			self.totalEmployeesCount(resp.count);
			self.posEmployees(ko.utils.arrayMap(resp.items, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
		});
	};

};
