namespace("oga.nomenclature");

oga.nomenclature.Roles = {
	ROLE_POINT_OF_SALE_ADMIN: "ROLE_POINT_OF_SALE_ADMIN",
	ROLE_POINT_OF_SALE_CASHIER: "ROLE_POINT_OF_SALE_CASHIER",
	ROLE_LOTTERY_ORGANIZATION: "ROLE_LOTTERY_ORGANIZATION",
	ROLE_LOTTERY_ORGANIZATION_REGULATOR: "ROLE_LOTTERY_ORGANIZATION_REGULATOR",
	ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT: "ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT",
	ROLE_LOTTERY_ORGANIZATION_LOGISTICS: "ROLE_LOTTERY_ORGANIZATION_LOGISTICS",
	ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE: "ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE"
};
