namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosWinningsVM = function() {
	var self = this;
	var subscriptions = [];
	var posId = null;
	var PAGE_SIZE = 10;

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE
	});
	this.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	this.posUsers = ko.observableArray([]);
	this.userOptions = ko.pureComputed(function() {
		var users = self.posUsers();
		return [{
			fullName: i18next.t("all"),
			id: null
		}].concat(users);
	});
	this.selectedUser = ko.observable(self.userOptions()[0]);
	this.posWinningsAll = ko.observableArray([]);
	this.posWinnings = ko.observableArray([]);
	this.totalUserWinningTickets = ko.observable();
	this.userWinningsByTickets = ko.observableArray([]);
	this.userWinningsByGames = ko.observableArray([]);
	this.showPopup = ko.observable(false);
	this.userInPopup = ko.observable();
	this.userNameInPopup = ko.observable();
	this.isTicketsLoading = ko.observable(false);

	this.isAdminOrPosAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	this.init = function(params) {
		posId = params.id;

		subscriptions.push(self.selectedUser.subscribe(function(selectedUser) {
			filterPaidWinnings(selectedUser.id);
		}));

		subscriptions.push(ko.computed(function() {
			loadCashierWinnings();
		}).extend({deferred: true}));

		subscriptions.push(ko.computed(function() {
			loadUsers();
			loadPaidWinnings();
		}).extend({
			deferred: true
		}));
	};

	function loadPaidWinnings() {
		oga.restClient.getResource("api/points-of-sale/" + posId + "/pos-winnings").done(function(resp) {
			self.posWinningsAll(ko.utils.arrayMap(resp, function(winningDto) {
				return new oga.model.PosPaidWinning(winningDto);
			}));
			self.posWinnings(self.posWinningsAll());
		});
	}

	function filterPaidWinnings(userId) {
		var filtered = self.posWinningsAll();
		if (userId != null) {
			filtered = self.posWinningsAll().filter(function (el) {
				return el.userId == userId;
			});
		}
		self.posWinnings(filtered);
	}

	function loadUsers() {
		self.posUsers([]);
		oga.restClient.getResource("api/points-of-sale/" + posId + "/users").done(function(posEmployeeDtos) {
			self.posUsers(ko.utils.arrayMap(posEmployeeDtos, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
		});
	}

	this.openCashierWinnings = function(userId, userName) {
		self.userNameInPopup(userName);
		self.userInPopup(userId);
		self.showPopup(true);
		loadCashierWinnings();
	};

	function loadCashierWinnings() {
		if (self.userInPopup() == null) {
			return;
		}
		self.isTicketsLoading(true);
		oga.restClient.getResource("api/points-of-sale/" + posId + "/user-winnings/" + self.userInPopup(), {
			page: self.pagination.page(),
			pageSize: PAGE_SIZE
		}).done(function(resp) {
			self.totalUserWinningTickets(resp.totalWinCount);
			self.userWinningsByTickets(ko.utils.arrayMap(resp.tickets, function(dto) {
				return new oga.model.PosPaidWinningUserTicket(dto);
			}));

			self.userWinningsByGames(ko.utils.arrayMap(resp.games, function(dto) {
				return new oga.model.PosPaidWinningUserGame(dto);
			}));
		}).always(function() {
			self.isTicketsLoading(false);
		});
	}

	this.closeCashierWinnings = function() {
		self.showPopup(false);
		self.userInPopup(null);
	};

	this.dispose = function() {
		subscriptions.forEach(function(subscription) {
			subscription.dispose();
		});
	};
};
