namespace("oga.nomenclature");

oga.nomenclature.InventorizationStatusType = {
	1: {
		id: 1,
		dbName: "IN_PROGRESS",
		transKey: "inventorization.status.in-progress",
		cssClass: "label-info"
	},
	2: {
		id: 1,
		dbName: "COMPLETED",
		transKey: "inventorization.status.completed",
		cssClass: "label-success"
	},
	3: {
		id: 1,
		dbName: "COMPLETED_WITH_MISSING_BARCODES",
		transKey: "inventorization.status.completed-with-missing",
		cssClass: "label-danger"
	},

	get IN_PROGRESS() {
		return this[1];
	},
	get COMPLETED() {
		return this[2];
	},
	get COMPLETED_WITH_MISSING_BARCODES() {
		return this[3];
	},
	get ALL() {
		return [this[1], this[2], this[3]];
	}

};

