namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.RedemptionRequestsVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;
	var PAGE_SIZE = 15;
	var requestStatus = oga.nomenclature.RequestStatusType;
	
	this.statusOptions = ko.observableArray([
		requestStatus.WAITING, requestStatus.APPROVED
	]);
	
	self.dateRange = new oga.model.DateRange(moment().subtract(6, "days"), moment());
	self.selectedStatus = ko.observable(undefined);
	self.redemptionRequests = ko.observableArray([]);
	self.tags = ko.observableArray([]);
	self.posNameIdParam = ko.observable();
	
	self.isLoading = ko.observable(false);

	self.redemptionRequestsCount = ko.observable();
	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "createdAt",
		direction: "desc",
		pageSize: PAGE_SIZE
	});	

	this.init = function() {
		subscriptions.push(self.selectedStatus.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.dateRange.fromDate.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.dateRange.toDate.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadRedemptionRequests();
		}).extend({
			deferred: true
		}));
	};

	function loadRedemptionRequests() {
		self.isLoading(true);
		restClient.getResource("/api/redemption-requests", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			statusId: self.selectedStatus(),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam(),
			fromDate: self.dateRange.fromDate().format(oga.settings.momentRequestFormat),
			toDate: self.dateRange.toDate().format(oga.settings.momentRequestFormat)
		}).done(function(resp) {
			self.redemptionRequests(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.RedemptionRequest(dto);
			}));
			self.redemptionRequestsCount(resp.count);
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.exportExcel = function() {
		var params = {
			statusId: self.selectedStatus(),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam(),
			fromDate: self.dateRange.fromDate().format(oga.settings.momentRequestFormat),
			toDate: self.dateRange.toDate().format(oga.settings.momentRequestFormat),
			lang: oga.util.i18n.getLanguage()
		};

		var urlQueryParams = $.param(params, true);
		location.href = "/api/redemption-requests/xls?" + urlQueryParams;
	};

	this.canOpen = ko.computed(function() {
		return oga.authenticatedUser().authority != oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE;
	});

	self.openRedemption = function(id) {
		//TODO add details to the setHash so the redirect is to the particular redemption and remove the console.log which is an eslint hack
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "redemption-request-open/" + id);
	};
	
	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
