namespace("oga.public");

oga.public.PublicCheckVM = function() {
	var self = this;
	var subscriptions = [];

	this.recaptcha = ko.observable("");

	this.barcodeInput = ko.observable("");
	this.winning = ko.observable(0);

	this.hasResult = ko.observable(false);
	this.invalidVerificationCode = ko.observable(false);

	this.gamesWinnings = ko.observableArray([]);
	this.highestWinAmount = ko.observable(0);

	this.isLoading = ko.observable(false).extend({
		rateLimit: {
			timeout: 200,
			method: "notifyWhenChangesStop"
		},
		notify: "always"
	});
	this.isChecking = ko.observable(false).extend({
		rateLimit: {
			timeout: 200,
			method: "notifyWhenChangesStop"
		},
		notify: "always"
	});

	this.init = function () {
		subscriptions.push(ko.computed(function () {
			loadGamesWinnings();
		}).extend({
			deferred: true
		}));
	};

	function loadGamesWinnings() {
		self.isLoading(true);
		$.ajax({
			url: "api/public/winnings",
			type: "GET"
		}).done(function (resp) {
			self.gamesWinnings(ko.utils.arrayMap(resp, function (dto) {
				return new oga.model.PublicRemainingGamesWinnings(dto);
			}));
			self.calcHighestWinAmount();
		}).always(function () {
			self.isLoading(false);
		});
	}

	this.validate = function () {
		var barcodeInput = self.barcodeInput();

		if (!barcodeInput) {
			self.invalidVerificationCode(true);
			return;
		}
		window.grecaptcha.execute();
	};

	window.check = function (recaptcha) {
		var csrfToken = $.cookie("XSRF-TOKEN");

		var data = {
			"captchaResponse": recaptcha
		};
		self.isChecking(true);
		$.ajax({
			url: "api/public/" + self.barcodeInput() + "/winning",
			data: data,
			type: "GET",
			headers: {
				"X-XSRF-TOKEN": csrfToken
			}
		}).done(function (resp) {
			self.winning(resp.winning.value);
			self.hasResult(true);

		}).fail(function (resp) {
			if (resp && resp.responseJSON) {
				if (resp.responseJSON.error == "InvalidTicketVerificationNumberException") {
					self.invalidVerificationCode(true);
				} else if (resp.responseJSON.error == "InvalidTicketVerificationNoWinException") {
					self.hasResult(true);
					self.winning(0);
				} else if (resp.responseJSON.error == "InvalidCaptchaException") {
					window.grecaptcha.reset();
				}
			}
		}).always(function () {
			self.isChecking(false);
		});
		window.grecaptcha.reset();
	};

	this.clearResult = function () {
		self.hasResult(false);
		self.barcodeInput("");
		self.invalidVerificationCode(false);
		self.winning(0);
	};

	this.calcHighestWinAmount = function () {
		var highest = Math.max(...ko.utils.arrayMap(self.gamesWinnings(), function (winning) {
			return winning.highestWinning;
		}));
		self.highestWinAmount(highest);
	};
};

