namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.GamesVM = function() {
	var self = this;
	var GAMES_PAGE_SIZE = 15;
	var GameStatus = oga.nomenclature.GameStatus;

	var subscriptions = [];
	var timeouts = [];

	this.gameStatusesOptions = ko.observableArray([
		GameStatus.ACTIVE, GameStatus.INACTIVE, GameStatus.WAITING
	]);

	this.selectedGameStatus = ko.observable(GameStatus.ACTIVE);
	this.displayedGames = ko.observableArray([]);
	this.totalSpecifiedGamesCount = ko.observable(0);
	this.pagination = new oga.component.pagination.Pagination({
		pageSize: GAMES_PAGE_SIZE
	});
	this.isLoading = ko.observable(false);
	this.gameListRequestParams = ko.computed(function() {
		var pageQueryParams = self.pagination.queryParamsObject();
		var gameListRequestParams = $.extend(pageQueryParams, {
			statusId: self.selectedGameStatus().id
		});

		return gameListRequestParams;
	}).extend({
		deferred: true
	});

	subscriptions.push(self.gameListRequestParams);

	this.checkGame = function(game) {
		var currentCheckedState = game.isChecked();
		var newCheckedState = !currentCheckedState;
		self.isLoading(true);
		oga.restClient.patchResource("api/games/" + game.id + "/enabled", JSON.stringify({
			isEnabled: newCheckedState
		})).done(function() {
			game.isStatusModifiable(false);
			if (newCheckedState) {
				game.status(GameStatus.ACTIVE);
			} else {
				game.status(GameStatus.INACTIVE);
			}
			game.isChecked(newCheckedState);

			timeouts.push(setTimeout(function() {
				if (self.displayedGames().length <= 1 && self.pagination.page() > 1) {
					self.pagination.page(self.pagination.page() - 1);
				} else {
					loadGames(self.gameListRequestParams());
				}
			}, 1500));
		}).always(function() {
			self.isLoading(false);
		});
	};

	this.init = function() {
		subscriptions.push(self.gameListRequestParams.subscribe(function(gameListRequestParams) {
			loadGames(gameListRequestParams);
		}));

		subscriptions.push(self.selectedGameStatus.subscribe(function() {
			self.pagination.page(1);
		}));

		loadGames(self.gameListRequestParams());
	};


	this.goToGameProfiles = function(game) {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "game-profiles", {
			gameId: game.id
		});
	};

	function loadGames(gameListRequestParams) {
		self.isLoading(true);
		oga.restClient.getResource("api/games?form=detailed", gameListRequestParams).done(function(resp) {
			self.totalSpecifiedGamesCount(resp.count);
			self.displayedGames(ko.utils.arrayMap(resp.items, function(gameDto) {
				return new oga.model.Game(gameDto);
			}));
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});

		$.each(timeouts, function(index, timeout) {
			clearTimeout(timeout);
		});
	};
};
