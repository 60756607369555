namespace("oga.component");
oga.component.TransactionCreationDialog = function(params) {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var PAGE_SIZE = 5;

	checkParams();
	self.isLoading = ko.observable(false);
	self.isPointOfSaleDisplayed = ko.observable(true);
	self.posNameIdParam = ko.observable();

	self.tags = ko.observableArray([]);
	self.pointsOfSale = ko.observableArray([]);
	self.checkedTransactionType = ko.observable("deposit");
	self.selectedPosId = ko.observable(0);
	self.amount = ko.observable().extend({required: true, min: 0.01, pattern: /^\d{1,12}(\.\d{1,2})?$/});

	self.hasSelectedPos = ko.computed(function() {
		return self.selectedPosId() > 0;
	}).extend({
		required: true,
		pattern: "^true$"
	});
	// self.hasEnteredAmount = ko.computed(function() {
	// 	return (self.amount.isValid() && self.checkedTransactionType());
	// }).extend({
	// 	required: true,
	// 	pattern: "^true$"
	// });

	subscriptions.push(ko.computed(function() {
		loadPos();
	}));
	
	function loadPos() {
		self.isLoading(true);
		self.selectedPosId(0);
		var url = "/api/points-of-sale";
		restClient.getResource(url, {
			page: 1,
			pageSize: PAGE_SIZE,
			orderBy: "name",
			direction: "desc",
			pointOfSaleTags: self.tags(),
			nameOrId: self.posNameIdParam()
		}).done(function(resp) {
			if (resp.count == 0) {
				self.isPointOfSaleDisplayed(false);
			} else {
				self.isPointOfSaleDisplayed(true);
			}
			if (resp.count == 1) {
				self.selectedPosId(resp.items[0].id);
			}
			self.pointsOfSale(resp.items);
		}).always(function() {
			self.isLoading(false);
		}); 
	}

	self.close = function() {
		params.onCloseClicked();
	};
	self.save = function() {
		var validationErrors = ko.validation.group([self.hasSelectedPos, self.amount]);
		if (ko.unwrap(validationErrors()).length > 0) {
			validationErrors.showAllMessages();
			return;
		}

		var query = "/api/points-of-sale/{0}/transactions/{1}";
		var url = pastel.util.StringHelper.format(query, self.selectedPosId(), self.checkedTransactionType());
		var dataToSend = JSON.stringify({amount: self.amount()});
		restClient.postResource(url, dataToSend).done(function() {
			params.onTransactionCreated();
		}).handleErrors({
			InsufficientPosBalanceException: function() {
				oga.popupManager.error(
					"insufficient.pos.balance.for.transaction");
			}
		});
	};

	function checkParams() {
		if (!$.isFunction(params.onCloseClicked) || !$.isFunction(params.onTransactionCreated)) {
			throw "Invalid component params. Expected callback functions";
		}
	}
	
	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
