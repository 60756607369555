namespace("oga.model");

oga.model.PaginatedArrayModel= function(initialState) {
	var self = this;
	//	var subscriptions = []
	var defaultState = {
		page: 1,
		pageSize: 15
	};
	
	if (initialState !== undefined) {
		initialState = $.extend({}, defaultState, initialState);
	} else {
		initialState = defaultState;
	}
	
	var items = ko.observableArray(initialState.items);
	this.pageSize = ko.observable(initialState.pageSize);
	this.page = ko.observable(initialState.page);
	//	this.orderBy = ko.observable(initialState.orderBy);
	//	this.direction = ko.observable(initialState.direction);
	this.itemsOnCurrentPage = ko.pureComputed(function() {
		return items.slice((self.page() - 1) * self.pageSize(), self.page() * self.pageSize());		
	}).extend({
		deferred: true
	});

	this.maxPage = ko.pureComputed(function() {
		getMaxPage();
	});
	function getMaxPage() {
		var resultsCount = self.itemsCount();
		var pageSize = parseInt(self.pageSize());
		if (resultsCount <= 0) {
			return 1;
		}
		return Math.ceil(resultsCount / pageSize);
	}

	this.firstItemNumber = ko.pureComputed(function() {
		return (this.pageSize() * (this.page() - 1)) + 1;
	}, this);

	this.lastItemNumber = ko.pureComputed(function() {
		return (this.firstItemNumber() - 1) + parseInt(this.pageSize());
	}, this);

	this.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return self.pageSize() * (self.page() - 1);
	});

	this.getItems = function() {
		return items();
	};

	this.addItem = function(item) {
		items.unshift(item);
	};

	this.removeItem = function(item) {
		if (self.itemsOnCurrentPage().length == 1 && self.page() > 1 && self.page() == getMaxPage()) {
			self.page(self.page() - 1);
		}
		items.remove(item);
	};

	this.clear = function() {
		items([]);
	};

	this.itemsCount = ko.pureComputed(function() {
		return items().length;
	});

	//	function sortArray() {
	//		if (self.direction() == "asc"){
	//			items().sort(function(firstItem, secondItem) {
	//				if (firstItem[self.orderBy()] < secondItem[self.orderBy()]) {
	//					return -1;
	//				}
	//				if (firstItem[self.orderBy()] > secondItem[self.orderBy()]) {
	//					return 1;
	//				}
	//				return 0;
	//			});
	//		} else {
	//			items().sort(function(firstItem, secondItem) {
	//				if (firstItem[self.orderBy()] > secondItem[self.orderBy()]) {
	//					return -1;
	//				}
	//				if (firstItem[self.orderBy()] < secondItem[self.orderBy()]) {
	//					return 1;
	//				}
	//				return 0;
	//			});
	//		}
	//		items.valueHasMutated();
	//	};
	//
	//	this.toggleOrder = function(orderBy) {
	//		if (this.orderBy() != orderBy) {
	//			this.direction("asc");
	//			this.orderBy(orderBy);
	//		} else {
	//			if (this.direction() == "asc") {
	//				this.direction("desc");
	//			} else {
	//				this.direction("asc");
	//			}
	//		}
	//	};


	//	this.dispose = function() {
	//		$.each(subscriptions, function(index, subscription) {
	//			subscription.dispose();
	//		});
	//	};
};
