namespace("oga.viewmodel.pos");

oga.viewmodel.pos.SellVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;

	self.isSellableTicket = ko.observable(false);
	self.barcodeToCheck = ko.observable().extend({ notify: "always" });
	self.barcodeInput = ko.observable().extend({
		required: true,
		pattern: {
			message: i18next.t("must.be.at.least.eight.digits"),
			params: "^\\d{8,}$"
		}
	});
	
	self.ticketInfo = ko.observable();
	self.isLoading = ko.observable(false);
	self.isMessageBoxVisible = ko.observable(false);
	self.messageBoxText = ko.observable("");
	
	this.init = function() {
		self.barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			minBarcodeLength: 12,
			nameSpace: "SellVM",
			scannerInputStorer: self.barcodeToCheck
		});
		self.barcodeScannerMannager.start();
		
		subscriptions.push(self.barcodeToCheck.subscribe(function(newValue) {
			self.barcodeInput(newValue);
			checkBarcode();
		}));

		subscriptions.push(self.barcodeInput.subscribe(function() {
			self.isSellableTicket(false);
		}));
	};

	function checkBarcode() {
		if (!self.barcodeInput.isValid()) {
			return;
		}
		self.isLoading(true);
		var barcode = self.barcodeToCheck();
		var url = pastel.util.StringHelper.format("api/tickets/{0}/sellable", barcode);
		self.isMessageBoxVisible(false);
		restClient.getResource(url).done(function(resp) {
			self.ticketInfo(resp);
			self.isSellableTicket(true);
		}).handleErrors({
			EntityNotFoundException: function() {
				self.messageBoxText(i18next.t("no.such.ticket") + " - " + barcode);
			}, InvalidBarcodeUnitException: function() {
				var msg = pastel.util.StringHelper.format(i18next.t("ticket.could.not.be.sold"), barcode);
				self.messageBoxText(msg);
			}
		}).fail(function() {
			self.isMessageBoxVisible(true);
			self.isSellableTicket(false);
		}).always(function() {
			self.isLoading(false);
		});
	}

	self.setBarcodeToCheck = function() {
		self.barcodeToCheck(self.barcodeInput());
	};
	
	self.sellTicket = function() {
		self.isMessageBoxVisible(false);
		var barcode = self.barcodeInput();
		restClient.postResource("api/tickets/" + barcode).done(function() {
			self.isSellableTicket(false);
			self.messageBoxText(i18next.t("ticket") + " " + barcode + " " + i18next.t("was sold"));
		}).handleErrors({
			EntityNotFoundException: function() {
				self.messageBoxText(i18next.t("no.such.ticket") + " - " + barcode);
			},
			InvalidBarcodeUnitException: function() {
				var msg = pastel.util.StringHelper.format(i18next.t("ticket.could.not.be.sold"), barcode);
				self.messageBoxText(msg);
			}
		}).always(function() {
			self.barcodeInput("");
			self.isMessageBoxVisible(true);
			self.barcodeInput.isModified(false);
		});
	};

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
		self.barcodeScannerMannager.dispose();
	};
};
