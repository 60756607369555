namespace("oga.component");

oga.component.OrderCreationItem = function(params) {
	var self = this;
	var subscriptions = [];

	self.games = params.data.availableGames;
	self.selectedGameId = ko.observable();
	self.selectedGameDetails = ko.observable();

	self.minimumOrderQuantity = ko.observable(0);
	self.orderBatchSize = ko.observable(0);
	self.orderQuantityInput = ko.observable().extend({
		required: true,
		min: self.minimumOrderQuantity,
		pattern: /^[0-9]+$/,
		validation: {
			validator: function (val, someOtherVal) {
				return (val % someOtherVal) === 0;
			},
			params: self.orderBatchSize
		}
	});

	subscriptions.push(self.orderQuantityInput.isValid.subscribe(function(newValue) {
		params.data.isValid(newValue);
	}));

	subscriptions.push(self.selectedGameId.subscribe(function() {
		self.selectedGameDetails(getSelectedGameDetails());
		params.data.selectedGame(self.selectedGameDetails());
	}));

	subscriptions.push(self.orderQuantityInput.subscribe(function(newValue) {
		params.data.quantity(newValue);
	}));

	function getSelectedGameDetails() {
		var gameDetails = self.games().find(function(game) {
			return game.gameId == self.selectedGameId();
		});
		self.minimumOrderQuantity(gameDetails.minimumOrderQuantity);
		self.orderBatchSize(gameDetails.orderBatchSize);
		return gameDetails;
	}

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
