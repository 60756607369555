namespace("oga.binding");

oga.binding.DateRangePickerBinding = {
	init: function(element, valueAccessor) {
		var dateRange = ko.unwrap(valueAccessor());
		if (!ko.isObservable(dateRange.fromDate) || !ko.isObservable(dateRange.toDate)) {
			throw "Invalid binding property. Expected oga.model.DateRange object";
		}
		var locale = window.getLocale(dateRange);
		$(element).daterangepicker(locale,
			function(fromDate, toDate) {
				dateRange.fromDate(fromDate);
				dateRange.toDate(toDate);
			}
		);

		ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
			$(element).data("daterangepicker").remove();
		});
	},
	update: function(element, valueAccessor) {
		var unwrappedValue = ko.unwrap(valueAccessor());
		var $element = $(element);
	
		$element.data("daterangepicker").setStartDate(unwrappedValue.fromDate());
		$element.data("daterangepicker").setEndDate(unwrappedValue.toDate());
	}
};

window.getLocale = function(dateRange) {
	var t = i18next.t;
	var i18locale;
	i18locale = {
		locale: {			
			format: oga.settings.momentDateFormat,
			separator: " - ",
			applyLabel: t("calendar.apply"),
			cancelLabel: t("calendar.cancel"),
			fromLabel: t("calendar.from"),
			toLabel: t("calendar.to"),
			customRangeLabel: t("calendar.custom.range"),
			daysOfWeek: [
				t("calendar.sunday"),
				t("calendar.monday"),
				t("calendar.tuesday"),
				t("calendar.wednesday"),
				t("calendar.thursday"),
				t("calendar.friday"),
				t("calendar.saturday")
			],
			monthNames: [
				t("calendar.january"),
				t("calendar.february"),
				t("calendar.march"),
				t("calendar.april"),
				t("calendar.May"),
				t("calendar.june"),
				t("calendar.july"),
				t("calendar.august"),
				t("calendar.september"),
				t("calendar.october"),
				t("calendar.november"),
				t("calendar.december")
			],
			"firstDay": 1
		},	
		startDate: dateRange.fromDate(),
		endDate: dateRange.toDate(),
		maxDate: moment(),
		opens: "left",
		ranges: getRanges()
	};
	
	function getRanges() {
		var ranges = {};
		if (dateRange.typeRange() === "weekly" && dateRange.typeRange() !== undefined) {
			ranges[t("calendar.last.seven.days")] = [moment().subtract(6, "days"), moment()];
		} else {
			ranges[t("calendar.today")] = [moment(), moment()];
			ranges[t("calendar.yesterday")] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
			ranges[t("calendar.last.seven.days")] = [moment().subtract(6, "days"), moment()];
			ranges[t("calendar.last.thirdty.days")] = [moment().subtract(29, "days"), moment()];
			ranges[t("calendar.this.month")] = [moment().startOf("month"), moment().endOf("month")];
			ranges[t("calendar.last.month")] = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month")
				.endOf("month")];
		}
		
		return ranges;
	}

	return i18locale;
};