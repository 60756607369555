namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.BarcodeUnitLogsVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	self.isLoading = ko.observable(false);
	self.barcodeUnitHistory = ko.observable();
	self.enteredBarcode = ko.observable("").extend({
		required: true
	});

	self.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});

	this.init = function() {
		var barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: "BarcodeUnitLogsVM",
			minBarcodeLength: 10,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.checkBarcode
		});
		
		barcodeScannerMannager.start();
	};

	self.checkBarcode = function() {
		if (!self.enteredBarcode.isValid()) {
			return;
		}
		var enteredBarcode = self.enteredBarcode().trim();
		var scanState = {
			barcode: enteredBarcode,
			barcodeCheckStatus: BarcodeCheckStatus.NOT_FOUND
		};
		self.isLoading(true);
		var url = pastel.util.StringHelper.format("api/barcode-units/{0}/log", enteredBarcode);
		restClient.getResource(url).done(function(resp) {
			self.barcodeUnitHistory(new oga.model.BarcodeUnitHistory(resp));
			scanState.barcodeCheckStatus = BarcodeCheckStatus.CHECKED;
			self.lastCheckBarcodeDetails(scanState);
		}).fail(function() {
			scanState.barcodeCheckStatus = BarcodeCheckStatus.NOT_FOUND;
			self.lastCheckBarcodeDetails(scanState);
			self.barcodeUnitHistory(null);
		}).always(function() {
			self.isLoading(false);
		});
	};
};