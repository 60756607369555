namespace("oga.viewmodel");

oga.viewmodel.LayoutVM = function() {
	var self = this;

	this.navDashboardButton = new oga.model.Button({
		viewName: "dashboard"
	});
	this.navPointsOfSaleButton = new oga.model.Button({
		viewName: "pos"
	});
	this.navSellTicketsParentButton = new oga.model.Button({
		viewName: "sellTicketsParent",
		hasNoView: true
	});
	this.navSellButton = new oga.model.Button({
		viewName: "sell"
	});
	this.navBulkSellButton = new oga.model.Button({
		viewName: "bulk-sell"
	});
	this.navTicketsParentButton = new oga.model.Button({
		viewName: "ticketsParent",
		hasNoView: true
	});
	this.navTicketCheckButton = new oga.model.Button({
		viewName: "check"
	});
	this.navTicketReturnButton = new oga.model.Button({
		viewName: "return-requests",
		hashOnWhichIsActive: ["return-request-create", "return-request-open"]
	});
	this.navTicketRedemptionButton = new oga.model.Button({
		viewName: "redemption-requests",
		hashOnWhichIsActive: ["redemption-request-open", "redemption-request-create"]
	});
	this.navTicketTransferButton = new oga.model.Button({
		viewName: "transfers",
		hashOnWhichIsActive: ["transfer-create", "transfer-open"]
	});
	this.navOrdersButton = new oga.model.Button({
		viewName: "orders",
		hashOnWhichIsActive: ["order-open", "order-create"]
	});
	this.navCashiersButton = new oga.model.Button({
		viewName: "cashiers"
	});
	this.navAvailabilityButton = new oga.model.Button({
		viewName: "availability"
	});
	this.navInventorizationButton = new oga.model.Button({
		viewName: "inventorizations"
	});
	this.navReportsButton = new oga.model.Button({
		viewName: "reports"
	});
	this.navGameSettingsParentButton = new oga.model.Button({
		viewName: "gamesParent",
		hasNoView: true
	});
	this.navGamesButton = new oga.model.Button({
		viewName: "games"
	});
	this.navGameWinningsButton = new oga.model.Button({
		viewName: "game-winnings"
	});
	this.navGroupsButton = new oga.model.Button({
		viewName: "game-profiles"
	});
	this.navTransactionsButton = new oga.model.Button({
		viewName: "transactions"
	});
	this.navBarcodeUnitLogButton = new oga.model.Button({
		viewName: "barcode-unit-log"
	});
	this.navUsersButton = new oga.model.Button({
		viewName: "users"
	});
	this.navBlacklistButton = new oga.model.Button({
		viewName: "blocklist"
	});
	this.navAccountButton = new oga.model.Button({
		viewName: "account"
	});
	this.navPosProfileButton = new oga.model.Button({
		viewName: "pos-profile/" + oga.authenticatedUser().pointOfSale.id + "/winnings"
	});

	this.isChangePasswordPopupVisible = ko.observable(false);

	this.currentPassword = ko.observable().extend({
		required: true
	});
	this.newPassword = ko.observable().extend({
		required: true,
		minLength: 8,
		pattern: {
			message: i18next.t("password.invalid"),
			params: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\p!\"#$%&'()*+,\\-./:;<=>?@[\\]\\^_`{\\|}~]{8,}$"
		}
	});
	this.retypePassword = ko.observable().extend({
		required: true,
		minLength: 8,
		pattern: {
			message: i18next.t("password.invalid"),
			params: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\p!\"#$%&'()*+,\\-./:;<=>?@[\\]\\^_`{\\|}~]{8,}$"
		}
	});

	self.validation = ko.validatedObservable([
		self.currentPassword,
		self.newPassword,
		self.retypePassword
	]);

	this.init = function() {
	};

	this.logout = function() {
		oga.restClient.postResource("/logout").done(function() {
			window.location = "/login.html";
		}).fail(function() {
			alert("Could not logout");
		});
	};

	this.toggleChangePasswordPopup = function() {
		self.isChangePasswordPopupVisible(!self.isChangePasswordPopupVisible());
	};

	this.cancel = function() {
		self.isChangePasswordPopupVisible(false);
		self.currentPassword(undefined);
		self.newPassword(undefined);
		self.retypePassword(undefined);
		self.currentPassword.clearError();
		self.newPassword.clearError();
		self.retypePassword.clearError();
	};

	this.changePassword = function() {

		if (self.validation.isValid()) {

			if (self.newPassword() !== self.retypePassword()) {
				self.retypePassword.setError("Passwords don't match");
				self.retypePassword.isModified(true);
				return;
			}

			var data = {
				currentPassword: self.currentPassword(),
				newPassword: self.newPassword(),
				retypePassword: self.retypePassword()
			};

			oga.restClient.putResource("/api/users/current/change-password", JSON.stringify(data)).done(function() {
				self.cancel();
			}).fail(function(resp) {
				if (resp.responseJSON.error == "PasswordMismatchException") {
					self.currentPassword.setError("Wrong password");
					self.currentPassword.isModified(true);
				}

			});
		} else {
			self.validation.errors.showAllMessages();
		}
	};
};
