namespace("oga.viewmodel");

oga.viewmodel.InventorizationOpenVM = function () {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var nameSpace = "inventorizationOpenVM";
	var barcodeScannerMannager = null;
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;
	var PAGE_SIZE = 10;
	var inventorizationId = null;

	self.pageSizes = [10, 50, 100];
	self.inventorization = ko.observable();

	self.allGames = ko.observableArray();
	self.selectedGame = ko.observable();
	self.selectedGameSummary = ko.observable();
	self.selectedGameSpread = ko.observable(null);

	self.isScannedBarcodesVisible = ko.observable(true);

	self.expectedBarcodes = ko.observableArray([]);
	self.expectedBarcodesCount = ko.observable(null);

	self.scannedBarcodes = ko.observableArray([]);
	self.scannedBarcodesCount = ko.observable(null);

	this.enteredBarcode = ko.observable("");

	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});

	self.paginationExpectedBarcodes = new oga.component.pagination.Pagination({
		orderBy: self.orderByBarcodeProp,
		direction: "asc",
		pageSize: PAGE_SIZE
	});

	self.paginationScannedBarcodes = new oga.component.pagination.Pagination({
		orderBy: "scannedAt",
		direction: "desc",
		pageSize: PAGE_SIZE
	});

	self.numberOfResultsOnPreviousPagesExpectedBarcodes = ko.pureComputed(function () {
		return PAGE_SIZE * (self.paginationExpectedBarcodes.page() - 1);
	});

	self.scannedBarcodesCurrentListIndex = ko.pureComputed(function () {
		var numberOfResultsOnPreviousPage = PAGE_SIZE * (self.paginationScannedBarcodes.page() - 1);
		return self.scannedBarcodesCount() - numberOfResultsOnPreviousPage;
	});

	self.isLoading = ko.observable(false);

	this.calculateTotalTicketsAvailable = ko.pureComputed(function () {
		var summary = self.selectedGameSummary();
		if (!summary) {
			return "";
		}
		var total = summary.ticketsCount
			+ summary.banderolsTicketCount
			+ summary.boxesTicketCount
			+ summary.cartonsTicketCount;
		return total;
	});

	this.init = function (params) {
		inventorizationId = params.id;

		loadGames();
		loadInventorizationDetails();

		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 10,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.addBarcode
		});

		subscriptions.push(self.selectedGame.subscribe(function (newSelectedItem) {
			if (newSelectedItem) {
				loadGameSummary();
				loadGameSpread();
			} else {
				self.selectedGameSummary(null);
			}
			if (self.paginationExpectedBarcodes.page() == 1) {
				loadPaginatedExpectedBarcodes();
			}
			self.paginationExpectedBarcodes.page(1);
		}));

		barcodeScannerMannager.start();

		subscriptions.push(self.paginationScannedBarcodes.queryParamsObject.subscribe(function () {
			loadPaginatedScannedBarcodes();
		}));

		loadPaginatedScannedBarcodes();

		subscriptions.push(self.paginationExpectedBarcodes.queryParamsObject.subscribe(function () {
			loadPaginatedExpectedBarcodes();
		}));

		loadPaginatedExpectedBarcodes();
	};

	function loadInventorizationDetails() {
		self.isLoading(true);
		oga.restClient.getResource("api/inventorizations/" + inventorizationId)
			.done(function (resp) {
				self.inventorization(new oga.model.Inventorization(resp));
			}).always(function () {
				self.isLoading(false);
			});
	}

	function loadPaginatedExpectedBarcodes() {
		self.isLoading(true);
		var paginationParams = self.paginationExpectedBarcodes.queryParamsObject();
		var gameId = self.selectedGame() != null ? self.selectedGame().id : null;
		var queryParams = $.extend(paginationParams, {
			gameId: gameId,
			isScanned: false
		});

		oga.restClient.getResource("api/inventorizations/" + inventorizationId + "/barcode-units/expected", queryParams)
			.done(function (resp) {
				self.expectedBarcodesCount(resp.count);
				self.expectedBarcodes(ko.utils.arrayMap(resp.items, function (dto) {
					return new oga.model.InventorizationExpectedBarcodeUnit(dto);
				}));
			}).always(function () {
				self.isLoading(false);
			});
	}

	function loadPaginatedScannedBarcodes() {
		self.isLoading(true);
		var paginationParams = self.paginationScannedBarcodes.queryParamsObject();
		var queryParams = $.extend(paginationParams, {
			isScanned: true
		});

		oga.restClient.getResource("api/inventorizations/" + inventorizationId + "/barcode-units/scanned", queryParams)
			.done(function (resp) {
				self.scannedBarcodesCount(resp.count);
				self.scannedBarcodes(ko.utils.arrayMap(resp.items, function (dto) {
					return new oga.model.InventorizationScannedBarcodeUnit(dto);
				}));
			}).always(function () {
				self.isLoading(false);
			});
	}

	self.addBarcode = function () {
		var enteredBarcode = self.enteredBarcode().trim();
		var scanState = {
			barcode: enteredBarcode,
			barcodeCheckStatus: BarcodeCheckStatus.NOT_FOUND
		};

		self.isLoading(true);
		oga.restClient.putResource("api/inventorizations/" + inventorizationId + "/barcode-unit/" + enteredBarcode + "/scan")
			.done(function () {

				scanState.barcodeCheckStatus = BarcodeCheckStatus.CHECKED;
				self.lastCheckBarcodeDetails(scanState);
				loadPaginatedScannedBarcodes();
				loadPaginatedExpectedBarcodes();

			}).fail(function (resp) {
				if (resp.responseJSON.error == "InventorizationBarcodeUnitAlreadyScannedException") {
					scanState.barcodeCheckStatus = BarcodeCheckStatus.DUPLICATE;
					self.lastCheckBarcodeDetails(scanState);
				} else if (resp.responseJSON.error == "InventorizationBarcodeUnitNotFoundException") {
					scanState.barcodeCheckStatus = BarcodeCheckStatus.ERROR;
					self.lastCheckBarcodeDetails(scanState);
				} else {
					scanState.barcodeCheckStatus = BarcodeCheckStatus.ERROR;
					self.lastCheckBarcodeDetails(scanState);
				}
			}).always(function () {
				self.isLoading(false);
			});
	};

	self.showScannedBarcodes = function () {
		self.isScannedBarcodesVisible(true);
		self.paginationScannedBarcodes.page(1);
	};

	self.showExpectedBarcodes = function () {
		self.isScannedBarcodesVisible(false);
		self.paginationExpectedBarcodes.page(1);
	};

	self.finishInventorization = function () {
		var msg = "inventorization.finish.confirm.msg";
		oga.popupManager.confirm(msg).done(function () {
			doFinishInventorization();
		});
	};

	function doFinishInventorization() {
		self.isLoading(true);
		oga.restClient.putResource("api/inventorizations/" + inventorizationId + "/finish")
			.done(function () {
				oga.router.setHash(oga.authenticatedUser().hashPrefix + "inventorizations");
			}).fail(function (resp) {
				if (resp.responseJSON.error == "InventorizationInvalidStatusException") {
					oga.popupManager.error("inventorization.finish.status.exception", "warning");
				}
			}).always(function () {
				self.isLoading(false);
			});
	}

	self.cancelInventorization = function () {
		var msg = "inventorization.cancel.confirm.msg";
		oga.popupManager.confirm(msg).done(function () {
			doCancelInventorization();
		});
	};

	function doCancelInventorization() {
		self.isLoading(true);
		oga.restClient.putResource("api/inventorizations/" + inventorizationId + "/cancel")
			.done(function () {
				oga.router.setHash(oga.authenticatedUser().hashPrefix + "inventorizations");
			}).fail(function (resp) {
				if (resp.responseJSON.error == "InventorizationInvalidStatusException") {
					oga.popupManager.error("inventorization.cancel.status.exception", "warning");
				}
			}).always(function () {
				self.isLoading(false);
			});
	}

	function loadGames() {
		self.isLoading(true);
		restClient.getResource("/api/games?form=light").done(function (resp) {
			self.allGames(resp);
		}).always(function () {
			self.isLoading(false);
		});
	}

	function loadGameSpread() {
		self.isLoading(true);
		self.selectedGameSpread(null);
		var url = pastel.util.StringHelper.format("/api/games/{0}/spread", self.selectedGame().id);
		restClient.getResource(url).done(function (resp) {
			self.selectedGameSpread(resp);
		}).always(function () {
			self.isLoading(false);
		});
	}

	function loadGameSummary() {
		self.isLoading(true);
		self.selectedGameSummary(null);
		var url = pastel.util.StringHelper.format("/api/games/{0}/availability/summary", self.selectedGame().id);
		restClient.getResource(url).done(function (resp) {
			self.selectedGameSummary(resp);
		}).always(function () {
			self.isLoading(false);
		});
	}

	this.canScanBarcodes = ko.pureComputed(function () {
		var inventorization = self.inventorization();
		if (inventorization === null) {
			return false;
		}
		return inventorization.statusId == oga.nomenclature.InventorizationStatusType.IN_PROGRESS;
	});

	self.dispose = function () {
		$.each(subscriptions, function (index, subscription) {
			subscription.dispose();
		});
	};

};
