namespace("oga.viewmodel");

oga.viewmodel.AccountVM = function() {
	var self = this;
	window.a = self;
	var subscriptions = [];
	var restClient = oga.restClient;
	var secretRegex = /secret=(.*)&issuer/;

	this.shouldShowQr = ko.observable(false);
	this.google2FaQr = ko.observable(null);
	this.isLoading = ko.observable(false);

	this.isGoogle2FaEnabled = ko.computed(function () {
		var qr = self.google2FaQr(); 
		var isEnabled = qr && qr != "" ? true : false;
		return isEnabled;  
	});

	this.extractSecret = function (secretQrUrl) {
		if (secretRegex.test(secretQrUrl)) {
			var matchGroups = secretQrUrl.match(secretRegex);
			return matchGroups[1];
		}
		return null;
	};
	
	this.init = function() {
		loadGoogle2FaQr();
		subscriptions.push(subscribeQrCodeRefresh());
	};

	this.toggleIsQrVisible = function () {
		self.shouldShowQr(!self.shouldShowQr());    
	};

	this.toggleIsGoogle2FaEnabled = function () {
		var newVal = !self.isGoogle2FaEnabled();	

		self.isLoading(true);
		restClient.putResource("/api/2fa/google2fa/" + newVal).done(function(newSecret) {
			if (newSecret === undefined || newSecret == "") {
				newSecret = null;
			}
			self.google2FaQr(newSecret);
			self.shouldShowQr(newVal);
		}).always(function() {
			self.isLoading(false);
		});
	};

	function subscribeQrCodeRefresh() {
		return ko.computed(function () {
			var isGoogle2FaEnabled = self.isGoogle2FaEnabled();

			if (isGoogle2FaEnabled) {
				loadGoogle2FaQr();
			} else {
				self.google2FaQr(null);
			}
		});
	}

	function loadGoogle2FaQr() {
		self.isLoading(true);
		self.google2FaQr(null);
		restClient.getResource("/api/2fa/google-secret").done(function(resp) {
			self.google2FaQr(resp);
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.dispose = function () {
		subscriptions.forEach(function (s) {
			s.dispose(); 
		});   
	};
	
};
