namespace("oga.model");

oga.model.PosEmployee = function(dto) {
	this.id = dto.id;
	this.fullName = dto.fullName !== undefined ? dto.fullName : null;
	if (dto.typeId != undefined) {
		this.type = ko.observable(oga.nomenclature.PosEmployeeType[dto.typeId]);
	} else {
		this.type = ko.observable();
	}
	this.role = ko.observable(dto.role);
	this.isEnabled = ko.observable(dto.isEnabled);
	this.email = dto.email;
	this.isActive = dto.isActive;

	this.isModifiable = ko.observable(true).extend({
		rateLimit : {
			timeout : 200,
			method : "notifyWhenChangesStop"
		},
		notify : "always"
	});
};