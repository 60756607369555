namespace("oga.model");

oga.model.CheckableTicket = function(dto) {
	this.id = dto.id;
	this.barcodeNumber = dto.barcodeNumber;
	this.gameName = dto.gameName;
	this.gameLotNumber = dto.gameLotNumber;
	this.winningValue = dto.winningValue;
	this.winningDescription = dto.winningDescription;

	this.isChecked = ko.observable(false);
};
