namespace("oga.nomenclature");

oga.nomenclature.TicketStatusType = {
	0: {
		id: 0,
		dbName: "CHECK",
		transKey: "check.caps"
	},
	1: {
		id: 1,
		dbName: "IN_STOCK",
		transKey: "in.stock"
	},
	2: {
		id: 2,
		dbName: "STREET_SALE",
		transKey: "street.sale"
	},
	3: {
		id: 3,
		dbName: "SOLD",
		transKey: "sold.caps"
	},
	4: {
		id: 4,
		dbName: "REDEEMED",
		transKey: "redeemed"
	},
	5: {
		id: 5,
		dbName: "BLACKLISTED",
		transKey: "blacklisted"
	},
	6: {
		id: 6,
		dbName: "TRANSFERRING",
		transKey: "transferring"
	},
	7: {
		id: 7,
		dbName: "RETURNED",
		transKey: "returned.caps"
	},
	8: {
		id: 8,
		dbName: "PAID",
		transKey: "paid.caps"
	},

	get CHECK() {
		return this[0];
	},
	get BLACKLISTED() {
		return this[5];
	}
};
