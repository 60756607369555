namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.ReportsRegulatorVM = function() {

	var subscriptions = [];
	var INITIAL_YEAR = 2022;
	self.regulatorReports = ko.observableArray();
	self.regulatorReportsCount = ko.observable();
	self.optionYears = getYears();
	self.selectedYear = ko.observable(self.optionYears[self.optionYears.length - 1]);
	self.dateRange = new oga.model.DateRange(moment().subtract(6, "days"), moment(), "weekly");

	this.isLotteryOrg = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	this.init = function() {
		subscriptions.push(self.selectedYear.subscribe(function() {
			loadReports();
		}));

		subscriptions.push(ko.computed(function() {
			loadReports();
		}).extend({deferred: true}));
	};

	function loadReports() {
		oga.restClient.getResource("api/reports/regulator/" + self.selectedYear(), {})
			.done(function(resp) {
				self.regulatorReports(ko.utils.arrayMap(resp, function(dto) {
					return new oga.model.ReportRegulatorMonth(dto);
				}));
				self.regulatorReportsCount(self.regulatorReports().length);
			});
	}

	function getYears() {
		var years = [];
		var todaysYear = new Date().getFullYear();
		var initialYear = INITIAL_YEAR;
		while (initialYear <= todaysYear) {
			years.push(initialYear);
			initialYear++;
		}
		return years;
	}

	this.exportExcel = function(report) {
		var params = {
			reportDate: moment(report.reportDate).format(oga.settings.momentRequestFormat),
			lang: oga.util.i18n.getLanguage()
		};

		var urlQueryParams = $.param(params, true);
		location.href = "api/reports/regulator/xls?" + urlQueryParams;
	};

	this.exportPaidWinningsExcel = function() {
		var dateToSubtracted = new Date(self.dateRange.toDate().subtract(7, "days"));
		self.dateRange.toDate().add(7, "days");

		if (dateToSubtracted > self.dateRange.fromDate()) {
			oga.popupManager.error("report.exception.7.days", "warning");
		} else {
			this.doExportPaidWinningsExcel();
		}
		
	};

	this.doExportPaidWinningsExcel = function() {
		var params = {
			fromDate: self.dateRange.fromDate().format(oga.settings.momentRequestFormat),
			toDate: self.dateRange.toDate().format(oga.settings.momentRequestFormat),
			lang: oga.util.i18n.getLanguage()
		};
		var urlQueryParams = $.param(params, true);
		location.href = "api/reports/paid-winnings/xls?" + urlQueryParams;

	};
};
