namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosProfileVM = function() {
	var pointOfSaleId = null;
	var self = this;
	oga.cache.posProfileVM = this;

	this.isPosNotFound = ko.observable(false);
	this.isLoadingPos = ko.observable(false);
	this.isSavingChanges = ko.observable(false);
	this.pos = ko.observable(null);
	this.activeTab = ko.observable(null);

	//hack to make the validated observable responsive
	this.posWorkCopy = ko.validatedObservable(new oga.model.PointOfSale());
	this.posWorkCopy(null);

	this.init = function() {
		var params = oga.router.getMatchedRoute().parameters;
		pointOfSaleId = params.id;

		setActiveTab();
		loadPos();
	};

	this.openSubView = function(subviewHash) {
		if (self.isAdmin() || self.isLottoRep()) {
			oga.router.setHash("#lotto-org/pos-profile/" + pointOfSaleId + "/" + subviewHash);
		} else {
			oga.router.setHash("#pos-admin/pos-profile/" + pointOfSaleId + "/" + subviewHash);

		}
		self.activeTab(subviewHash);
	};

	this.savePosChanges = function() {
		var posWorkCopy = self.posWorkCopy();

		self.isSavingChanges(true);
		oga.restClient.putResource("api/points-of-sale/" + pointOfSaleId, JSON.stringify(posWorkCopy.toDtoLight())).done(function() {
			self.pos(posWorkCopy);
			self.clearEditDetails();
		}).always(function() {
			self.isSavingChanges(false);
		});
	};

	this.isAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	this.isLottoRep = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE;
	});

	this.isPosAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE;
	});

	this.editPos = function() {
		self.posWorkCopy(self.pos().getClone());
	};

	this.clearEditDetails = function() {
		self.posWorkCopy(null);
	};

	function setActiveTab() {
		var currentHash = location.hash;
		if (currentHash.indexOf("tickets") > -1) {
			self.activeTab("tickets");
		} else if (currentHash.indexOf("users") > -1) {
			self.activeTab("users");
		} else if (currentHash.indexOf("requests") > -1) {
			self.activeTab("requests");
		} else if (currentHash.indexOf("settings") > -1) {
			self.activeTab("settings");
		} else if (currentHash.indexOf("winnings") > -1) {
			self.activeTab("winnings");
		} else if (currentHash.indexOf("balance") > -1) {
			self.activeTab("balance");
		}
	}

	function loadPos() {
		self.isLoadingPos(true);
		oga.restClient.getResource("api/points-of-sale/" + pointOfSaleId).done(function(posDto) {
			var pos = new oga.model.PointOfSale(posDto);
			self.pos(pos);
		}).fail(function(resp) {
			var respIsNoFound = resp !== undefined && resp.status == 404;
			if (respIsNoFound) {
				var errorDetails = JSON.parse(resp.responseText);
				if (errorDetails.error == "EntityNotFoundException") {
					self.isPosNotFound(true);
				}
			}

		}).always(function() {
			self.isLoadingPos(false);
		});
	}

	this.dispose = function () {
		oga.cache.posProfileVM = undefined;    
	};

};
