namespace("oga.viewmodel");

oga.viewmodel.RedemptionRequestOpenVM = function() {
	var self = this;
	var TICKETS_PAGE_SIZE = 10;
	var redemptionRequestId = null;
	var allTicketsToggleCheckValue = true;
	var nameSpace = "redemptionRequestOpenVM";
	var barcodeScannerMannager = null;
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	this.redemptionRequest = ko.observable(null);
	this.isRedemptionNotFound = ko.observable(false);
	this.isRedemptionForbidden = ko.observable(false);
	this.isApprovingRequest = ko.observable(false);

	this.enteredBarcode = ko.observable("");

	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: TICKETS_PAGE_SIZE,
		orderBy: "barcodeNumber"
	});

	this.canApproveRedemptionRequest = ko.pureComputed(function() {
		var tickets = self.redemptionRequest().tickets;
		var allTicketsAreChecked = true;

		for (var i = 0; i < tickets.length; i++) {
			var ticket = tickets[i];
			if (!ticket.isChecked()) {
				allTicketsAreChecked = false;
				break;
			}
		}

		return allTicketsAreChecked;
	});

	this.canEditRedemptionRequest = ko.pureComputed(function() {
		var redemptionRequest = self.redemptionRequest();
		var isUserFromLottoOrg = oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS;
		if (redemptionRequest === null) {
			return false;
		}

		var isRedemptionRequestApproved = self.redemptionRequest().status() == oga.nomenclature.RequestStatusType.APPROVED;
		return isUserFromLottoOrg && !isRedemptionRequestApproved;
	});

	this.ticketsForPage = ko.pureComputed(function() {
		var redemptionRequest = self.redemptionRequest();
		var pageQueryParams = self.pagination.queryParamsObject();
		if (redemptionRequest === null) {
			return [];
		}

		sortObjArByNumericProp(redemptionRequest.tickets, pageQueryParams.direction);

		var ticketsStartIndex = (pageQueryParams.page - 1) * pageQueryParams.pageSize;

		var ticketsForPage = [];

		for (var i = 0; i < pageQueryParams.pageSize; i++) {
			var ticket = redemptionRequest.tickets[ticketsStartIndex + i];
			if (ticket !== undefined) {
				ticketsForPage.push(ticket);
			} else {
				break;
			}
		}

		return ticketsForPage;
	});

	this.init = function(params) {
		redemptionRequestId = params.id;
		loadRedemptionRequest();

		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 12,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.checkTicketWithBarcode
		});

		barcodeScannerMannager.start();
	};

	this.toggleTicketsChecks = function() {
		self.redemptionRequest().tickets.forEach(function(ticket) {
			ticket.isChecked(allTicketsToggleCheckValue);
		});
		allTicketsToggleCheckValue = !allTicketsToggleCheckValue;
	};

	function loadRedemptionRequest() {
		oga.restClient.getResource("api/redemption-requests/" + redemptionRequestId).done(function(resp) {
			self.redemptionRequest(new oga.model.RedemptionRequest(resp));
		}).fail(function(resp) {
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "EntityNotFoundException":
						self.isRedemptionNotFound(true);
						break;
					case "PointOfSaleMismatchException":
						self.isRedemptionForbidden(true);
						break;
					default:
						oga.logger("Unexpected redmption request exception");
				}
			}
		});
	}

	this.checkTicketWithBarcode = function() {
		var barcode = self.enteredBarcode().trim();
		var allTickets = self.redemptionRequest().tickets;

		var hasNoSuchTicket = true;
		for (var i = 0; i < allTickets.length; i++) {
			var ticket = allTickets[i];
			if (ticket.barcodeNumber == barcode) {
				ticket.isChecked(true);
				hasNoSuchTicket = false;
				break;
			}
		}

		var scanState = {
			barcode: barcode
		};

		scanState.barcodeCheckStatus = hasNoSuchTicket ? BarcodeCheckStatus.NOT_FOUND : BarcodeCheckStatus.CHECKED;
		self.lastCheckBarcodeDetails(scanState);

	};

	this.approveRedemptionRequest = function() {
		self.isApprovingRequest(true);
		oga.restClient.postResource("api/redemption-requests/" + redemptionRequestId + "/approvement").done(function(resp) {
			self.redemptionRequest(new oga.model.RedemptionRequest(resp));
		}).always(function() {
			self.isApprovingRequest(false);
		});
	};

	//TODO move logic to util object
	function sortObjArByNumericProp(objAr, direction) {
		objAr.sort(function(ticketA, ticketB) {
			var orderByProp = self.pagination.orderBy();
			if (ticketA[orderByProp] > ticketB[orderByProp]) {
				return 1;
			} else if (ticketB[orderByProp] > ticketA[orderByProp]) {
				return -1;
			} else {
				return 0;
			}
		});

		if (direction == "desc") {
			objAr.reverse();
		}

		return objAr;
	}

	this.dispose = function() {
		barcodeScannerMannager.dispose();
	};

};
