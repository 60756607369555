namespace("oga.binding");

oga.binding.PageSortingBinding = {
	update: function(element, valueAccessor) {
		//The parent table must have a "dataTable" class
		var value = ko.unwrap(valueAccessor());

		var pagination = value[0];
		var elementOrderByName = value[1];
		var orderBy = pagination.orderBy();
		var direction = pagination.direction();

		var sortingClass = "";
		if (elementOrderByName == orderBy) {
			if (direction == "asc") {
				sortingClass = "sorting_asc";
			} else {
				sortingClass = "sorting_desc";
			}
		} else {
			sortingClass = "sorting";
		}

		ko.bindingHandlers["css"].update(element, function() {
			return sortingClass;
		});
	}
};
