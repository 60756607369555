namespace("oga.viewmodel.pos.admin");
oga.viewmodel.pos.admin.RedemptionRequestCreateVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var PAGE_SIZE = 15;
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;
 
	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus:BarcodeCheckStatus.STANDBY
	});

	self.currentPos = ko.observable();
	self.barcodeToCheck = ko.observable("");
	self.isLoading = ko.observable(false);
	self.pagination = new oga.model.PaginatedArrayModel({
		pageSize: PAGE_SIZE
	});
	self.totalPrice = ko.pureComputed(function() {
		var total = 0;
		$.each(self.pagination.getItems(), function(index, ticket) {
			total += ticket.winningValue;
		});
		return total;
	});

	this.init = function() {
		getCurrentPos();
		self.barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			minBarcodeLength: 12,
			nameSpace: "RedemptionRequestCreateVM",
			scannerInputStorer: self.barcodeToCheck,
			onScanCallback: self.checkBarcode
		});
		self.barcodeScannerMannager.start();
	};
    
	function getCurrentPos() {
		restClient.getResource("api/points-of-sale/current").done(function(resp) {
			self.currentPos(resp);
		});
	}

	self.checkBarcode = function() {
		var barcodeCheckDetails = {
			barcode: self.barcodeToCheck()
		};

		if (isBarcodeScanned(self.barcodeToCheck())) {
			barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.DUPLICATE;
			self.lastCheckBarcodeDetails(barcodeCheckDetails);
			return;
		}

		self.isLoading(true);
		var url = pastel.util.StringHelper.format("api/tickets/{0}/redeemable", self.barcodeToCheck());
		restClient.getResource(url).done(function(resp) {
			barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.ADDED;
			self.pagination.addItem(resp);
		}).fail(function(resp) {
			barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.ERROR;
			if (resp.responseJSON !== undefined) {
				if (resp.responseJSON.error == "TicketNotFoundException") {
					barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.NOT_FOUND;
				}
			} else {
				oga.logger("unexpected error when gettign redeemable ticket");
			}
		}).always(function() {
			self.lastCheckBarcodeDetails(barcodeCheckDetails);
			self.isLoading(false);
		});
	};

	self.deleteTicket = function() {
		self.pagination.removeItem(this);
	};

	self.sendRedemptionRequest = function() {
		self.isLoading(true);
		var ticketIds = getTicketsToRedeem();
		var dataToSend = JSON.stringify({ticketIds: ticketIds});
		restClient.postResource("api/redemption-requests", dataToSend).done(function() {
			self.pagination.clear();
			oga.logger("successfully send redemption request");
			oga.router.setHash(oga.authenticatedUser().hashPrefix + "redemption-requests");
		}).fail(function() {
			oga.logger("sending failed");
		}).always(function() {
			self.isLoading(false);
		});
	};

	function isBarcodeScanned(barcodeNumber) {
		var temp = false;
		$.each(self.pagination.getItems(), function(index, ticket) {
			if (ticket.barcodeNumber == barcodeNumber) {
				temp = true;
				return;
			}
		});
		return temp;
	}
 
	function getTicketsToRedeem() {
		var ticketIds = [];
		self.pagination.getItems().forEach(function(ticket) {
			ticketIds.push(ticket.id);
		});
		return ticketIds;
	}

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
		self.barcodeScannerMannager.dispose();
	};
};
