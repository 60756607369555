namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.OrdersVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;
	var PAGE_SIZE = 15;
	var OrdersStatus = oga.nomenclature.OrderStatusType;
	
	self.statusOptions = ko.observableArray([
		OrdersStatus.WAITING, OrdersStatus.PROCESSED, OrdersStatus.ACCEPTED, OrdersStatus.CANCELED
	]);
	self.selectedStatus = ko.observable(undefined);
	self.pointOfSaleId = ko.observable();
	self.orders = ko.observableArray([]);
	self.ordersCount = ko.observable();
	self.tags = ko.observableArray([]);
	self.posNameIdParam = ko.observable();

	self.isLoading = ko.observable(false);
	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "createdAt",
		direction: "desc",
		pageSize: PAGE_SIZE
	});	
	
	this.init = function() {
		subscriptions.push(self.selectedStatus.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadOrders();
		}).extend({
			deferred: true
		}));
	};

	function loadOrders() {
		self.isLoading(true);
		restClient.getResource("/api/orders", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			statusId: self.selectedStatus(),
			pointOfSaleId: self.pointOfSaleId(),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam()
		}).done(function(resp) {
			self.orders(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.Order(dto);
			}));
			self.ordersCount(resp.count);
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.canOpen = ko.computed(function() {
		return oga.authenticatedUser().authority != oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE;
	});

	this.exportExcel = function() {
		var params = {
			statusId: self.selectedStatus(),
			pointOfSaleId: self.pointOfSaleId(),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam(),
			lang: oga.util.i18n.getLanguage()
		};

		var urlQueryParams = $.param(params, true);
		location.href = "/api/orders/xls?" + urlQueryParams;
	};
	
	self.openOrder = function(id) {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "order-open/"+ id);
	};
	
	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
