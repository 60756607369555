namespace("oga.model");

oga.model.CheckableBarcodeUnit = function(dto) {
	this.id = dto.id;
	this.barcodeNumber = dto.barcodeNumber;
	this.type = dto.type;

	this.game = new oga.model.GameLight(dto.game);
	this.ticketsCount = dto.ticketsCount;

	this.isChecked = ko.observable(false);
};
