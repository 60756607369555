namespace("oga.security");

oga.security.SecurityUtil = {
	isAnyGranted: function() {
		var i;
		for (i = 0; i < arguments.length; i++) {
			if (oga.authenticatedUser().authorities.indexOf(arguments[i]) != -1) {
				return true;
			}
		}
		return false;
	},

	isAllGranted: function() {
		var i;
		for (i = 0; i < arguments.length; i++) {
			if (oga.authenticatedUser().authorities.indexOf(arguments[i]) == -1) {
				return false;
			}
		}
		return true;
	},

	isNoneGranted: function() {
		var i;
		for (i = 0; i < arguments.length; i++) {
			if (oga.authenticatedUser().authorities.indexOf(arguments[i]) != -1) {
				return false;
			}
		}
		return true;
	}
};
