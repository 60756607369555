namespace("oga.component.pagination");

oga.component.pagination.Paginator = function(params) {

	var self = this;

	this.pagination = params.pagination;
	this.resultsCount = null;
	if ($.isFunction(params.resultsCount)) {
		this.resultsCount = params.resultsCount;
	} else {
		this.resultsCount = ko.observable(params.resultsCount);
	}

	this.visiblePagesCount = params.visiblePagesCount;

	var pageSizeSubscription = this.pagination.pageSize.subscribe(function() {
		var maxPage = getMaxPage();
		if (self.pagination.page() > maxPage) {
			self.pagination.page(maxPage);
		}
	});

	this.maxPage = ko.pureComputed(function() {
		return getMaxPage();
	});

	function getMaxPage() {
		var resultsCount = self.resultsCount();
		var pageSize = parseInt(self.pagination.pageSize());
		if (resultsCount <= 0) {
			return 1;
		}
		return Math.ceil(resultsCount / pageSize);
	}

	this.isGoToPreviousPageEnabled = ko.pureComputed(function() {
		return self.pagination.page() > 1;
	});

	this.isGoToNextPageEnabled = ko.pureComputed(function() {
		return self.pagination.page() < self.maxPage();
	});

	this.pages = ko.pureComputed(function() {
		var halfVisiblePagesCount = Math.floor(self.visiblePagesCount / 2);
		var currentPage = self.pagination.page();
		var firstPage = currentPage - halfVisiblePagesCount;
		if (firstPage < 1) {
			firstPage = 1;
		}
		var lastPage = Math.min(self.maxPage(), firstPage + self.visiblePagesCount - 1);
		if (lastPage < 1) {
			lastPage = 1;
		}

		var pages = [];
		for (var page = firstPage; page <= lastPage; page++) {
			pages.push(page);
		}
		return pages;
	});

	this.goToFirstPage = function() {
		if (self.isGoToPreviousPageEnabled()) {
			self.pagination.page(1);
		}
	};

	this.goToPreviousPage = function() {
		if (self.isGoToPreviousPageEnabled()) {
			var currentPage = self.pagination.page();
			self.pagination.page(currentPage - 1);
		}
	};

	this.goToNextPage = function() {
		if (self.isGoToNextPageEnabled()) {
			var currentPage = self.pagination.page();
			self.pagination.page(currentPage + 1);
		}
	};

	this.goToLastPage = function() {
		if (self.isGoToNextPageEnabled()) {
			self.pagination.page(self.maxPage());
		}
	};

	this.goToPage = function(page) {
		self.pagination.page(page);
	};

	this.dispose = function() {
		pageSizeSubscription.dispose();
	};
};
