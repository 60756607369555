namespace("oga.viewmodel");

oga.viewmodel.InventorizationsVM = function () {
	var self = this;
	var PAGE_SIZE = 15;
	var subscriptions = [];

	this.totalInventorizationsCount = ko.observable(0);
	this.inventorizations = ko.observableArray([]);
	this.isLoading = ko.observable(false);
	this.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE,
		direction: "desc"
	});

	this.init = function () {
		subscriptions.push(self.pagination.queryParamsObject.subscribe(function (queryParams) {
			loadInventorizations(queryParams);
		}));

		loadInventorizations(self.pagination.queryParamsObject());
	};

	self.numberOfResultsOnPreviousPages = ko.pureComputed(function () {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	this.createInventorization = function () {
		self.isLoading(true);
		oga.restClient.postResource("api/inventorizations").done(function (resp) {
			oga.router.setHash(oga.authenticatedUser().hashPrefix + "inventorization-open/" + resp);
		}).fail(function (resp) {
			if (resp.responseJSON.error == "UserHasInventorizationInProgressException") {
				oga.popupManager.error("user.already.have.started.inventorization", "warning");
			} else if (resp.responseJSON.error == "InventorizationUserHasNoBarcodesException") {
				oga.popupManager.error("inventorization.no.available.barcodes", "warning");
			}
		}).always(function () {
			self.isLoading(false);
		});
	};

	this.openInventorization = function (inventorization) {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "inventorization-open/" + inventorization.id);
	};

	function loadInventorizations(queryParams) {
		self.isLoading(true);
		oga.restClient.getResource("api/inventorizations", queryParams).done(function (resp) {
			self.totalInventorizationsCount(resp.count);
			self.inventorizations(ko.utils.arrayMap(resp.items, function (inventorizationDto) {
				return new oga.model.InventorizationListItem(inventorizationDto);
			}));
		}).always(function () {
			self.isLoading(false);
		});
	}

	this.dispose = function () {
		subscriptions.forEach(function (subscription) {
			subscription.dispose();
		});
	};
};
