namespace("oga.nomenclature");

oga.nomenclature.RequestStatusType = {
	1: {
		id: 1,
		dbName: "", //WAITING
		dropdownName: "", //Waiting
		transKey: "waiting",
		cssClass: "label-warning"
	},
	2: {
		id: 2,
		dbName: "", //APPROVED
		dropdownName: "", //Approved
		transKey: "approved",
		cssClass: "label-success"
	},
	get WAITING() {
		return this[1];
	},
	get APPROVED() {
		return this[2];
	},
	get ALL() {
		return [this[1], this[2]];
	}

};
