namespace("oga.nomenclature");

oga.nomenclature.PosEmployeeType = {
	1: {
		id: 1,
		dbTypeName: "", //ADMIN
		typeName: "", //Admin
		transKey: "admin"
	},
	2: {
		id: 2,
		dbTypeName: "", //CASHIER
		typeName: "", //Cashier
		transKey: "cashier"
	},
	get ADMIN() {
		return this[1];
	},
	get CASHIER() {
		return this[2];
	},
	get ALL() {
		return [this[1], this[2]];
	}
};
