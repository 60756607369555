namespace("oga.model");

oga.model.GameLight = function(dto) {
	this.id = dto.id;
	this.name = dto.name;

	if (dto.ticketPrice !== undefined) {
		this.ticketPrice = dto.ticketPrice;
	}

	if (Array.isArray(dto.profiles)) {
		this.profiles = ko.utils.arrayMap(dto.profiles, function(profileDto) {
			return new oga.model.GameSettingsProfileLight(profileDto);
		});
	}
};
