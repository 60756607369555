namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.GameSettingsProfileVM = function() {
	var self = this;
	var subscriptions = [];

	this.games = ko.observableArray([]);
	this.selectedGame = ko.observable(null);
	this.gameWithSettingsProfiles = ko.observable(null);
	this.shouldShowPosList = ko.observable(false);
	this.listOfPosForClickedProfile = ko.observableArray([]);
	this.posListTitle = ko.observable("");

	this.profileInEdit = ko.observable(null);

	this.isLoading = ko.observable(false);
	//hack needed for validatedObservable updating to work
	this.profileWorkCopy = ko.validatedObservable(getStubProfile(false, 100));
	this.profileWorkCopy(null);

	this.goToPos = function(pos) {
		oga.router.setHash("lotto-org/pos-profile/" + pos.id + "/tickets");
	};

	this.init = function(params) {
		params = params || {};

		subscriptions.push(self.selectedGame.subscribe(function(newSelectedGame) {
			if (newSelectedGame !== undefined && newSelectedGame !== null) {
				clearProfileEditDetails();
				loadGameWithSettingsProfiles(newSelectedGame.id);
			}
		}));

		loadGames(params.gameId);
	};

	this.showPosForProfile = function(profile) {
		
		if (profile.pointsOfSaleCount() > 0) {
			clearPosListDetails();
			self.isLoading(true);
			oga.restClient.getResource("api/game-settings-profiles/" + profile.id + "/points-of-sale").done(function(resp) {
				self.listOfPosForClickedProfile(ko.utils.arrayMap(resp, function(posDto) {
					return new oga.model.PointOfSaleLight(posDto);
				}));
				self.posListTitle("Game " + self.selectedGame().name + " - Profile " + profile.name());
				self.shouldShowPosList(true);
			}).always(function() {
				self.isLoading(false);
			});
		}
	};

	this.createNewProfile = function() {
		clearProfileEditDetails();
		self.profileWorkCopy(getStubProfile());
	};

	this.saveNewProfile = function() {
		var profileWorkCopy = self.profileWorkCopy();
		var dto = profileWorkCopy.toRequestDto();
		dto.gameId = self.selectedGame().id;
		self.isLoading(true);
		oga.restClient.postResource("api/game-settings-profiles", JSON.stringify(dto))
			.done(function(resp) {
				profileWorkCopy.id = resp.id;
				self.gameWithSettingsProfiles().settingsProfiles.unshift(self.profileWorkCopy());
				clearProfileEditDetails();
			}).always(function() {
				self.isLoading(false);
			});

	};

	this.editProfile = function(profile) {
		clearProfileEditDetails();
		self.profileInEdit(profile);
		if (profile !== null) {
			self.profileWorkCopy(profile.getClone());
		} else {
			self.profileWorkCopy(getStubProfile());
		}
	};

	this.saveProfileChanges = function() {
		var profileWorkCopy = self.profileWorkCopy();
		var profileInEdit = self.profileInEdit();

		if (JSON.stringify(profileWorkCopy.toRequestDto()) == JSON.stringify(profileInEdit.toRequestDto())) {
			clearProfileEditDetails();
			return;
		}
		self.isLoading(true);
		oga.restClient.putResource("api/game-settings-profiles/" + profileWorkCopy.id, JSON.stringify(profileWorkCopy.toRequestDto()))
			.done(function() {
				self.gameWithSettingsProfiles().settingsProfiles.replace(self.profileInEdit(), profileWorkCopy);
				clearProfileEditDetails();
			}).always(function() {
				self.isLoading(false);
			});

	};

	this.cancelProfileChanges = function() {
		clearProfileEditDetails();
	};

	this.deleteProfileInEdit = function() {
		self.isLoading(true);
		oga.restClient.deleteResource("api/game-settings-profiles/" + self.profileWorkCopy().id)
			.done(function() {
				self.gameWithSettingsProfiles().settingsProfiles.remove(self.profileInEdit());
				clearProfileEditDetails();
			}).always(function() {
				self.isLoading(false);
			});
	};

	function clearProfileEditDetails() {
		self.profileInEdit(null);
		self.profileWorkCopy(null);
	}

	function clearPosListDetails() {
		self.shouldShowPosList(false);
		self.listOfPosForClickedProfile([]);
		self.posListTitle("");
	}

	function loadGameWithSettingsProfiles(gameId) {
		clearProfileEditDetails();
		self.isLoading(true);
		oga.restClient.getResource("api/game-settings-profiles?gameId=" + gameId).done(function(resp) {
			var gameWithSettingsProfiles = new oga.model.GameWithSettingsProfiles(resp);
			self.gameWithSettingsProfiles(gameWithSettingsProfiles);
			if (self.gameWithSettingsProfiles().defaultProfile() === null) {
				self.profileWorkCopy(getStubProfile(true));
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	function loadGames(gameId) {
		self.games([]);
		self.isLoading(true);
		oga.restClient.getResource("api/games?form=light").done(function(resp) {
			var gameToShowInitially = null;
			self.games(ko.utils.arrayMap(resp, function(gameLightDto) {
				var gameLight = new oga.model.GameLight(gameLightDto);
				if (gameId !== undefined && gameId == gameLight.id) {
					gameToShowInitially = gameLight;
				}
				return gameLight;
			}));

			if (gameToShowInitially !== null) {
				self.selectedGame(gameToShowInitially);
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	function getStubProfile(isDefault, singleTicketPrice) {
		singleTicketPrice = singleTicketPrice || self.gameWithSettingsProfiles().singleTicketPrice;
		isDefault = isDefault || false;
		var dto = {};
		if (isDefault) {
			dto.name = "DEFAULT";
		}
		dto.isDefault = isDefault;
		dto.isOrderingEnabled = true;
		dto.isReturnEnabled = true;
		dto.pointsOfSaleCount = 0;

		return new oga.model.GameSettingsProfile(dto, singleTicketPrice);
	}

	this.dispose = function() {
		subscriptions.forEach(function(subscription) {
			subscription.dispose();
		});
	};

};
