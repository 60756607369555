namespace("oga.model");

oga.model.PosGameSettingsProfile= function(dto) {
	this.profileName = dto.name;
	this.minimumOrderQuantity = dto.minimumOrderQuantity;
	this.orderBatchSize = dto.orderBatchSize;
	this.singleTicketPrice = dto.singleTicketPrice;
	this.isOrderingEnabled = dto.isOrderingEnabled;
	this.gameId = dto.gameId;
	this.gameName = dto.gameName;
};
