namespace("oga.model");

oga.model.OrderItem = function(dto, orderBarcodeUnitsRef) {
	var self = this;
	var toggleAllChecksVal = true;
	this.game = new oga.model.GameLight(dto.game);
	this.quantity = dto.quantity;

	if (orderBarcodeUnitsRef !== undefined) {
		this.barcodeUnits = ko.pureComputed(function() {
			var filteredBarcodeUnits = [];
			var barcodeUnits = orderBarcodeUnitsRef();
			barcodeUnits.forEach(function(barcodeUnit) {
				if (self.game.id == barcodeUnit.game.id) {
					filteredBarcodeUnits.push(barcodeUnit);
				}
			});

			return filteredBarcodeUnits;
		});

		this.barcodeUnitsCount = ko.pureComputed(function() {
			return self.barcodeUnits().length;
		});

		this.pagination = new oga.component.pagination.Pagination({
			pageSize: 5
		});

		this.barcodeUnitsForPage = ko.pureComputed(function() {
			var barcodeUnits = self.barcodeUnits();
			return self.pagination.getItemsForPage(barcodeUnits);
		});

		this.appropriatedTicketsCount = ko.pureComputed(function() {
			var ticketsCount = 0;
			self.barcodeUnits().forEach(function(barcodeUnit) {
				ticketsCount += parseInt(barcodeUnit.ticketsCount);
			});

			return ticketsCount;
		});

		this.areAllBarcodeUnitsChecked = ko.pureComputed(function() {
			var barcodeUnits = self.barcodeUnits();
			for (var i = 0; i < barcodeUnits.length; i++) {
				var barcodeUnit = barcodeUnits[i];
				if (!barcodeUnit.isChecked()) {
					return false;
				}
			}

			return true;
		});

		this.demandMatchesSupply = ko.pureComputed(function() {
			return self.quantity == self.appropriatedTicketsCount();
		});

		this.toggleAllBarcodeUnits = function() {
			self.barcodeUnits().forEach(function(barcodeUnit) {
				barcodeUnit.isChecked(toggleAllChecksVal);
			});

			toggleAllChecksVal = !toggleAllChecksVal;
		};
	}
};
