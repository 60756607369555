namespace("oga.nomenclature");

oga.nomenclature.BarcodeUnitType = [
	{
		id: 3,
		name: "", //carton
		transKey: "carton",
		dropdownValue: "CARTON"
	},
	{
		id: 2,
		name: "", //box
		transKey: "box",
		dropdownValue: "BOX"		
	},
	{
		id: 1,
		name: "", //banderol
		transKey: "banderol",
		dropdownValue: "BANDEROL"
	},
	{
		id: null,
		name: "", //ticket
		transKey: "ticket",
		dropdownValue: "TICKET"
	}
];
