namespace("oga.model");

oga.model.OrderCreationItem = function(games) {
	var self = this;
	this.selectedGame = ko.observable();
	this.quantity = ko.observable();
	this.isValid = ko.observable();
	
	this.availableGames = ko.pureComputed(function() {
		var availableGames = games();
		if (self.selectedGame()) {
			return [self.selectedGame()].concat(availableGames);
		} 	
		return availableGames;
	}).extend({deferred: true});
};