namespace("oga.binding");

oga.binding.NumberSpaceFormatBinding = {
	update : function(element, valueAccessor) {
		var valueUnwrapped = ko.unwrap(valueAccessor());
		var $element = $(element);

		var spaceFormattedNumber = oga.formatNumber(valueUnwrapped);

		$element.text(spaceFormattedNumber);
	}
};
