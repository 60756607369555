namespace("oga.model");

oga.model.Order = function(dto) {
	var self = this;

	dto.barcodeUnits = dto.barcodeUnits || [];
	dto.orderItems = dto.orderItems || [];

	this.id = dto.id;
	this.ticketsCount = ko.observable(dto.ticketsCount);
	this.createdAt = moment.fromJacksonDateTimeArray(dto.createdAt);
	if (dto.processedAt !== null && dto.processedAt !== undefined) {
		this.processedAt = moment.fromJacksonDateTimeArray(dto.processedAt);
	}
	this.status = ko.observable(oga.nomenclature.OrderStatusType[dto.statusId]);
	if (dto.pointOfSale !== undefined) {
		this.pointOfSale = ko.observable(new oga.model.PointOfSaleLight(dto.pointOfSale));
	}

	this.barcodeUnits = ko.observableArray(ko.utils.arrayMap(dto.barcodeUnits, function(barcodeUnitDto) {
		return new oga.model.CheckableBarcodeUnit(barcodeUnitDto);
	}));

	this.orderItems = ko.utils.arrayMap(dto.orderItems, function(orderItemDto) {
		return new oga.model.OrderItem(orderItemDto, self.barcodeUnits);
	});

	this.areAllBarcodeUnitsChecked = ko.pureComputed(function() {
		for (var i = 0; i < self.orderItems.length; i++) {
			if (!self.orderItems[i].areAllBarcodeUnitsChecked()) {
				return false;
			}
		}

		return true;
	});

	this.demandMatchesSupplyForAllOrderItems = ko.pureComputed(function() {
		for (var i = 0; i < self.orderItems.length; i++) {
			if (!self.orderItems[i].demandMatchesSupply()) {
				return false;
			}
		}

		return true;
	});

	this.doesOrderContainGameWithId = function(gameId) {
		for (var i = 0; i < self.orderItems.length; i++) {
			if (self.orderItems[i].game.id == gameId) {
				return true;
			}
		}

		return false;
	};

};
