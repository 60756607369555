namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.OrderOpenVM = function() {
	var self = this;
	var orderId = null;
	var barcodeScannerMannager = null;
	var nameSpace = "OrdersOpenVM";
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	this.order = ko.observable(null);
	this.isProcessingOrder = ko.observable(false);
	this.isCancelingOrder = ko.observable(false);
	this.isCheckingBarcode = ko.observable(false);
	this.isOrderNotFound = ko.observable(false);

	this.enteredBarcode = ko.observable("");

	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});
	this.orderItemOptions = ko.pureComputed(function() {
		var order = self.order();
		var gameOptions = [{
			name: i18next.t("all"),
			value: null
		}];

		if (order !== null) {
			order.orderItems.forEach(function(orderItem) {
				gameOptions.push({
					name: orderItem.game.name,
					value: orderItem
				});
			});
		}

		return gameOptions;

	});
	this.selectedOrderItem = ko.observable(self.orderItemOptions()[0]);


	this.filteredOrderItems = ko.pureComputed(function() {
		var order = self.order();
		var selectedOrderItem = self.selectedOrderItem();

		if (order !== null) {
			if (selectedOrderItem.value === null) {
				return order.orderItems;
			} else {
				for (var i = 0; i < order.orderItems.length; i++) {
					var orderItem = order.orderItems[i];
					if (orderItem.game.id == selectedOrderItem.value.game.id) {
						return [orderItem];
					}
				}

				return [];
			}
		}
	});

	this.init = function(params) {
		orderId = params.id;
		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 12,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.addBarcodeUnit
		});

		barcodeScannerMannager.start();
		loadOrder();
	};

	this.canEditOrder = ko.pureComputed(function() {
		var order = self.order();
		if (order == null) {
			return false;
		}
		return order.status() == oga.nomenclature.OrderStatusType.WAITING;
	});

	this.removeBarcodeUnit = function(barcodeUnit) {
		self.order().barcodeUnits.remove(barcodeUnit);
	};

	this.addBarcodeUnit = function() {
		if (self.isCheckingBarcode()) {
			return;
		}
		var barcode = self.enteredBarcode();
		var currentBarcodeUnits = self.order().barcodeUnits();

		var barcodeCheckDetails = {
			barcode: barcode,
			barcodeCheckStatus: BarcodeCheckStatus.STANDBY
		};

		for (var i = 0; i < currentBarcodeUnits.length; i++) {
			var currentBarcodeUnit = currentBarcodeUnits[i];
			if (currentBarcodeUnit.barcodeNumber == barcode) {
				barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.DUPLICATE;
				self.lastCheckBarcodeDetails(barcodeCheckDetails);
				return;
			}
		}

		self.isCheckingBarcode(true);
		oga.restClient.getResource("api/barcode-units/" + barcode + "/orderable").done(function(resp) {
			var checkableBarcodeUnit = new oga.model.CheckableBarcodeUnit(resp);
			var barcodeIsForGameInOrder = self.order().doesOrderContainGameWithId(checkableBarcodeUnit.game.id);
			if (barcodeIsForGameInOrder) {
				self.order().barcodeUnits.unshift(checkableBarcodeUnit);
				barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.ADDED;
			} else {
				barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.NO_GAME;
			}
		}).fail(function(resp) {
			var errorStatus = BarcodeCheckStatus.ERROR;
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "BarcodeUnitNotFoundException":
						errorStatus = BarcodeCheckStatus.NOT_FOUND;
						break;
					case "InvalidBarcodeUnitException":
						errorStatus = BarcodeCheckStatus.ERROR;
						break;
					default:
						errorStatus = BarcodeCheckStatus.ERROR;
				}
			}
			barcodeCheckDetails.barcodeCheckStatus = errorStatus;
		}).always(function() {
			self.lastCheckBarcodeDetails(barcodeCheckDetails);
			self.isCheckingBarcode(false);
		});
	};

	this.processOrder = function() {
		var barcodesData = {
			barcodeNumbers: []
		};
		var barcodeUnits = self.order().barcodeUnits();
		barcodeUnits.forEach(function(barcodeUnit) {
			barcodesData.barcodeNumbers.push(barcodeUnit.barcodeNumber);
		});

		self.isProcessingOrder(true);
		oga.restClient.postResource("api/orders/" + orderId + "/processing", JSON.stringify(barcodesData)).done(function(resp) {
			self.order(new oga.model.Order(resp));
		}).always(function() {
			self.isProcessingOrder(false);
		});
	};

	this.cancelOrder = function() {
		var msg = "cancel.order.confirm.msg";
		oga.popupManager.confirm(msg).done(function() {
			doCancelOrder();
		});
	};

	function doCancelOrder() {
		self.isCancelingOrder(true);
		oga.restClient.postResource("api/orders/" + orderId + "/cancel").done(function() {
			self.order().status(oga.nomenclature.OrderStatusType.CANCELED);
		}).always(function() {
			self.isCancelingOrder(false);
		});
	}

	function loadOrder() {
		oga.restClient.getResource("api/orders/" + orderId).done(function(resp) {
			self.order(new oga.model.Order(resp));
		}).fail(function(resp) {
			if (resp.responseJSON !== undefined) {
				if (resp.responseJSON.error == "EntityNotFoundException") {
					self.isOrderNotFound(true);
				}
			}
		});
	}

	this.dispose = function() {
		barcodeScannerMannager.dispose();
	};

};
