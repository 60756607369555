namespace("oga.model");

oga.model.RemainingGameWinning = function(dto) {
	var self = this;

	this.remainingCount = dto.remainingCount;
	this.value = dto.value !== undefined ? dto.value : null;
	this.description = dto.description !== undefined ? dto.description : null;
	this.winningTicketsCount = dto.winningTicketsCount;

	this.concatedDescription = function() {
		var result = oga.formatNumber(self.winningTicketsCount) + " x " + oga.settings.NAIRA_SYMBOL + oga.formatNumber(self.value);
		if (self.description !== null && self.description.trim() != "") {
			result += " (" + self.description + ")";
		}
		return result;
	};

	this.totalPrize = function() {
		var totalPrize = parseInt(self.winningTicketsCount) * parseInt(self.value);
		return oga.formatNumber(totalPrize);
	};
};
