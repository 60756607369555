namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosRequestsVM = function() {
	var self = this;
	var posId = null;

	var showLastFivePosResultsParams = {
		page: 1,
		pageSize: 5,
		orderBy: "createdAt",
		direction: "desc"
	};

	this.orders = ko.observableArray([]);
	this.returns = ko.observableArray([]);
	this.redemptions = ko.observableArray([]);

	this.hasNoOrders = ko.observable(false);
	this.hasNoReturns = ko.observable(false);
	this.hasNoRedemptions = ko.observable(false);

	this.isLoadingOrders = ko.observable(false);
	this.isLoadingReturns = ko.observable(false);
	this.isLoadingRedemptions = ko.observable(false);

	this.init = function(params) {
		posId = params.id;
		showLastFivePosResultsParams.pointOfSaleId = posId;

		loadLastFiveOrders();
		loadLastFiveReturns();
		loadLastFiveRedemptions();
	};

	this.openOrder = function(order) {
		oga.router.setHash("lotto-org/order-open/" + order.id);
	};

	this.openReturn = function(returnRequest) {
		oga.router.setHash("lotto-org/return-request-open/" + returnRequest.id);
	};

	this.openRedemption = function(redemption) {
		oga.router.setHash("lotto-org/redemption-request-open/" + redemption.id);
	};

	this.isAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	function loadLastFiveOrders() {
		self.isLoadingOrders(true);
		oga.restClient.getResource("api/orders", showLastFivePosResultsParams).done(function(resp) {
			self.orders(ko.utils.arrayMap(resp.items, function(orderDto) {
				return new oga.model.Order(orderDto);
			}));

			self.hasNoOrders(self.orders().length === 0);
		}).always(function() {
			self.isLoadingOrders(false);
		});
	}

	function loadLastFiveReturns() {
		self.isLoadingReturns(true);
		oga.restClient.getResource("api/return-requests", showLastFivePosResultsParams).done(function(resp) {
			self.returns(ko.utils.arrayMap(resp.items, function(returnDto) {
				return new oga.model.ReturnRequest(returnDto);
			}));

			self.hasNoReturns(self.returns().length === 0);
		}).always(function() {
			self.isLoadingReturns(false);
		});
	}

	function loadLastFiveRedemptions() {
		self.isLoadingRedemptions(true);
		oga.restClient.getResource("api/redemption-requests", showLastFivePosResultsParams).done(function(resp) {
			self.redemptions(ko.utils.arrayMap(resp.items, function(redemptionDto) {
				return new oga.model.RedemptionRequest(redemptionDto);
			}));

			self.hasNoRedemptions(self.redemptions().length === 0);
		}).always(function() {
			self.isLoadingRedemptions(false);
		});
	}
};
