namespace("oga.model");

oga.model.PacketLight = function(dto) {
	this.id = dto.id;
	this.barcodeNumber = dto.barcodeNumber;

	this.type = oga.nomenclature.BarcodeUnitType.filter(function (type) {
		return type.id == dto.typeId;
	})[0];
};
