namespace("oga.viewmodel.pos.admin");

oga.viewmodel.pos.admin.RedemptionRequestsVM = function() {

	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;
	var PAGE_SIZE = 15;
	var requestStatus = oga.nomenclature.RequestStatusType;

	this.statusOptions = ko.observableArray([
		requestStatus.WAITING, requestStatus.APPROVED
	]);	
	self.selectedStatus = ko.observable(undefined);
	self.redemptionRequests = ko.observableArray([]);
	
	self.isLoading = ko.observable(false);
	
	self.redemptionRequestsCount = ko.observable();
	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "createdAt",
		direction: "desc",
		pageSize: PAGE_SIZE
	});	
	
	this.init = function() {
		subscriptions.push(self.selectedStatus.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadRedemptionRequests();
		}).extend({
			deferred: true
		}));
	};
	
	function loadRedemptionRequests() {
		self.isLoading(true);
		restClient.getResource("/api/points-of-sale/current/redemption-requests", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			statusId: self.selectedStatus()
		}).done(function(resp) {
			self.redemptionRequests(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.RedemptionRequest(dto);
			}));
			self.redemptionRequestsCount(resp.count);
		}).always(function() {
			self.isLoading(false);
		});
	}
	
	self.openRedemption = function(id) {
		//TODO add details to the setHash so the redirect is to the particular redemption and remove the console.log which is an eslint hack
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "redemption-request-open/" + id);
	};
	self.createRedemption = function() {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "redemption-request-create");
	};
	
	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};

};
