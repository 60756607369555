namespace("oga.model");

oga.model.GameLot = function(dto) {
	this.id = dto.id;
	this.number = dto.number;
	this.ticketsCount = dto.ticketsCount;

	if (dto.game !== undefined) {
		this.game = new oga.model.GameLight(dto.game);
	}
};
