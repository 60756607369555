namespace("oga.model");

oga.model.CheckedTicket = function(dto) {
	this.barcodeNumber = dto.barcodeNumber;
	this.winning = dto.winning;
	this.soldAt = moment.fromJacksonDateTimeArray(dto.soldAt);
	this.pointOfSale = dto.pointOfSale;
	this.paidAt = moment.fromJacksonDateTimeArray(dto.paidAt);
	this.paymentPointOfSale = dto.paymentPointOfSale;
	this.winPaymentLimit = dto.winPaymentLimit;
	
	this.isPaid = dto.paidAt != null;
	this.isWinningCash = dto.winning.description == null;
	this.hasPaymentLimit = dto.winPaymentLimit != null;
	this.isPayable = !this.isPaid && (!this.hasPaymentLimit || (this.winning.value <= this.winPaymentLimit));
};
