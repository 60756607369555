namespace("oga.model");

oga.model.DateRange = function (fromDate, toDate, typeRange) {
	this.fromDate = ko.observable(fromDate);
	this.toDate = ko.observable(toDate);
	if (typeRange != undefined) {
		this.typeRange = ko.observable(typeRange);
	} else {
		this.typeRange = ko.observable(undefined);
	}
};