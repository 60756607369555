namespace("oga.binding");

(function() {
	function isAnyRoleGranted(valueAccessor) {
		var roles = ko.unwrap(valueAccessor());
		if (!$.isArray(roles)) {
			roles = roles.replace(/ /g, "");
			roles = roles.split(",");
		}
		var SecurityUtil = oga.security.SecurityUtil;
		var isGranted = SecurityUtil.isAnyGranted.apply(SecurityUtil, roles);
		return isGranted;
	}
	
	function isNoRoleGranted(valueAccessor) {
		var roles = ko.unwrap(valueAccessor());
		if (!$.isArray(roles)) {
			roles = roles.replace(/ /g, "");
			roles = roles.split(",");
		}
		var SecurityUtil = oga.security.SecurityUtil;
		var isNotGranted = SecurityUtil.isNoneGranted.apply(SecurityUtil, roles);
		return isNotGranted;
	}
	
	oga.binding.hasAnyRole = {
		update: function(element, valueAccessor) {
			var isGranted = isAnyRoleGranted(valueAccessor);
			ko.bindingHandlers["visible"].update(element, function() {
				return isGranted;
			});
		}
	};
	
	oga.binding.hasNoRole = {
		update: function(element, valueAccessor) {
			var isGranted = isNoRoleGranted(valueAccessor);
			ko.bindingHandlers["visible"].update(element, function() { 
				return isGranted;
			});
		}
	};
})();
