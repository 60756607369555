namespace("oga.model");

oga.model.Button = function(options) {
	var viewName = options.viewName;
	var hashOnWhichIsActive = options.hashOnWhichIsActive || [];
	hashOnWhichIsActive.push(viewName);
	var hasNoView = options.hasNoView || false;

	var COMMON_POS_ITEMS = ["dashboard", "sellTicketsParent", "sell", "inventorizations",
		"bulk-sell", "ticketsParent", "check", "transfers", "availability",
		"pos-profile/" + oga.authenticatedUser().pointOfSale.id + "/winnings"];
	var POS_CASHIER_MENU_ITEMS = [].concat(COMMON_POS_ITEMS);
	var POS_ADMIN_MENU_ITEMS = ["redemption-requests", "return-requests", "orders", "cashiers", "blocklist"].concat(COMMON_POS_ITEMS);

	var LOTTO_ORG_MENU_ITEMS = ["dashboard", "pos", "check", "ticketsParent", "redemption-requests", "return-requests", "gamesParent", 
		"games", "groups", "orders", "availability", "transactions", "reports", "blocklist", "game-profiles", "game-winnings", "account",
		"barcode-unit-log", "users", "inventorizations"
	];

	var LOTTO_ORG_ACCOUNTANT_MENU_ITEMS = ["pos", "transactions", "account", "barcode-unit-log"];

	/*
	*  Plus inventarisation and дневния генериран дневен файл с наличности (от стр. справки)\
	*  when implemented
	*/
	var LOTTO_ORG_LOGISTICS_MENU_ITEMS = ["pos", "ticketsParent", "redemption-requests", "return-requests", "orders", "availability",
		"account", "barcode-unit-log", "inventorizations"];

	var LOTTO_ORG_REPRESENTATIVE_MENU_ITEMS = ["pos", "ticketsParent", "redemption-requests", "orders"];

	var LOTTO_ORG_REGULATOR_MENU_ITEMS = ["availability", "gamesParent", "game-winnings", "reports"];

	var self = this;
	this.viewName = viewName;
	this.isActive = ko.observable(false);

	oga.events.subscribe(oga.Event.ROUTE_CHANGED, function(event, route) {
		self.isActive(hashOnWhichIsActive.indexOf(route.url.split("/")[1]) > -1);
	});

	this.isVisible = ko.pureComputed(function() {
		var result = false;
		if (oga.authenticatedUser() !== null) {
			var specificMenuItems = [];

			switch (oga.authenticatedUser().authority) {
				case oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN:
					specificMenuItems = POS_ADMIN_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION:
					specificMenuItems = LOTTO_ORG_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT:
					specificMenuItems = LOTTO_ORG_ACCOUNTANT_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS:
					specificMenuItems = LOTTO_ORG_LOGISTICS_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE:
					specificMenuItems = LOTTO_ORG_REPRESENTATIVE_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR:
					specificMenuItems = LOTTO_ORG_REGULATOR_MENU_ITEMS;
					break;
				case oga.nomenclature.Roles.ROLE_POINT_OF_SALE_CASHIER:
					specificMenuItems = POS_CASHIER_MENU_ITEMS;
					break;
			}

			result = specificMenuItems.indexOf(self.viewName) > -1;
		}
		return result;

	});

	this.goToView = function() {
		if (oga.authenticatedUser() === null || hasNoView) {
			return;
		}

		oga.router.setHash(oga.authenticatedUser().hashPrefix + self.viewName);
	};

	this.viewHash = ko.pureComputed(function() {
		if (oga.authenticatedUser() === null || hasNoView) {
			return "";
		}
		return oga.authenticatedUser().hashPrefix + self.viewName;
	});
};
