namespace("oga.model");

oga.model.RedemptionRequest = function(dto) {
	this.id = dto.id;
	this.ticketsCount = ko.observable(dto.ticketsCount);
	this.createdAt = moment.fromJacksonDateTimeArray(dto.createdAt);
	this.amount = ko.observable(dto.amount);
	this.isAutomatic = ko.observable(dto.isAutomatic);

	if (dto.statusId !== undefined) {
		this.status = ko.observable(oga.nomenclature.RequestStatusType[dto.statusId]);
	}

	if (dto.approvedAt !== undefined) {
		this.approvedAt = moment.fromJacksonDateTimeArray(dto.approvedAt);
	}

	if (dto.pointOfSale !== undefined) {
		this.pointOfSale = ko.observable(new oga.model.PointOfSaleLight(dto.pointOfSale));
	}

	if (dto.tickets !== undefined) {
		this.tickets = ko.utils.arrayMap(dto.tickets, function(ticketDto) {
			return new oga.model.CheckableTicket(ticketDto);
		});
	}
};
