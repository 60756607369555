namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PointsOfSaleVM = function() {
	var self = this;
	var POS_PAGE_SIZE = 15;
	var direction = "desc";

	var subscriptions = [];

	this.isLoadingPosList = ko.observable(false);
	this.pointsOfSaleForPage = ko.observableArray([]);
	this.totalSpecifiedPointsOfSaleCount = ko.observable(0);
	this.tagsArray = ko.observableArray([]);
	this.posInEdit = ko.observableArray([]);
	this.isCreatingPos = ko.observable(false);
	this.posInCreation = ko.validatedObservable(getStubPos());
	this.posNameIdParam = ko.observable();
	this.representatives = ko.observableArray([]);
	this.representativeName = ko.observable();
	
	this.creditLimitOptions = [{
		name: i18next.t("all"),
		value: null
	}, {
		name: i18next.t("prepaid"),
		value: false
	}, {
		name: i18next.t("credit"),
		value: true
	}];

	this.selectedCreditLimitFilter = ko.observable(null);

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: POS_PAGE_SIZE,
		direction: direction
	});

	this.posListRequestParams = ko.computed(function() {
		var pageQueryParams = self.pagination.queryParamsObject();
		var posListRequestParams = $.extend({}, pageQueryParams, {
			pointOfSaleTags: self.tagsArray(),
			nameOrId: self.posNameIdParam(),
			representativeName: self.representativeName()
		});
		var creditLimitFilter = self.selectedCreditLimitFilter();
		if (creditLimitFilter !== null && creditLimitFilter.value !== null) {
			posListRequestParams.withCreditLimit = creditLimitFilter.value;
		}

		return posListRequestParams;
	}).extend({
		deferred: true
	});

	subscriptions.push(self.posListRequestParams);

	this.isAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE;
	});

	this.isAdminOnly = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	this.init = function() {
		subscriptions.push(self.posListRequestParams.subscribe(function(requestParams) {
			loadPointsOfSale(requestParams);
		}));

		subscriptions.push(ko.computed(function() {
			self.tagsArray();
			self.selectedCreditLimitFilter();

			self.pagination.page(1);
		}));
	};

	this.openProfile = function(profileId) {
		oga.router.setHash("#lotto-org/pos-profile/" + profileId + "/winnings");
	};

	this.openProfileAtUsers = function(pos) {
		oga.router.setHash("#lotto-org/pos-profile/" + pos.id + "/users");
	};

	this.toggleIsPosEnabled = function(pointOfSale) {
		var requestedState = !pointOfSale.isEnabled();
		self.posInEdit.push(pointOfSale);

		oga.restClient.patchResource("api/points-of-sale/" + pointOfSale.id + "/enabled", JSON.stringify({
			isEnabled: requestedState
		})).done(function() {
			pointOfSale.isEnabled(requestedState);
		}).always(function() {
			self.posInEdit.remove(pointOfSale);
		});
	};

	this.createNewPos = function() {
		self.representatives([]);
		oga.restClient.getResource("api/users/representatives").done(function(posEmployeeDtos) {
			self.representatives(ko.utils.arrayMap(posEmployeeDtos, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
			self.posInCreation(getStubPos());
			self.isCreatingPos(true);
		});
	};

	this.isSavingPos = ko.observable(false);

	this.saveNewPos = function() {
		if (self.isSavingPos()) {
			return;
		}
		this.isSavingPos(true);
		var newPos = self.posInCreation();
		oga.restClient.postResource("api/points-of-sale", JSON.stringify(newPos.toDtoLight()))
			.done(function() {
				reloadPosListWithNewestOnTop(self.posListRequestParams());
				self.isCreatingPos(false);
			}).always(function() {
				self.isSavingPos(false);
			});
	};

	function reloadPosListWithNewestOnTop() {
		var tagsAreEmpty = self.tagsArray() == [];
		var isOnFirstPage = self.pagination.page() == 1;
		var directionIsDesc = self.pagination.direction() == "desc";
		var orderByPropIsId = self.pagination.orderBy() == "id";
		var hasNoCreditLimitFilter = self.selectedCreditLimitFilter() == null;

		var requestParamsWontTriggerReload = tagsAreEmpty && isOnFirstPage && directionIsDesc && orderByPropIsId && hasNoCreditLimitFilter;

		if (!tagsAreEmpty) {
			self.tagsArray([]);
		}

		if (!isOnFirstPage) {
			self.pagination.page(1);
		}

		if (!directionIsDesc) {
			self.pagination.direction("desc");
		}

		if (!orderByPropIsId) {
			self.pagination.orderBy("id");
		}

		if (!hasNoCreditLimitFilter) {
			self.selectedCreditLimitFilter({
				name: "All",
				value: null
			});
		}

		if (requestParamsWontTriggerReload) {
			loadPointsOfSale(self.posListRequestParams());
		}

	}
	
	function loadPointsOfSale(posListRequestParams) {
		self.pointsOfSaleForPage([]);

		self.isLoadingPosList(true);
		oga.restClient.getResource("api/points-of-sale", $.param(posListRequestParams)).done(function(resp) {
			self.totalSpecifiedPointsOfSaleCount(resp.count);
			self.pointsOfSaleForPage(ko.utils.arrayMap(resp.items, function(posDto) {
				return new oga.model.PointOfSale(posDto);
			}));
		}).always(function() {
			self.isLoadingPosList(false);
		});
	}

	this.exportExcel = function() {
		var params = self.posListRequestParams();
		params.lang = oga.util.i18n.getLanguage();

		var urlQueryParams = $.param(params, true);
		location.href = "/api/points-of-sale/xls?" + urlQueryParams;
	};

	function getStubPos() {
		return new oga.model.PointOfSale();
	}

	this.dispose = function() {
		subscriptions.forEach(function(koSubscription) {
			koSubscription.dispose();
		});
	};

};
