namespace("oga.model");

oga.model.ReportRegulatorMonth = function(dto) {
	var reportDate = new Date(moment.fromJacksonDateTimeArray(dto.reportDate));
	this.dateFrom = moment(new Date(reportDate.getFullYear(), reportDate.getMonth() - 1, 1));
	this.dateTo = moment(new Date(reportDate.getFullYear(), reportDate.getMonth(), 0));
	this.reportDate = reportDate;
	this.regulatorDtos = ko.utils.arrayMap(dto.regulatorDtos, function(reportDto) {
		return new oga.model.ReportRegulator(reportDto);
	});
};
