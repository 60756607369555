namespace("oga.model");

oga.model.PointOfSale = function(dto) {
	var self = this;
	dto = dto || {};

	var geoCooValidationRule = {
		params: /^(-?[0-9][0-9]?)((?:\.[0-9]{1,6})?)$/,
		message: "Enter a valid point"
	};

	var phoneRule = {
		params: /^\+?[0-9]{4,12}$/,
		message: "Enter a valid phone number"
	};

	this.id = dto.id;
	this.name = ko.observable(dto.name).extend({
		required: true
	});
	this.representativeId = ko.observable(dto.representativeId !== undefined ? dto.representativeId : null);
	this.representativeName = ko.observable(dto.representativeName !== undefined ? dto.representativeName : null);
	this.latitude = ko.observable(dto.latitude !== undefined ? dto.latitude : null).extend({
		pattern: geoCooValidationRule
	});
	this.longitude = ko.observable(dto.longitude !== undefined ? dto.longitude : null).extend({
		pattern: geoCooValidationRule
	});
	this.address = ko.observable(dto.address).extend({
		required: true
	});
	this.email = ko.observable(dto.email !== undefined ? dto.email : null).extend({
		required: true,
		email: true
	});
	this.phoneNumber = ko.observable(dto.phoneNumber !== undefined ? dto.phoneNumber : null).extend({
		required: true,
		pattern: phoneRule
	});

	this.creditLimit = ko.observable(dto.creditLimit);
	this.isEnabled = ko.observable(dto.isEnabled !== undefined ? dto.isEnabled : false).extend({
		required: true
	});
	this.balance = ko.observable(dto.balance !== undefined ? dto.balance : 0).extend({
		required: true,
		number: true
	});
	this.usersCount = ko.observable(dto.usersCount !== undefined ? parseInt(dto.usersCount) : 0);
	this.availableTicketsCount = ko.observable(dto.availableTicketsCount !== undefined ? dto.availableTicketsCount : 0);
	this.tags = ko.observableArray(dto.tags !== undefined ? ko.utils.arrayMap(dto.tags, function(tag) {
		if (typeof(tag) == "object") {
			return tag.name;
		} else {
			return tag;
		}
	}) : []);

	this.isCredit = ko.pureComputed(function() {
		return self.creditLimit() > 0;
	});

	this.tagsConcat = ko.pureComputed(function() {
		var tags = self.tags();
		
		return tags.join(", ");
	});

	this.reprNames = ko.observableArray(dto.representativeNames !== null ? ko.utils.arrayMap(dto.representativeNames, function(reprName) {
		if (typeof(reprName) == "object") {
			return reprName.fullName;
		} else {
			return reprName;
		}
	}) : []);

	this.representativeNamesConcat = ko.pureComputed(function() {
		var names = self.reprNames();
		if (names.length == 0) {
			return "-";
		}

		return names.join(", ");
	});

	this.toDtoLight = function() {
		var dtoLight = {};
		dtoLight.name = self.name();
		if (self.latitude() !== null && self.longitude() !== null) {
			dtoLight.latitude = self.latitude();
			dtoLight.longitude = self.longitude();
		}
		dtoLight.representativeId = self.representativeId();
		dtoLight.address = self.address();
		dtoLight.email = self.email();
		dtoLight.phoneNumber = self.phoneNumber();
		dtoLight.tags = self.tags();

		return dtoLight;
	};

	this.toDto = function() {
		var dto = {};
		dto.id = self.id;
		dto.name = self.name();
		dto.representativeId = self.representativeId();
		dto.latitude = self.latitude();
		dto.longitude = self.longitude();
		dto.address = self.address();
		dto.email = self.email();
		dto.phoneNumber = self.phoneNumber();
		dto.creditLimit = self.creditLimit();
		dto.isEnabled = self.isEnabled();
		dto.balance = self.balance();
		dto.tags = self.tags();
		dto.usersCount = self.usersCount();
		dto.availableTicketsCount = self.availableTicketsCount();

		return dto;
	};

	this.getClone = function() {
		return new oga.model.PointOfSale(self.toDto());
	};
};
