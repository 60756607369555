namespace("oga.viewmodel.pos");

oga.viewmodel.pos.TransferOpenVM = function() {
	var self = this;
	var transferId = null;
	var PAGE_SIZE = 15;

	this.isTransferNotFound = ko.observable(false);
	this.isTransferForbidden = ko.observable(false);
	this.isLoadingTransfer = ko.observable(false);

	this.transfer = ko.observable(null);

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE
	});

	this.barcodeUnitsForPage = ko.pureComputed(function() {
		self.pagination.queryParamsObject();
		var transfer = self.transfer();
		var barcodeUnitsForPage = [];

		if (transfer !== null) {
			barcodeUnitsForPage = self.pagination.getItemsForPage(transfer.barcodeUnits);
		}

		return barcodeUnitsForPage;
	});

	this.totalTransferTickets = ko.pureComputed(function () {
		var transfer = self.transfer();

		if (transfer == null) {
			return "";
		}

		var total = 0;
		for (var i = 0; i < transfer.barcodeUnits.length; i++) {
			var barcodeUnit = transfer.barcodeUnits[i];
			total += barcodeUnit.ticketsCount;
		}

		return total;
	});

	this.init = function(params) {
		transferId = params.id;

		loadTransfer();
	};


	function loadTransfer() {
		self.isLoadingTransfer(true);
		oga.restClient.getResource("api/transfers/" + transferId).done(function(responseDto) {
			self.transfer(new oga.model.Transfer(responseDto));
		}).fail(function(resp) {
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "EntityNotFoundException":
						self.isTransferNotFound(true);
						break;
					case "UserNotSenderOrRecipientOfTrasnferException":
						self.isTransferForbidden(true);
						break;
					default:
						oga.logger("unexpected error when loading transfer");
				}
			}
		}).always(function() {
			self.isLoadingTransfer(false);
		});
	}

};
