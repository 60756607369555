namespace("oga.model");

oga.model.ReturnRequest = function(dto) {
	this.id = dto.id;
	this.ticketsCount = ko.observable(dto.ticketsCount);
	this.createdAt = moment.fromJacksonDateTimeArray(dto.createdAt);
	this.status = ko.observable(oga.nomenclature.RequestStatusType[dto.statusId]);

	if (this.status() == oga.nomenclature.RequestStatusType.APPROVED && dto.approvedAt !== undefined) {
		this.approvedAt = moment.fromJacksonDateTimeArray(dto.approvedAt);
	}

	if (dto.pointOfSale !== undefined) {
		this.pointOfSale = ko.observable(new oga.model.PointOfSaleLight(dto.pointOfSale));
	}

	if (dto.barcodeUnits !== undefined) {
		this.barcodeUnits = ko.utils.arrayMap(dto.barcodeUnits, function(barcodeUnitDto) {
			var checkableBarcodeUnit = new oga.model.CheckableBarcodeUnitLight(barcodeUnitDto);
			return checkableBarcodeUnit;
		});
	}

};
