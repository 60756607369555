namespace("oga.model");

oga.model.InventorizationUser = function(dto) {
	this.id = dto.id;
	this.fullName = dto.fullName;
};

oga.model.InventorizationListItem = function(dto) {
	this.id = dto.id;
	this.createdAt = moment.fromJacksonDateTimeArray(dto.createdAt);
	this.user = new oga.model.InventorizationUser(dto.user);
	this.status = oga.nomenclature.InventorizationStatusType[dto.statusId];
	this.missingBarcodesCount = dto.missingBarcodesCount;
};
