namespace("oga.component");

oga.component.PosUsers = function(params) {
	var self = this;
	var PosEmployeeType = oga.nomenclature.PosEmployeeType;
	
	this.posId = params.posId;
	this.onDeleteCallBack = params.onDeleteCallBack;
	this.posEmployees = params.posEmployees;
	this.isAddingPosEmployee = ko.observable(false);
	this.isSavingEmployeeOnRemote = ko.observable(false);
	this.type = ko.observable(null).extend({
		validation: {
			validator: function (val, someOtherVal) {
				return someOtherVal || val;
			},
			params: self.role
		}
	});
	this.role = ko.observable(null).extend({
		validation: {
			validator: function (val, someOtherVal) {
				return someOtherVal == null && val != null || someOtherVal != null && val == null;
			},
			params: self.type
		}
	});
	this.newEmployeeDetails = ko.validatedObservable(getStubNewEmployee());

	this.addNewEmployee = function() {
		self.newEmployeeDetails(getStubNewEmployee());
		self.isAddingPosEmployee(true);
	};

	this.cancelAddingNewEmployee = function() {
		self.isAddingPosEmployee(false);
	};

	this.saveNewEmployee = function() {
		var reqUrl = "api/users/";
		var employeeType = self.newEmployeeDetails().type();
		var employeeEmail = self.newEmployeeDetails().email();
		var employeeRole = self.newEmployeeDetails().role();
		
		if (employeeType == PosEmployeeType.ADMIN) {
			reqUrl += "admin";
		} else if (employeeType == PosEmployeeType.CASHIER) {
			reqUrl += "cashier";
		} else {
			reqUrl += "lottery";
		}

		self.isSavingEmployeeOnRemote(true);
		oga.restClient.postResource(reqUrl, JSON.stringify({
			email: employeeEmail,
			pointOfSaleId: self.posId,
			role: employeeRole
		})).done(function(resp) {
			var newEmployee = new oga.model.PosEmployee({
				id: resp.id,
				isActive: false,
				typeId: (employeeType != null) ? employeeType.id : null,
				isEnabled: false,
				email: employeeEmail,
				role: employeeRole
			});

			self.posEmployees.unshift(newEmployee);
			self.isAddingPosEmployee(false);
		}).fail(function(resp) {
			if (resp.responseJSON.error == "UserAlreadyExistsException") {
				self.newEmployeeDetails().email.setError("User Already Exists");
				self.newEmployeeDetails().email.isModified(true);
			}
		}).always(function() {
			self.isSavingEmployeeOnRemote(false);
		});
	};

	this.deleteNotActiveUser = function(posEmployee) {
		var msg = "delete.user.confirm.msg";
		oga.popupManager.confirm(msg).done(function() {
			onDeleteActiveUserConfirm(posEmployee);
		});
	};

	function onDeleteActiveUserConfirm(posEmployee) {
		posEmployee.isModifiable(false);
		oga.restClient.deleteResource("api/users/" + posEmployee.id + "/delete").done(function() {
			self.onDeleteCallBack();
		}).fail(function(resp) {
			if (resp.responseJSON.error == "UserAlreadyActivatedException") {
				oga.popupManager.error("user.already.active", "warning");
			} else if (resp.responseJSON.error == "EntityNotFoundException") {
				oga.popupManager.error("not.found", "warning");
			}
		}).always(function() {
			posEmployee.isModifiable(true);
		});
	}

	this.toggleIsPosEmployeeActive = function(posEmployee) {
		var newEnabledValue = !posEmployee.isEnabled();
		posEmployee.isModifiable(false);
		oga.restClient.patchResource("api/users/" + posEmployee.id + "/enabled", JSON.stringify({
			isEnabled: newEnabledValue
		})).done(function() {
			posEmployee.isEnabled(newEnabledValue);
		}).fail(function(resp) {
			if (resp.responseJSON.error == "ApplicationException"
			&& resp.responseJSON.message == "Can't enable/disable self!") {
				oga.popupManager.error("cant.enable.disable.self", "warning");
			}
		}).always(function() {
			posEmployee.isModifiable(true);
		});
	};

	this.changeUserRole = function(newRole, user) {
		oga.restClient.patchResource("api/users/" + user.id + "/change-role", JSON.stringify({
			role: newRole
		})).done(function() {
			user.role(newRole);
		}).fail(function(resp) {
			if (resp.responseJSON.error == "UserRepresentativeHasPointsOfSaleException") {
				oga.popupManager.error("cant.change.type.of.user.rep.has.pos", "warning");
			}
		}).always(function() {
			user.isModifiable(true);
		});
	};

	this.togglePosEmployeeType = function(posEmployee) {
		var newEmployeeType = null;
		if (posEmployee.type() == PosEmployeeType.ADMIN) {
			newEmployeeType = PosEmployeeType.CASHIER;
		} else {
			newEmployeeType = PosEmployeeType.ADMIN;
		}

		posEmployee.isModifiable(false);
		oga.restClient.patchResource("api/users/" + posEmployee.id + "/change-type", JSON.stringify({
			typeId: newEmployeeType.id
		})).done(function() {
			posEmployee.type(newEmployeeType);
		}).fail(function(resp) {
			if (resp.responseJSON.error == "ApplicationException"
			&& resp.responseJSON.message == "Can't change type on self!") {
				oga.popupManager.error("cant.change.type.on.self", "warning");
			}
		}).always(function() {
			posEmployee.isModifiable(true);
		});
	};

	function getStubNewEmployee() {
		return {
			email: ko.observable(null).extend({
				required: true,
				email: true
			}),
			type: self.type,
			role: self.role
		};
	}
};
