namespace("oga.viewmodel");

oga.viewmodel.ReturnRequestOpenVM = function() {
	var self = this;
	var BARCODE_UNITS_FOR_PAGE = 10;
	var returnRequestId = null;
	var allBarcodeUnitsCheckedState = true;
	var isCheckingBarcodeUnit = false;
	var nameSpace = "returnRequestOpenVM";
	var barcodeScannerMannager = null;
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	this.returnRequest = ko.observable(null);
	this.isReturnNotFound = ko.observable(false);
	this.isReturnForbidden = ko.observable(false);
	this.isApprovingRequest = ko.observable(false);

	this.enteredBarcode = ko.observable("");

	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: BARCODE_UNITS_FOR_PAGE,
		orderBy: "barcodeNumber"
	});

	this.canApproveReturnRequest = ko.pureComputed(function() {
		var barcodeUnits = self.returnRequest().barcodeUnits;
		var allBarcodeUnitsAreChecked = true;

		for (var i = 0; i < barcodeUnits.length; i++) {
			var barcodeUnit = barcodeUnits[i];
			if (!barcodeUnit.isChecked()) {
				allBarcodeUnitsAreChecked = false;
				break;
			}
		}

		return allBarcodeUnitsAreChecked;
	});

	this.canEditReturnRequest = ko.pureComputed(function() {
		var returnRequest = self.returnRequest();
		var isUserFromLottoOrg = oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS;
		if (returnRequest === null) {
			return false;
		}

		var isReturnRequestApproved = self.returnRequest().status() == oga.nomenclature.RequestStatusType.APPROVED;

		return isUserFromLottoOrg && !isReturnRequestApproved;
	});

	//TODO move to outside paginating object
	this.barcodeUnitsForPage = ko.pureComputed(function() {
		var returnRequest = self.returnRequest();
		var pageQueryParams = self.pagination.queryParamsObject();
		if (returnRequest === null) {
			return [];
		}

		sortObjArByNumericProp(returnRequest.barcodeUnits, pageQueryParams.direction);

		var barcodesStartIndex = (pageQueryParams.page - 1) * pageQueryParams.pageSize;

		var barcodeUnitsForPage = [];

		for (var i = 0; i < pageQueryParams.pageSize; i++) {
			var barcodeUnit = returnRequest.barcodeUnits[barcodesStartIndex + i];
			if (barcodeUnit !== undefined) {
				barcodeUnitsForPage.push(barcodeUnit);
			} else {
				break;
			}
		}

		return barcodeUnitsForPage;
	});

	this.init = function(params) {
		returnRequestId = params.id;
		loadReturnRequest();

		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 12,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.checkBarcodeUnit
		});

		barcodeScannerMannager.start();
	};

	this.toggleBarcodeUnitsCheck = function() {
		self.returnRequest().barcodeUnits.forEach(function(barcodeUnit) {
			barcodeUnit.isChecked(allBarcodeUnitsCheckedState);
		});
		allBarcodeUnitsCheckedState = !allBarcodeUnitsCheckedState;
	};

	function loadReturnRequest() {
		oga.restClient.getResource("api/return-requests/" + returnRequestId).done(function(resp) {
			self.returnRequest(new oga.model.ReturnRequest(resp));
		}).fail(function(resp) {
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "EntityNotFoundException":
						self.isReturnNotFound(true);
						break;
					case "PointOfSaleMismatchException":
						self.isReturnForbidden(true);
						break;
					default:
						oga.logger("Unexpected redmption request exception");
				}
			}
		});
	}

	this.checkBarcodeUnit = function() {
		if (isCheckingBarcodeUnit) {
			return;
		}

		isCheckingBarcodeUnit = true;
		var barcode = self.enteredBarcode().trim();
		var allBarcodeUnits = self.returnRequest().barcodeUnits;

		var noSuchBarcodeUnit = true;
		for (var i = 0; i < allBarcodeUnits.length; i++) {
			var barcodeUnit = allBarcodeUnits[i];
			if (barcodeUnit.barcodeNumber == barcode) {
				barcodeUnit.isChecked(true);
				noSuchBarcodeUnit = false;
				break;
			}
		}

		var scanState = {
			barcode: barcode
		};

		scanState.barcodeCheckStatus = noSuchBarcodeUnit ? BarcodeCheckStatus.NOT_FOUND : BarcodeCheckStatus.CHECKED;
		self.lastCheckBarcodeDetails(scanState);

		isCheckingBarcodeUnit = false;
	};

	this.acceptReturnRequest = function() {
		self.isApprovingRequest(true);
		oga.restClient.postResource("api/return-requests/" + returnRequestId + "/approvement").done(function(resp) {
			self.returnRequest(new oga.model.ReturnRequest(resp));
		}).always(function() {
			self.isApprovingRequest(false);
		});
	};

	//TODO move logic to util object
	function sortObjArByNumericProp(objAr, direction) {
		objAr.sort(function(objA, objB) {
			var orderByProp = self.pagination.orderBy();
			if (objA[orderByProp] > objB[orderByProp]) {
				return 1;
			} else if (objB[orderByProp] > objA[orderByProp]) {
				return -1;
			} else {
				return 0;
			}
		});

		if (direction == "desc") {
			objAr.reverse();
		}

		return objAr;
	}

	this.dispose = function() {
		barcodeScannerMannager.dispose();
	};

};
