namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.GameWinningsVM = function() {
	var self = this;
	var GameStatus = oga.nomenclature.GameStatus;
	var subscriptions = [];

	this.isLoading = ko.observable(false);
	this.games = ko.observableArray([]);
	this.selectedGame = ko.observable(null);
	this.gameStatuses = ko.observableArray([GameStatus.ACTIVE, GameStatus.INACTIVE, GameStatus.WAITING]);
	this.selectedGameStatus = ko.observable(GameStatus.ACTIVE);
	this.isLoadingGames = ko.observable(false);
	this.isLoadingGameWinnings = ko.observable(false);

	this.gameWinnings = ko.observableArray();
	this.gameWinningsCount = ko.observable();
	this.init = function() {
		subscriptions.push(self.selectedGameStatus.subscribe(function() {
			self.gameWinningsCount(null);
			loadGames();
		}));
		subscriptions.push(self.selectedGame.subscribe(function(newVal) {
			self.gameWinnings([]);
			if (newVal !== undefined && newVal !== null) {
				loadGameWinnings();
			}
		}));
		loadGames();
	};

	function loadGameWinnings() {
		var selectedGameId = self.selectedGame().id;
		self.isLoadingGameWinnings(true);
		oga.restClient.getResource("api/games/" + selectedGameId + "/winnings").done(function(resp) {
			self.gameWinnings(ko.utils.arrayMap(resp, function(gameWinningDto) {
				return new oga.model.RemainingGameWinning(gameWinningDto);
			}));
			self.gameWinningsCount(resp.length);
		}).always(function() {
			self.isLoadingGameWinnings(false);
		});
	}

	function loadGames() {
		var statusId = self.selectedGameStatus().id;
		self.games([]);
		self.isLoadingGames(true);
		oga.restClient.getResource("api/games?form=light&statusId=" + statusId).done(function(resp) {
			self.games(ko.utils.arrayMap(resp, function(gameLightDto) {
				return new oga.model.GameLight(gameLightDto);
			}));
			if (resp.length == 0) {
				self.gameWinningsCount(0);
			}
		}).always(function() {
			self.isLoadingGames(false);
		});
	}

	this.dispose = function() {
		subscriptions.forEach(function(subscription) {
			subscription.dispose();
		});
	};

};
