namespace("oga.component");

oga.component.ScannerBoxComponent = function(params) {
	var self = this;

	this.isVisible = params.isVisible || true;
	this.title = params.title || i18next.t("enter.barcode");
	this.barcodeStorer = params.barcodeStorer;
	this.onAddCallback = params.onAddCallback;
	this.lastCheckBarcodeDetails = params.lastCheckBarcodeDetails;
	this.isLoading = params.isLoading !== undefined ? params.isLoading : false;
	this.buttonText = params.buttonText || i18next.t("add");


	this.canAddBarcode = ko.pureComputed(function () {
		var barcode = self.barcodeStorer().trim();

		return barcode !== "" && !isNaN(barcode); 
	});

	this.closeBarcodeCheckDetailsBox = function() {
		self.lastCheckBarcodeDetails({
			barcodeCheckStatus: oga.nomenclature.BarcodeCheckStatus.STANDBY
		});
	};
};
