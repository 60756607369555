namespace("oga.nomenclature");

oga.nomenclature.BlacklistReason = {
	1: {
		id: 1,
		transKey: "stolen"
	},
	2: {
		id: 2,
		transKey: "lost"
	},
	get STOLEN() {
		return this[1];
	},
	get LOST() {
		return this[2];
	}
};
