namespace("oga.viewmodel");

oga.viewmodel.pos.TransfersVM = function() {
	var self = this;
	var PAGE_SIZE = 15;
	var subscriptions = [];

	this.totalTransfersCount = ko.observable(0);
	this.transfersForPage = ko.observableArray([]);
	this.transfersForPageAll = ko.observableArray([]);
	this.isLoading = ko.observable(false);
	this.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE,
		direction: "desc"
	});
	this.posEmployees = ko.observableArray([]);


	this.userOptions = ko.pureComputed(function() {
		var users = self.posEmployees();
		return [{
			fullName: i18next.t("all"),
			id: null
		}].concat(users);
	});
	this.selectedUser = ko.observable(undefined);
	this.isPosAdmin = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN;
	});
	this.init = function() {
		self.loadUsers();
		subscriptions.push(self.selectedUser.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadTransfers();
		}).extend({
			deferred: true
		}));

	};

	this.openTransfer = function(transfer) {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "transfer-open/" + transfer.id);
	};

	function loadTransfers() {
		self.isLoading(true);
		oga.restClient.getResource("api/transfers", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			userId: self.selectedUser()
		}).done(function(resp) {
			self.totalTransfersCount(resp.count);
			self.transfersForPageAll(ko.utils.arrayMap(resp.items, function(transferDto) {
				return new oga.model.Transfer(transferDto);
			}));
			self.transfersForPage(self.transfersForPageAll());
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.dispose = function() {
		subscriptions.forEach(function(subscription) {
			subscription.dispose();
		});
	};

	this.loadUsers = function() {
		self.posEmployees([]);
		oga.restClient.getResource("api/points-of-sale/current/users").done(function(resp) {
			self.posEmployees(ko.utils.arrayMap(resp.items, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
		});
	};
};
