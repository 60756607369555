namespace("oga.binding");

oga.binding.LoaderBinding = {
	update : function(element, valueAccessor) {
		var value = valueAccessor();
		var valueUnwrapped = ko.unwrap(value);
		var $element = $(element);
		if (valueUnwrapped == true) {
			$element.block({
				message : 
						"<h4><img src='./bower_components/bootstrap-ample-admin/plugins/images/busy.gif' /> Just a moment...</h4>",
				css : {
					border : "1px solid #fff"
				}
			});
		} else {
			$element.unblock();
		}
	}

};
