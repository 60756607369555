namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosTicketsVM = function() {
	var self = this;
	var subscriptions = [];
	var posId = null;
	this.games = ko.observableArray([]);

	this.gameOptions = ko.pureComputed(function() {
		var games = self.games();
		return [{
			name: i18next.t("all"),
			id: null
		}].concat(games);
	});

	this.selectedStatisticsGame = ko.observable(self.gameOptions()[0]);
	this.selectedUsersGame = ko.observable(self.gameOptions()[0]);

	this.selectedDateRange = new oga.model.DateRange(moment().subtract(1, "weeks"), moment());
	this.gameStatistics = ko.observable(null);
	this.posUsers = ko.observableArray([]);
	this.totalUsersTickets = ko.pureComputed(function() {
		var posUsers = self.posUsers();
		if (posUsers == null) {
			return 0;
		} else {
			var totalTickets = 0;
			posUsers.forEach(function(posUser) {
				totalTickets += parseInt(posUser.ticketsCount);
			});

			return totalTickets;
		}
	});


	this.isLoadingGameStatistics = ko.observable(false);
	this.isLoadingUserStatistics = ko.observable(false);

	this.gameStatsQueryParams = ko.pureComputed(function() {
		var gameStatsQueryParams = {};
		var selectedStatisticsGame = self.selectedStatisticsGame();
		var fromDate = self.selectedDateRange.fromDate().format(oga.settings.momentRequestFormat);
		var toDate = self.selectedDateRange.toDate().format(oga.settings.momentRequestFormat);

		if (selectedStatisticsGame.id !== null) {
			gameStatsQueryParams.gameId = selectedStatisticsGame.id;
		}
		gameStatsQueryParams.fromDate = fromDate;
		gameStatsQueryParams.toDate = toDate;

		return gameStatsQueryParams;
	});

	this.init = function(params) {
		posId = params.id;
		loadGames();

		subscriptions.push(self.gameStatsQueryParams.subscribe(function(gameQueryParams) {
			loadGameStatistics(gameQueryParams);
		}));

		subscriptions.push(self.selectedUsersGame.subscribe(function(selectedUsersGame) {
			var queryParams = {};

			if (selectedUsersGame.id !== null) {
				queryParams.gameId = selectedUsersGame.id;
			}

			loadGamesForUsers(queryParams);
		}));

	};

	function loadGames() {
		oga.restClient.getResource("api/games?form=light").done(function(resp) {
			self.games(ko.utils.arrayMap(resp, function(gameLightDto) {
				return new oga.model.GameLight(gameLightDto);
			}));
		});
	}

	function loadGamesForUsers(gameQueryParams) {
		self.isLoadingUserStatistics(true);
		oga.restClient.getResource("api/points-of-sale/" + posId + "/user-ticket-availabilities", gameQueryParams).done(function(resp) {
			self.posUsers(ko.utils.arrayMap(resp, function(posEmployeeDto) {
				return new oga.model.PosEmployeeLight(posEmployeeDto);
			}));
		}).always(function() {
			self.isLoadingUserStatistics(false);
		});
	}

	function loadGameStatistics(gameQueryParams) {
		self.isLoadingGameStatistics(true);
		oga.restClient.getResource("api/points-of-sale/" + posId + "/ticket-statistics", gameQueryParams).done(function(resp) {
			self.gameStatistics(new oga.model.GameStatistics(resp));
		}).always(function() {
			self.isLoadingGameStatistics(false);
		});
	}

	this.dispose = function() {
		subscriptions.forEach(function(subscription) {
			subscription.dispose();
		});
	};

};
