namespace("oga.viewmodel.pos");
oga.viewmodel.CheckVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;

	self.barcodeInput = ko.observable().extend({
		required: true,
		pattern: {
			message: i18next.t("must.be.at.least.eight.digits"),
			params: "^\\d{8,}$"
		}
	});
	self.barcodeToCheck = ko.observable().extend({notify: "always"});
	self.isLoading = ko.observable(false);
	self.checkedTicket = ko.observable();
	self.barcodeHasError = ko.observable(false);
	self.i18errorCode = ko.observable("");

	
	this.init = function() {
		self.barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			minBarcodeLength: 12,
			nameSpace: "CheckVM",
			scannerInputStorer: self.barcodeToCheck
		});
		self.barcodeScannerMannager.start();

		subscriptions.push(self.barcodeInput.subscribe(function() {
			self.checkedTicket(null);
			self.barcodeHasError(false);
		}));
		subscriptions.push(self.barcodeToCheck.subscribe(function(newValue) {
			self.barcodeInput(newValue);
			checkBarcode();
		}));
	};

	function checkBarcode() {
		if (!self.barcodeInput.isValid()) {
			return;
		}
		self.isLoading(true);
		var url = pastel.util.StringHelper.format("api/tickets/{0}/winning", self.barcodeInput());
		restClient.getResource(url).done(function(resp) {
			self.checkedTicket(new oga.model.CheckedTicket(resp));
		}).handleErrors({
			"InvalidTicketVerificationNumberException": function() {
				self.i18errorCode("barcode.not.valid");
			},
			"InvalidTicketVerificationNoWinException": function() {
				self.i18errorCode("barcode.no.win");
			},
			"TicketWinExceedsPosRedemptionLimitException": function() {
				self.i18errorCode("barcode.win.exceeds.limit");
			}
		}).fail(function() {
			self.barcodeHasError(true);
		}).always(function() {
			self.isLoading(false);
		});
	}

	self.setBarcodeToCheck = function() {
		self.barcodeToCheck(self.barcodeInput());
	};

	//	TODO -> pop up for successful/unsuccessful pay
	self.payTicket = function() {
		var url = pastel.util.StringHelper.format("api/tickets/{0}/payment", self.barcodeInput());
		restClient.postResource(url).done(function() {
			
		}).fail(function() {
			oga.logger("ticket payment failed");
		}).always(function() {
			self.barcodeInput("");
			self.barcodeInput.isModified(false);
		});
	};

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
		self.barcodeScannerMannager.dispose();
	};
};
