namespace("oga.viewmodel.pos");

oga.viewmodel.pos.TransferCreateVM = function() {
	var self = this;
	var PAGE_SIZE = 15;
	var barcodeScannerMannager = null;
	var nameSpace = "TransferCreateVM";
	var popupNamespace = "TransferCreatePopup";
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	this.isLoadingEmployees = ko.observable(false);
	this.isCheckingBarcode = ko.observable(false);
	this.isTransferConfirmationFormOpen = ko.observable(false);
	this.isCreatingTransfer = ko.observable(false);

	this.selectedRecipient = ko.observable(null);
	this.recipientPassword = ko.observable("");
	this.posEmployees = ko.observableArray([]);
	this.enteredBarcode = ko.observable("");
	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});

	self.pagination = new oga.model.PaginatedArrayModel({
		pageSize: PAGE_SIZE
	});

	this.transferTicketsCount = ko.pureComputed(function() {
		var barcodeUnitsForTransfer = self.pagination.getItems();
		var transferTicketsCount = 0;
		
		barcodeUnitsForTransfer.forEach(function(barcodeUnit) {
			transferTicketsCount += parseInt(barcodeUnit.ticketsCount);
		});
		
		return transferTicketsCount;
	});

	this.transferEligibleEmployees = ko.pureComputed(function() {
		var posEmployees = self.posEmployees();
		var eligibleForTransfer = [];
		posEmployees.forEach(function(posEmployee) {
			if (posEmployee.isEnabled() && posEmployee.isActive && posEmployee.email !== oga.authenticatedUser().email) {
				eligibleForTransfer.push(posEmployee);
			}
		});

		return eligibleForTransfer;
	});

	this.canSendBarcodeUnits = ko.pureComputed(function() {
		var barcodeUnitsForTransfer = self.pagination.getItems();

		return barcodeUnitsForTransfer.length > 0 && self.selectedRecipient() != null;
	});

	this.init = function() {
		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 12,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.addBarcodeUnit
		});

		initKeyBindings();
		barcodeScannerMannager.start();

		loadPosEmployees();
	};

	this.addBarcodeUnit = function() {
		if (self.isCheckingBarcode()) {
			return;
		}
		var barcode = self.enteredBarcode();
		var currentBarcodeUnits = self.pagination.getItems();
		var barcodeCheckDetails = {
			barcode: barcode,
			barcodeCheckStatus: BarcodeCheckStatus.STANDBY
		};

		for (var i = 0; i < currentBarcodeUnits.length; i++) {
			var currentBarcodeUnit = currentBarcodeUnits[i];
			if (currentBarcodeUnit.barcodeNumber == barcode) {
				barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.DUPLICATE;
				self.lastCheckBarcodeDetails(barcodeCheckDetails);
				return;
			}
		}

		self.isCheckingBarcode(true);
		oga.restClient.getResource("api/barcode-units/" + barcode + "/transferable").done(function(resp) {
			self.pagination.addItem(new oga.model.CheckableBarcodeUnit(resp));
			barcodeCheckDetails.barcodeCheckStatus = BarcodeCheckStatus.ADDED;
		}).fail(function(resp) {
			var errorStatus = BarcodeCheckStatus.ERROR;
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "BarcodeUnitNotFoundException":
						errorStatus = BarcodeCheckStatus.NOT_FOUND;
						break;
					case "InvalidBarcodeUnitException":
						errorStatus = BarcodeCheckStatus.ERROR;
						break;
					default:
						errorStatus = BarcodeCheckStatus.ERROR;
				}
			}
			barcodeCheckDetails.barcodeCheckStatus = errorStatus;
		}).always(function() {
			self.lastCheckBarcodeDetails(barcodeCheckDetails);
			self.isCheckingBarcode(false);
		});
	};

	this.removeBarcodeUnit = function(barcodeUnit) {
		self.pagination.removeItem(barcodeUnit);
	};

	this.closeTransferForm = function() {
		self.recipientPassword("");
		self.isTransferConfirmationFormOpen(false);
	};

	this.acceptTransfer = function() {
		if (self.isCreatingTransfer()) {
			return;
		}

		var barcodeNumbers = [];
		self.pagination.getItems().forEach(function(barcodeUnit) {
			barcodeNumbers.push(barcodeUnit.barcodeNumber);
		});

		var transferData = {
			barcodeNumbers: barcodeNumbers,
			recipientUserId: self.selectedRecipient().id,
			recipientUserPassword: self.recipientPassword()
		};
		self.isCreatingTransfer(false);
		oga.restClient.postResource("api/transfers", JSON.stringify(transferData)).done(function() {
			oga.router.setHash(oga.authenticatedUser().hashPrefix + "transfers");
		}).handleErrors({
			TransferCreationException: function() {
				oga.popupManager.error("transfer.could.not.be.completed");
			}
		}).always(function () {
			self.isCreatingTransfer(false);
		});

	};

	function loadPosEmployees() {
		self.isLoadingEmployees(true);
		oga.restClient.getResource("api/points-of-sale/current/users").done(function(resp) {
			self.posEmployees(ko.utils.arrayMap(resp.items, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
		}).always(function() {
			self.isLoadingEmployees(false);
		});
	}

	function initKeyBindings() {
		$(document).on("keyup." + popupNamespace, function(event) {
			var codes = pastel.util.KeyCodes;
			var keyCode = event.keyCode;

			if (keyCode === codes.ENTER) {
				if (self.recipientPassword() && self.recipientPassword().trim().length > 0) {
					self.acceptTransfer();
				}
			} else if (keyCode === codes.ESCAPE) {
				self.isTransferConfirmationFormOpen(false);
			}
		});
	}

	this.dispose = function() {
		$(document).off("keyup." + popupNamespace);
		barcodeScannerMannager.dispose();
	};
};
