namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.BlacklistVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;
	var BlacklistReason = oga.nomenclature.BlacklistReason;
	var PAGE_SIZE = 15;

	this.isBlacklisting = ko.observable(false);
	this.isSavingBlacklist = ko.observable(false);
	var toggleAllChecksVal = true;

	this.reason = ko.observable(BlacklistReason.STOLEN).extend({
	});

	this.barcodeInput = ko.observable().extend({
		required: true,
		pattern: {
			message: i18next.t("must.be.at.least.eight.digits"),
			params: "^\\d{8,}$"
		}
	});

	this.barcodeInputSearch = ko.observable().extend({notify: "always"});
	self.barcodeUnitTypeOptions = oga.nomenclature.BarcodeUnitType;
	self.selectedBarcodeUnitType = ko.observable();
	self.unitForBlacklist = ko.validatedObservable(getStubBlacklist());
	self.blacklistedUnits = ko.observableArray([]);
	self.barcodeUnitsCount = ko.observable();
	
	self.isLoading = ko.observable(false);
	self.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE
	});

	self.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	this.init = function() {
		subscriptions.push(self.barcodeInputSearch.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.selectedBarcodeUnitType.subscribe(function() {
			self.pagination.page(1);
		}));

		subscriptions.push(ko.computed(function() {
			self.loadBlacklistItems();
		}).extend({
			deferred: true
		}));
	};

	this.loadBlacklistItems = function() {
		self.isLoading(true);
		restClient.getResource("/api/barcode-units/blacklisted", {
			page: self.pagination.page(),
			pageSize: PAGE_SIZE,
			type: self.selectedBarcodeUnitType(),
			barcode: self.barcodeInputSearch()
		}).done(function(resp) {
			self.barcodeUnitsCount(resp.totalCount);
			self.blacklistedUnits(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.BlacklistedBarcodeUnit(dto);
			}));
		}).always(function() {
			self.isLoading(false);
		});
	};

	this.search = function() {
		if (self.barcodeInputSearch.isValid()) {
			self.loadBlacklistItems();
		}
	};

	this.blacklist = function() {
		self.unitForBlacklist(getStubBlacklist());
		self.isBlacklisting(true);
	};

	this.unblacklist = function(barcodeItem) {
		var msg = "blacklist.restore.current.msg";
		oga.popupManager.confirm(msg).done(function() {
			doUnblacklist(barcodeItem);
		});
	};

	function doUnblacklist(barcodeItem) {
		var dataToSend = JSON.stringify({
			barcodeNumbers: [barcodeItem.barcode]
		});
		self.isLoading(true);

		oga.restClient.patchResource("api/barcode-units/blacklist/off", dataToSend).done(function() {
			self.loadBlacklistItems();
		}).fail(function(resp) {
			if (resp.responseJSON.error == "InvalidBarcodeUnitException") {
				var msg = pastel.util.StringHelper.format(i18next.t("barcode.unit.could.not.change.status"), barcodeItem.barcode);
				oga.popupManager.error(msg, "warning");
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.unblacklistSelected = function() {
		var msg = "blacklist.restore.selected.msg";
		oga.popupManager.confirm(msg).done(function() {
			doUnblacklistSelected();
		});
	};

	function doUnblacklistSelected() {
		var selectedBarcodes = [];
		var barcodeUnits = self.blacklistedUnits();
		var barcodeType = null;
		for (var i = 0; i < barcodeUnits.length; i++) {
			var type = barcodeUnits[i].type();
			if (barcodeType == null) {
				barcodeType = type;
			} else if (barcodeType != type) {
				oga.popupManager.error("cannot.unblacklist.different.types", "warning");
				return;
			}
			var barcodeUnit = barcodeUnits[i];
			if (barcodeUnit.isChecked()) {
				selectedBarcodes.push(barcodeUnit.barcode);
			}
		}
		var dataToSend = JSON.stringify({
			barcodeNumbers: selectedBarcodes
		});
		self.isLoading(true);
		oga.restClient.patchResource("api/barcode-units/blacklist/off", dataToSend).done(function() {
			self.pagination.page(1);
			toggleAllChecksVal = true;
			self.loadBlacklistItems();
		}).fail(function(resp) {
			if (resp.responseJSON.error == "InvalidBarcodeUnitException") {
				var msg = pastel.util.StringHelper.format(i18next.t("barcode.unit.could.not.change.status"), selectedBarcodes[0]);
				oga.popupManager.error(msg, "warning");
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.saveBlacklisted = function() {
		if (self.isSavingBlacklist()) {
			return;
		}
		this.isSavingBlacklist(true);
		var msg = "blacklist.add.confirm.msg";
		oga.popupManager.confirm(msg).done(function() {
			doSaveBlacklisted();
		});

	};

	function doSaveBlacklisted() {
		var blacklist = self.unitForBlacklist();

		oga.restClient.patchResource("api/barcode-units/" + blacklist.barcodeInput() + "/blacklist/on", JSON.stringify({
			reasonId: blacklist.reason().id
		})).done(function() {
			self.isBlacklisting(false);
			self.loadBlacklistItems();
		}).fail(function(resp) {
			if (resp.responseJSON.error == "InvalidBarcodeUnitException") {
				var msg = pastel.util.StringHelper.format(i18next.t("barcode.unit.could.not.change.status"), blacklist.barcodeInput());
				const respMsg = resp.responseJSON.message;
				if (respMsg.includes("actual: " + oga.nomenclature.TicketStatusType.BLACKLISTED.id)) {
					msg = pastel.util.StringHelper.format(i18next.t("barcode.unit.already.blacklisted"), blacklist.barcodeInput());
				}
				oga.popupManager.error(msg, "warning");
			} else if (resp.responseJSON.error == "InvalidPacketStatusException") {
				var msgs = pastel.util.StringHelper.format(i18next.t("barcode.unit.parent.not.open"), blacklist.barcodeInput());
				oga.popupManager.error(msgs, "warning");
			}
		}).always(function() {
			self.isLoading(false);
			self.isSavingBlacklist(false);
		});
	}

	function getStubBlacklist() {
		return {
			barcodeInput: self.barcodeInput,
			reason: self.reason
		};
	}

	this.toggleAllBarcodeUnits = function() {
		self.blacklistedUnits().forEach(function(barcodeUnit) {
			barcodeUnit.isChecked(toggleAllChecksVal);
		});

		toggleAllChecksVal = !toggleAllChecksVal;
	};

	this.atLeastOneChecked = ko.pureComputed(function() {
		var barcodeUnits = self.blacklistedUnits();
		for (var i = 0; i < barcodeUnits.length; i++) {
			var barcodeUnit = barcodeUnits[i];
			if (barcodeUnit.isChecked()) {
				return true;
			}
		}

		return false;
	});
	
	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
