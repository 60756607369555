namespace("oga.nomenclature");

oga.nomenclature.OrderStatusType = {
	1: {
		id: 1,
		dbName: "", //WAITING
		dropdownName: "", //Waiting
		transKey: "waiting",
		cssClass: "label-warning"
	},
	2: {
		id: 2,
		dbName: "", //PROCESSED
		dropdownName: "", //Processed
		transKey: "processed",
		cssClass: "label-info"
	},
	3: {
		id: 3,
		dbName: "", //ACCEPTED
		dropdownName: "", //Accepted
		transKey: "accepted",
		cssClass: "label-success"
	},
	4: {
		id: 4,
		dbName: "", //CANCELED
		dropdownName: "", //Canceled
		transKey: "canceled",
		cssClass: "label-default-dark"
	},
	get WAITING() {
		return this[1];
	},
	get PROCESSED() {
		return this[2];
	},
	get ACCEPTED() {
		return this[3];
	},
	get CANCELED() {
		return this[4];
	},
	get ALL() {
		return [this[1], this[2], this[3], this[4]];
	}
};
