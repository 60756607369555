namespace("oga.util.i18n");

oga.util.i18translate = function (valueAccessor) {
	var value = ko.unwrap(valueAccessor());
	if (Array.isArray(value)) {
		var translated = i18next.t(value[0]);
		if (typeof value[1] == "function") {
			translated = value[1](translated, value.slice(2));
		}
		return translated;
	} else {
		return i18next.t(value);
	}
};

oga.util.I18nBinding = {
	init: function(element, valueAccessor) {
		var onLanguageChange = function() {
			pastel.knockout.binding.I18nBinding.update(element, valueAccessor);
		};
		i18next.on("languageChanged", onLanguageChange);
		ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
			i18next.off("languageChanged", onLanguageChange);
		});
	},
		
	update: function(element, valueAccessor) {
		var translation = oga.util.i18translate(valueAccessor);
		$(element).text(translation);
	}
};
oga.util.I18nPlaceholderBinding = {
	init: function(element, valueAccessor) {
		var onLanguageChange = function() {
			pastel.knockout.binding.I18nPlaceholderBinding.update(element, valueAccessor);
		};
		i18next.on("languageChanged", onLanguageChange);
		ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
			i18next.off("languageChanged", onLanguageChange);
		});
	},
		
	update: function(element, valueAccessor) {
		var translation = oga.util.i18translate(valueAccessor);
		$(element).attr("placeholder", translation);
	}
};

window.ou = {};
ou.upper = function (str) {
	return str.toUpperCase();
};

ou.format = function (str, args) {
	return str.replace(/{(\d+)}/g, function(match, number) { 
		return args[number] !== undefined ? args[number] : match;
	});
};

oga.util.i18n.getLanguage = function() {
	var lang = $.cookie("lang");
	lang = lang || "en";
	return lang;
};

oga.util.i18n.changeLanguage = function (lang) {
	$.cookie("lang", lang);    
	window.location.reload();
};

ou.language = ko.observable(null);
oga.util.setupI18n = function() {
	var deferred = $.Deferred();
	var initialLang = oga.util.i18n.getLanguage();
	i18next.use(i18nextXHRBackend)
		.init({
			whitelist: ["pt", "en"],
			lng: initialLang,
			//fallbackLng: "en",
			load: "languageOnly",
			keySeparator: "/",
			parseMissingKeyHandler: function(key) {
				return key;
			},
			backend: {
				loadPath: "i18n/{{lng}}.json?_=" + new Date().getTime(),
				allowMultiLoading: false
			}
		}, function() {
			ou.language(initialLang);
			deferred.resolve();
		});

	i18next.on("languageChanged", function(lng) {
		ou.language(lng);
		//TODO what locales are supported by moment?
		//moment.locale(lng);
	});


	wrapi18();

	ko.bindingHandlers.i18n = oga.util.I18nBinding;
	ko.bindingHandlers.i18nPlaceholder = oga.util.I18nPlaceholderBinding;

	function wrapi18() {
		var orig = window.i18next;
		window.i18next = {
			t: function(key) {
				var result = orig.t(key);
				//result = "!!!" + result;
				return result;
			},
			on: function(arg1, arg2) {
				return orig.on(arg1, arg2);
			},
			off: function(arg1, arg2) {
				return orig.off(arg1, arg2);
			}
		};
	}

	return deferred;
};
