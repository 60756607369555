namespace("oga.nomenclature");

oga.nomenclature.TransactionType = {
	1: {
		id: 1,
		dbName: "", //DEPOSIT
		cssClass: "label-success",
		dropdownName: "", //Deposit
		transKey: "deposit"
	},	
	2: {
		id: 2,
		dbName: "", //WITHDRAW,
		cssClass: "label-danger",
		dropdownName: "", //Withdraw
		transKey: "withdraw"
	},
	3: {
		id: 3,
		dbName: "", //ORDER,
		cssClass: "label-primary",
		dropdownName: "", //Order
		transKey: "order"
	},
	4: {
		id: 4,
		dbName: "", //REDEMPTION,
		cssClass: "label-info",
		dropdownName: "", //Redemption
		transKey: "redemption"
	},
	5: {
		id: 5,
		dbName: "", //RETURN,
		cssClass: "label-warning",
		dropdownName: "", //Return
		transKey: "return"
	},
	6: {
		id: 6,
		dbName: "", //CANCEL ORDER,
		cssClass: "label-primary",
		dropdownName: "", //Cancel order
		transKey: "order"
	},
	get DEPOSIT() {
		return this[1];
	},
	get WITHDRAW() {
		return this[2];
	},
	get ORDER() {
		return this[3];
	},
	get REDEMPTION() {
		return this[4];
	},
	get RETURN() {
		return this[5];
	},
	get CANCELED_ORDER() {
		return this[6];
	},
	get ALL() {
		return [this[1], this[2], this[3], this[4], this[5], this[6]];
	}
};


ou.language.subscribe(function() {
	var TransactionType = oga.nomenclature.TransactionType;
	for (var i = 0; i < TransactionType.ALL.length; i++) {
		var tt = TransactionType.ALL[i];
		tt.dropdownName = i18next.t(tt.transKey);
		tt.dbName = i18next.t(tt.transKey).toUpperCase();
	}
});
