namespace("oga.model");

oga.model.GameSettingsProfile = function(dto, defaultTicketPrice) {
	var self = this;

	this.id = dto.id;
	this.singleTicketPrice = defaultTicketPrice;
	this.nameSimple = dto.name;
	this.name = ko.observable(dto.name).extend({
		required: true,
		minLength: 4,
		maxLength: 64
	});
	this.minimumOrderQuantity = ko.observable(dto.minimumOrderQuantity).extend({
		required: true,
		digit: true,
		min: 1
	});
	this.orderBatchSize = ko.observable(dto.orderBatchSize).extend({
		required: true,
		digit: true,
		min: 1
	});
	this.isOrderingEnabled = ko.observable(dto.isOrderingEnabled).extend({
		required: true
	});

	this.isDefault = ko.observable(dto.isDefault).extend({
		required: true
	});
	this.ticketPrice = ko.observable(dto.ticketPrice).extend({
		required: true,
		number: true
	});
	this.isReturnEnabled = ko.observable(dto.isReturnEnabled).extend({
		required: true
	});
	this.pointsOfSaleCount = ko.observable(dto.pointsOfSaleCount).extend({
		required: true,
		digit: true
	});
	this.winRedemptionLimit = ko.observable(dto.winRedemptionLimit).extend({
		required: true,
		number: true
	});

	this.discountPercent = ko.pureComputed(function() {
		var ticketPrice = self.ticketPrice();
		if (isNaN(ticketPrice) || (typeof(ticketPrice) == "string" && ticketPrice.trim() == "")) {
			return "";
		}
		var discountPercent = (1 - (parseFloat(ticketPrice) / parseFloat(defaultTicketPrice))) * 100;
		var formatedDiscountPercent = discountPercent.toFixed(2);
		return formatedDiscountPercent + "%";
	});

	this.priceAndDiscountConcat = ko.pureComputed(function() {
		var discountPercent = self.discountPercent();
		var ticketPrice = self.ticketPrice();
		if (discountPercent == "") {
			return discountPercent;
		}
		return oga.settings.NAIRA_SYMBOL + ticketPrice + " - " + discountPercent;
	});

	this.toggleIsOrderingEnabled = function() {
		self.isOrderingEnabled(!self.isOrderingEnabled());
	};

	this.toggleIsReturnEnabled = function() {
		self.isReturnEnabled(!self.isReturnEnabled());
	};


	this.toDto = function() {
		var dto = self.toRequestDto();
		dto.id = self.id;
		return dto;
	};

	this.toRequestDto = function() {
		return {
			name: self.name(),
			minimumOrderQuantity: self.minimumOrderQuantity(),
			orderBatchSize: self.orderBatchSize(),
			isDefault: self.isDefault(),
			ticketPrice: self.ticketPrice(),
			isReturnEnabled: self.isReturnEnabled(),
			pointsOfSaleCount: self.pointsOfSaleCount(),
			winRedemptionLimit: self.winRedemptionLimit(),
			isOrderingEnabled: self.isOrderingEnabled()
		};
	};

	this.getClone = function() {
		return new oga.model.GameSettingsProfile(self.toDto(), defaultTicketPrice);
	};

};
