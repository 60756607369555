namespace("oga.util");

oga.util.BarcodeScannerManager = function(optConfig) {
	optConfig = optConfig || {};
	var defaultValueStorer = ko.observable();
	var handleScannerInputTimeout = null;

	var DEFAULT_CONFIG = {
		maxDelayBetweenKeyPress: 50,
		minBarcodeLength: 5,
		nameSpace: "default",
		scannerInputStorer: defaultValueStorer,
		eventQueue: null,
		eventName: null,
		onEnterCallback: null,
		onScanCallback: null
	};

	var config = $.extend({}, DEFAULT_CONFIG, optConfig);

	this.setMaxDelayBetweenKeyPress = function(delay) {
		config.maxDelayBetweenKeyPress = delay;
	};

	this.setMinBarcodeLength = function(minLen) {
		config.minBarcodeLength = minLen;
	};


	this.getBarcodeValue = function() {
		return config.scannerInputStorer();
	};

	this.start = function() {
		function setScannerInputIfValid() {
			if (scannerInput.length >= config.minBarcodeLength) {
				config.scannerInputStorer(scannerInput);
				if ($.isFunction(config.onScanCallback)) {
					config.onScanCallback(scannerInput);
				}
				if (config.eventQueue !== null && config.eventName !== null) {
					config.eventQueue.publish(config.eventName, {
						scannedBarcode: scannerInput
					});
				}
			}

			scannerInput = "";
		}

		var scannerInput = "";
		$(document).on("keypress." + config.nameSpace, function(event) {
			clearTimeout(handleScannerInputTimeout);
			var eventKeyIsNumber = !isNaN(event.key) && (typeof(event.key) == "string" && event.key.trim() !== "");
			var eventKeyIsEnter = typeof(event.key) == "string" && event.key == "Enter";

			if (eventKeyIsNumber) {
				scannerInput = scannerInput += event.key;
				handleScannerInputTimeout = setTimeout(setScannerInputIfValid, config.maxDelayBetweenKeyPress);
			} else if (eventKeyIsEnter) {
				setScannerInputIfValid();
				if (typeof(config.onEnterCallback) == "function" && scannerInput.length >= config.minBarcodeLength) {
					config.onEnterCallback(scannerInput);
				}
			} else {
				scannerInput = "";
			}

		});
	};

	this.dispose = function() {
		$(document).off("keypress." + config.nameSpace);
		clearTimeout(handleScannerInputTimeout);
	};


};
