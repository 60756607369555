namespace("oga.nomenclature");

oga.nomenclature.PacketStateType = {
	1: {
		id: 1,
		dbName: "IN_STOCK",
		transKey: "in.stock"
	},
	2: {
		id: 2,
		dbName: "TRANSFERRING",
		transKey: "transferring"
	},
	3: {
		id: 3,
		dbName: "OPENED",
		transKey: "opened"
	},
	4: {
		id: 4,
		dbName: "BLACKLISTED",
		transKey: "blacklisted"
	}
};
