namespace("oga.binding");

oga.binding.NameIdInputBinding = {
	init : function(element, valueAccessor) {
		var $element = $(element);
		
		$element.on("keyup", function(event) {
			var resultValue = valueAccessor();
			var input = $(this);

			var isEmpty = input.val().length == 0;
			if (isEmpty) {
				resultValue(undefined);
			}

			var isBackspace = event.keyCode == pastel.util.KeyCodes.BACKSPACE;
			if (isBackspace) {
				return;
			}

			var isNumeric = !isNaN(input.val());
			var hasEnoughLength = input.val().length >= 3;
			if (isNumeric || hasEnoughLength) {
				resultValue(input.val());
			}
		});
	}
};
