namespace("oga.model");

oga.model.PosPaidWinning = function(dto) {
	this.userId = dto.userId;
	this.fullName = dto.fullName;
	this.ticketsCount = dto.ticketsCount;
	this.winningsAmount = dto.winningsAmount;
};

oga.model.PosPaidWinningUserGame = function(dto) {
	this.gameName = dto.gameName;
	this.ticketsCount = dto.ticketsCount;
	this.winningsAmount = dto.winningsAmount;
};

oga.model.PosPaidWinningUserTicket = function(dto) {
	if (dto.dateTime !== undefined) {
		this.dateTime = moment.fromJacksonDateTimeArray(dto.dateTime);
	}
	this.barcode = dto.barcode;
	if (dto.gameLot !== undefined) {
		this.gameLot = new oga.model.GameLot(dto.gameLot);
	}

	this.winningAmount = dto.winningAmount;
};
