namespace("oga.model");

oga.model.Transaction = function(dto) {
	this.id = dto.id;
	this.requestId = dto.requestId;
	if (dto.ticketsCount != undefined) {
		this.ticketsCount = ko.observable(dto.ticketsCount);
	} else {
		this.ticketsCount = ko.observable(-1);
	}
	this.orderStatus = ko.observable();
	if (dto.orderStatusId !== undefined) {
		this.orderStatus = ko.observable(oga.nomenclature.OrderStatusType[dto.orderStatusId]);
	}
	this.pointOfSale = new oga.model.PointOfSaleLight(dto.pointOfSale);
	this.timestamp = moment.fromJacksonDateTimeArray(dto.timestamp);
	if (dto.typeId !== undefined) {
		this.type = ko.observable(oga.nomenclature.TransactionType[dto.typeId]);
	}
	this.amount = dto.amount;
};
