oga.util.SimplePopupManager = function() {
	var self = this;
	var popupQue = [];

	var POPUP_TYPES = {
		ALERT: "alert",
		CONFIRM: "confirm",
		prompt: "prompt"
	};

	var DEFAULT_SETTINGS = {
		okButtonText: "ok",
		cancelButtonText: "cancel",
		type: "alert"
	};

	this.isVisible = ko.observable(false).extend({
		rateLimit: {
			timeout: 200,
			method: "notifyWhenChangesStop"
		},
		notify: "always"
	});

	this.openedPopup = ko.observable(null);


	this.addPopupToQue = function(settings) {
		var deferred = $.Deferred();

		settings = $.extend({}, DEFAULT_SETTINGS, settings);
		var popupContext = new PopupContext(settings, deferred);
		if (isPopupInQueAlready(popupContext)) {
			return;
		}
		popupQue.push(popupContext);

		deferred.always(function() {
			removePopupContext(popupContext);
			openNextPopupIfNoneOpen();
		});

		openNextPopupIfNoneOpen();

		return deferred;
	};

	function isPopupInQueAlready(newContext) {
		for (var i = 0; i < popupQue.length; i++) {
			var contextInQue = popupQue[i];
			if (JSON.stringify(contextInQue) == JSON.stringify(newContext)) {
				return true;
			}
		}
		return false;
	}

	function removePopupContext(popupContext) {
		var popupContextIndexInQue = popupQue.indexOf(popupContext);

		if (popupContextIndexInQue > -1) {
			popupQue.splice(popupContextIndexInQue, 1);
		}

		if (self.openedPopup() == popupContext) {
			self.openedPopup(null);
			self.isVisible(false);
		}
	}

	function openNextPopupIfNoneOpen() {
		if (self.openedPopup() !== null) {
			return;
		}

		var popupContext = popupQue[0];

		if (popupContext instanceof PopupContext) {
			self.openedPopup(popupContext);
			self.isVisible(true);
		} else {
			self.openedPopup(null);
			self.isVisible(false);
		}

	}

	function PopupContext(settings, deferred) {
		var self = this;
		this.settings = settings;

		this.ok = function() {
			if (settings.type == POPUP_TYPES.prompt) {
				deferred.resolve(self.promptInput());
			} else {
				deferred.resolve();
			}
		};

		this.cancel = function() {
			deferred.reject();
		};

		this.promptInput = ko.observable();
	}

};
