namespace("oga.model");

oga.model.PublicRemainingGamesWinnings = function(dto) {

	this.gameName = dto.gameName !== undefined ? dto.gameName : null;

	if (dto.remainingWinnings !== undefined) {
		this.remainingWinnings = ko.utils.arrayMap(dto.remainingWinnings, function(winning) {
			return new oga.model.RemainingGameWinning(winning);
		});

		this.highestWinning = Math.max(...ko.utils.arrayMap(this.remainingWinnings, function(winning) {
			return winning.value;
		}));
	}
};
