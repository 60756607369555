namespace("oga.model");

oga.model.ReportRegulator = function(dto) {
	var date = new Date(moment.fromJacksonDateTimeArray(dto.date));
	this.dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1);
	this.dateTo = new Date(date.getFullYear(), date.getMonth(), 0);
	this.gameName = dto.gameName;
	this.ticketsSold = dto.ticketsSold;
	this.prizesPaid = dto.prizesPaid;
};


