namespace("oga.security");

oga.security.SecurityRouterListerner = function() {
	this.onRouteChange = function(route) {

		if (route.url.split("/")[0] == "account") {
			return;
		}

		var currentUser = oga.authenticatedUser();
		if (currentUser == null) {
			window.location = "/login.html";
		}

		var prefixWithoutHashtagOrSlash = currentUser.hashPrefix.substring(1, currentUser.hashPrefix.length - 1);

		if (route.url.split("/")[0] != prefixWithoutHashtagOrSlash) {
			goToPageWithNoPermissionsRequired();
			return;
		}

		if (route.access !== undefined) {
			var access = route.access;
			if (!Array.isArray(access)) {
				access = [access];
			}
			var isAuthorised = oga.security.SecurityUtil.isAnyGranted.apply(this, access);
			if (!isAuthorised) {
				goToPageWithNoPermissionsRequired();
			}
		}
	};

	function goToPageWithNoPermissionsRequired() {
		alert("Error. You don't have access to this page.");
		window.location = "/login.html";
		throw new Error("Access denied");
	}
};
