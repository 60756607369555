namespace("oga.nomenclature");

oga.nomenclature.BarcodeCheckStatus = {
	STANDBY: {
		message: "",
		cssClass: ""
	},
	CHECKED: {
		message: "successfully.checked",
		cssClass: "jq-icon-success"
	},
	ADDED: {
		message: "successfully.added",
		cssClass: "jq-icon-success"
	},
	NOT_FOUND: {
		message: "not.found",
		cssClass: "jq-icon-warning"
	},
	ERROR: {
		message: "cannot.add",
		cssClass: "jq-icon-error"
	},
	DUPLICATE: {
		message: "already.added",
		cssClass: "jq-icon-info"
	},
	NO_GAME: {
		message: "barcode.game.is.not.in.order",
		cssClass: "jq-icon-warning"
	}
};
