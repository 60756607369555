namespace("oga.model");

oga.model.BarcodeUnitChangeLogItem = function (dto, type) {
	this.logTime = moment.fromJacksonDateTimeArray(dto.logTime);
	this.userFullName = dto.userFullName;
	this.pointOfSale = dto.pointOfSale ? new oga.model.PointOfSaleLight(dto.pointOfSale) : null;
	this.ipAddress = dto.ipAddress;

	if (type.id == null) {
		if (dto.oldStatusId !== undefined) {
			this.oldStatus = oga.nomenclature.TicketStatusType[dto.oldStatusId];
		}

		if (dto.newStatusId !== undefined) {
			this.newStatus = oga.nomenclature.TicketStatusType[dto.newStatusId];
		}
	} else {
		if (dto.oldStatusId !== undefined) {
			this.oldStatus = oga.nomenclature.PacketStateType[dto.oldStatusId];
		}

		if (dto.newStatusId !== undefined) {
			this.newStatus = oga.nomenclature.PacketStateType[dto.newStatusId];
		}
	}

};
