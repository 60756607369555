namespace("oga.model");

oga.model.GameWithSettingsProfiles = function(dto) {
	var self = this;

	this.id = dto.id;
	this.name = dto.name;
	

	this.singleTicketPrice = dto.singleTicketPrice;

	this.settingsProfiles = ko.observableArray(ko.utils.arrayMap(dto.settingsProfiles, function(settingsProfileDto) {
		return new oga.model.GameSettingsProfile(settingsProfileDto, dto.singleTicketPrice);
	}));

	this.defaultProfile = ko.pureComputed(function() {
		var profiles = self.settingsProfiles();
		for (var i = 0; i <= profiles.length - 1; i++) {
			var profile = profiles[i];
			if (profile.isDefault()) {
				return profile;
			}
		}
	
		return null;
	});

	this.noneDefaultProfiles = ko.pureComputed(function() {
		var profiles = self.settingsProfiles();
		var noneDefaultProfiles = [];
		profiles.forEach(function(profile) {
			if (!profile.isDefault()) {
				noneDefaultProfiles.push(profile);
			}
		});

		return noneDefaultProfiles;
	});
};
