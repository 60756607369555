namespace("oga.binding");

oga.binding.TagInputBinding = {
	init : function(element, valueAccessor) {
		var value = valueAccessor();
		var $element = $(element);

		$element.tagsinput(value);
		
		var tagsArray = [];
		var autocompletedTagIndex = 0;
		var autocompleteSelectionStart = 0;
		var keyCode = 0;
		var input = $element.tagsinput("input");

		input.keydown(function(event) {
			keyCode = event.keyCode;
			if (keyCode != pastel.util.KeyCodes.DOWN_ARROW && keyCode != pastel.util.KeyCodes.UP_ARROW) {
				return;
			}
			event.preventDefault();
			if (tagsArray.length <= 1) {
				return;
			}
			if (keyCode == pastel.util.KeyCodes.DOWN_ARROW) {
				autocompletedTagIndex++;
				if (autocompletedTagIndex == tagsArray.length) {
					autocompletedTagIndex = 0;
				}
				input.val(tagsArray[autocompletedTagIndex].name).prop("selectionStart", autocompleteSelectionStart);
			} else {
				autocompletedTagIndex--;
				if (autocompletedTagIndex == -1) {
					autocompletedTagIndex = tagsArray.length - 1;
				}
				input.val(tagsArray[autocompletedTagIndex].name).prop("selectionStart", autocompleteSelectionStart);
			}
		});

		input.on("input", function() {
			if (!hasEnoughLength()) {
				tagsArray = [];
				keyCode = 0;
				return;
			}
			if (keyCode == pastel.util.KeyCodes.BACKSPACE) {
				autocompletedTagIndex = 0;
				keyCode = 0;
				tagsArray = [];
				return;
			}
			var value = this.value;
			autocompleteSelectionStart = value.length;

			oga.restClient.getResource("/api/point-of-sale-tags", {
				name : value
			}).done(function(resp) {
				if (resp.length != 0 && input.val() == value) {
					if (tagsArray.length == 0) {
						input.val(resp[0].name);
					} else {
						var tagIndexInResp = getTagIndexInResp(resp, tagsArray[autocompletedTagIndex]);
						input.val(resp[tagIndexInResp].name);
					}
					tagsArray = resp;
					input.prop("selectionStart", autocompleteSelectionStart);
				} else {
					tagsArray = [];
				}
			});
		
		});
		
		function hasEnoughLength() {
			return (input.val().length > 2);
		}
		
		function getTagIndexInResp(tags, currentSelectedTag) {
			var indexToReturn = 0;
			$.each(tags, function(index, tag) {
				if (currentSelectedTag.name == tag.name) {
					indexToReturn = index;
				}
			});
			return indexToReturn;
		}

		$element.on("itemAdded", function(event) {
			tagsArray = [];
			keyCode = 0;
			autocompletedTagIndex = 0;
			if (value().indexOf(event.item) === -1) {
				value.push(event.item);
			}
		});
		
		$element.on("itemRemoved", function(event) {
			tagsArray = [];
			value.remove(event.item);			
		});
		ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
			$element.tagsinput("destroy");
		});
	},
	
	update : function(element, valueAccessor) {
		var value = valueAccessor();
		var valueUnwrapped = ko.unwrap(value);
		var $element = $(element);
		var prev = $element.tagsinput("items");
		
		// get all added element
		var added = valueUnwrapped.filter(function(i) {
			return prev.indexOf(i) === -1;
		});

		// get all removed elements
		var removed = prev.filter(function(i) {
			return valueUnwrapped.indexOf(i) === -1;
		});

		// Add new items in modelement as tags
		for (i = 0; i < added.length; i++) {
			$element.tagsinput("add", added[i]);
		}
	
		// Remove tags no longer in bound model(VM tags array)
		for (var i = 0; i < removed.length; i++) {
			$element.tagsinput("remove", removed[i]);
		}

		// Refresh remaining tags
		$element.tagsinput("refresh");
	}
};
