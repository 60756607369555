namespace("oga.viewmodel.pos.admin");

oga.viewmodel.pos.admin.OrderCreateVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];

	self.allEnabledGamesForCurrentPos = ko.observableArray([]);
	self.orderItems = ko.observableArray([new oga.model.OrderCreationItem()]);

	self.currentPosDetails = ko.observable();
	self.isLoading = ko.observable(false);

	self.availableGames = ko.pureComputed(function() {
		var selectedGames = [];
		$.each(self.orderItems(), function(index, item) {
			if (item.selectedGame()) {
				selectedGames.push(item.selectedGame());
			}
		});
		var result = getAvailableGames(selectedGames); 
		return result;
	}).extend({
		deferred: true
	});

	self.totalTicketsCount = ko.pureComputed(function() {
		var totalTicketsCount = 0;
		$.each(self.orderItems(), function(index, item) {
			if (item.isValid()) {
				totalTicketsCount += parseInt(item.quantity());
			}
		});
		return totalTicketsCount;
	});

	self.totalPrice = ko.pureComputed(function() {
		var totalPrice = 0;
		$.each(self.orderItems(), function(index, item) {
			if (item.isValid()) {
				totalPrice += parseInt(item.quantity()) * item.selectedGame().singleTicketPrice;
			}
		});
		return totalPrice;
	});

	self.areAllOrderItemsValid = ko.pureComputed(function() {
		var isAllValid = true;
		$.each(self.orderItems(), function(index, item) {
			if (!item.isValid()) {
				isAllValid = false;
				return;
			}
		});
		return isAllValid;
	});

	this.init = function() {
		getCurrentPosDetails();
		getGames();
		self.orderItems([new oga.model.OrderCreationItem(self.availableGames)]);
	};

	self.addNewOrderItem = function() {
		self.orderItems.push(new oga.model.OrderCreationItem(self.availableGames));
	};

	self.sendOrderRequest = function() {
		self.isLoading(true);
		var dataToSend = JSON.stringify({
			items: getOrderDetails()
		});
		restClient.postResource("api/orders", dataToSend).done(function() {
			self.orderItems([new oga.model.OrderCreationItem(self.availableGames)]);
			oga.router.setHash(oga.authenticatedUser().hashPrefix + "orders");
		}).fail(function(resp) {
			if (resp.status == 409) {
				var errorMessage = resp.responseJSON.message;
				var errorTitle = null;
				if (resp.responseJSON.error == "InsufficientPosBalanceException") {
					errorTitle = "insufficient.balance";
				} else if (resp.responseJSON.error == "OrderFromGameIsDisabledForPointOfSaleException") {
					errorMessage = "game.is.disabled.for.order";
				}
				oga.popupManager.error(errorMessage, errorTitle);
			}
			oga.logger("sending failed");
		}).always(function() {
			self.isLoading(false);
		});
	};

	self.deleteComponent = function(component) {
		self.orderItems.remove(component);
	};

	function getCurrentPosDetails() {
		restClient.getResource("api/points-of-sale/current").done(function(resp) {
			self.currentPosDetails(new oga.model.PointOfSale(resp));
		});
	}

	function getGames() {
		self.isLoading(true);
		oga.restClient.getResource("api/points-of-sale/current/game-settings-profiles").done(function(resp) {
			var allPosProfiles = ko.utils.arrayMap(resp, function(dto) {
				return new oga.model.PosGameSettingsProfile(dto);
			});
			var posProfilesWithEnabledOrdering = [];
			for (var i = 0; i < allPosProfiles.length; i++) {
				var posProfile = allPosProfiles[i];
				if (posProfile.isOrderingEnabled) {
					posProfilesWithEnabledOrdering.push(posProfile);
				}
			}
			
			self.allEnabledGamesForCurrentPos(posProfilesWithEnabledOrdering);
		}).always(function() {
			self.isLoading(false);
		});
	}

	function getAvailableGames(selectedGames) {
		var availableGames = ko.observableArray(getAllEnabledGamesForCurrentPos());
		$.each(selectedGames, function(index, selectedGame) {
			availableGames.remove(selectedGame);
		});
		return availableGames();
	}

	function getAllEnabledGamesForCurrentPos() {
		return [].concat(self.allEnabledGamesForCurrentPos());
	}

	function getOrderDetails() {
		var orderDetails = [];
		$.each(self.orderItems(), function(index, item) {
			orderDetails.push({
				"gameId": item.selectedGame().gameId,
				"quantity": item.quantity()
			});
		});
		return orderDetails;
	}

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};

};
