namespace("oga.component.pagination");

oga.component.pagination.Pagination = function(initialState) {
	var self = this;

	var defaultState = {
		orderBy: "id",
		direction: "asc",
		page: 1,
		pageSize: 0
	};

	if (initialState !== undefined) {
		initialState = $.extend({}, defaultState, initialState);
	} else {
		initialState = defaultState;
	}

	this.orderBy = ko.observable(initialState.orderBy);
	this.direction = ko.observable(initialState.direction);
	this.page = ko.observable(initialState.page);
	this.pageSize = ko.observable(initialState.pageSize);

	this.firstItemNumber = ko.pureComputed(function() {
		return (this.pageSize() * (this.page() - 1)) + 1;
	}, this);

	this.lastItemNumber = ko.pureComputed(function() {
		return (this.firstItemNumber() - 1) + parseInt(this.pageSize());
	}, this);

	this.queryParamsObject = ko.pureComputed(function() {
		return {
			orderBy: self.orderBy(),
			direction: self.direction(),
			page: self.page(),
			pageSize: self.pageSize()
		};
	});

	this.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return self.pageSize() * (self.page() - 1);
	});

	this.toggleOrder = function(orderBy) {
		if (this.orderBy() != orderBy) {
			this.direction("asc");
			this.orderBy(orderBy);
		} else {
			if (this.direction() == "asc") {
				this.direction("desc");
			} else {
				this.direction("asc");
			}
		}
	};

	this.getItemsForPage = function (allItems) {
		var pageSize = self.pageSize();
		var itemStartIndex = (self.page() - 1) * pageSize;

		var itemsForPage = [];

		for (var i = 0; i < pageSize; i++) {
			var item = allItems[itemStartIndex + i];
			if (item !== undefined) {
				itemsForPage.push(item);
			} else {
				break;
			}
		}

		return itemsForPage;
	};
};
