namespace("oga.viewmodel");

oga.viewmodel.AvailabilityVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var PAGE_SIZE = 15;

	self.allGames = ko.observableArray();
	self.selectedGame = ko.observable();
	self.selectedGameSummary = ko.observable();
	self.isGameSelected = ko.observable(false);

	self.barcodeUnitTypeOptions = oga.nomenclature.BarcodeUnitType;
	self.selectedBarcodeUnitType = ko.observable();
	self.barcodeUnitsCount = ko.observable();
	self.barcodeUnits = ko.observable();
	self.gameSpread = ko.observable(null);

	self.orderByBarcodeProp = "bu.barcode_number";

	self.isGamesLoading = ko.observable(false);
	self.isTypeFilterQueryLoading = ko.observable(false);
	self.pagination = new oga.component.pagination.Pagination({
		orderBy: self.orderByBarcodeProp,
		direction: "asc",
		pageSize: PAGE_SIZE
	});
	self.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	this.calculateTotalTicketsAvailable = ko.pureComputed(function () {
		var summary = self.selectedGameSummary();
		if (!summary) {
			return "";
		}
		var total = summary.ticketsCount
		+ summary.banderolsTicketCount
		+ summary.boxesTicketCount
		+ summary.cartonsTicketCount;
		return total;
	});

	this.init = function() {
		loadGames();
		subscriptions.push(self.selectedGame.subscribe(function(newSelectedItem) {
			if (newSelectedItem == undefined) {
				self.isGameSelected(false);
				self.selectedGameSummary(null);
				self.barcodeUnits(null);
				return;
			}
			self.barcodeUnitsCount(null);
			self.selectedBarcodeUnitType(undefined);
			self.pagination.page(1);
			self.isGameSelected(true);
			loadGameSummary();
			loadGameSpread();
		}));
		subscriptions.push(self.selectedBarcodeUnitType.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadBarcodeUnitsByType();
		}).extend({deferred: true}));
	};

	function loadGames() {
		self.isGamesLoading(true);
		restClient.getResource("/api/games?form=light").done(function(resp) {
			self.allGames(resp);
		}).always(function() {
			self.isGamesLoading(false);
		});
	}

	function loadGameSpread() {
		self.isGamesLoading(true);
		self.gameSpread(null);
		var url = pastel.util.StringHelper.format("/api/games/{0}/spread", self.selectedGame().id);
		restClient.getResource(url).done(function(resp) {
			self.gameSpread(resp);
		}).always(function() {
			self.isGamesLoading(false);
		});
	}

	function loadGameSummary() {
		self.isGamesLoading(true);
		self.selectedGameSummary(null);
		var url = pastel.util.StringHelper.format("/api/games/{0}/availability/summary", self.selectedGame().id);
		restClient.getResource(url).done(function(resp) {
			self.selectedGameSummary(resp);
		}).always(function() {
			self.isGamesLoading(false);
		});
	}

	function loadBarcodeUnitsByType() {
		if (self.selectedGame() == undefined) {
			return;
		}
		self.isTypeFilterQueryLoading(true);
		var url = pastel.util.StringHelper.format("/api/games/{0}/availability", self.selectedGame().id);
		restClient.getResource(url, {
			page: self.pagination.page(),
			pageSize: PAGE_SIZE,
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			type: self.selectedBarcodeUnitType()
		}).done(function(resp) {
			self.barcodeUnitsCount(resp.count);
			self.barcodeUnits(resp.items);			
		}).always(function() {
			self.isTypeFilterQueryLoading(false);
		});
	}

	this.canViewSpread = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN
		|| oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_POINT_OF_SALE_CASHIER;
	});

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};

};
