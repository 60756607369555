namespace("oga.model");

oga.model.BarcodeUnitHistory = function (dto) {
	let self = this;
	this.id = dto.id;

	this.type = oga.nomenclature.BarcodeUnitType.filter(function (type) {
		return type.id == dto.typeId;
	})[0];
	this.barcodeNumber = dto.barcodeNumber;

	var unorderedLogs = $.map(dto.changeLogs, function (changeLogDto) {
		return new oga.model.BarcodeUnitChangeLogItem(changeLogDto, self.type);
	});
	
	this.changeLogs = unorderedLogs.sort((a, b) => b.logTime - a.logTime);

	this.parents = this.lots = $.map(dto.parents, function (packetLightDto) {
		return new oga.model.PacketLight(packetLightDto);
	});

};
