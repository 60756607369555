namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.DashboardVM = function() {

	this.init = function() {
		activatePieCharts();
	};

	//TODO rethink this
	function activatePieCharts() {
		oga.restClient.getResource("js/lib/flot-data.js").done(function (script) {
			var head = $("head");
			head.append(script);
		});
	}

};
