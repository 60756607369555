namespace("oga.viewmodel.pos.admin");
oga.viewmodel.pos.admin.ReturnRequestsVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;
	var PAGE_SIZE = 15;
	var ReturnRequestStatus = oga.nomenclature.RequestStatusType;
	
	self.statusOptions = ko.observableArray([
		ReturnRequestStatus.WAITING, ReturnRequestStatus.APPROVED
	]);
	self.selectedStatus = ko.observable(undefined);
	self.returnRequests = ko.observableArray([]);
	
	self.isLoading = ko.observable(false);
	
	self.returnRequestsCount = ko.observable();
	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "createdAt",
		direction: "desc",
		pageSize: PAGE_SIZE
	});	
	
	this.init = function() {
		subscriptions.push(self.selectedStatus.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadReturnRequests();
		}).extend({
			deferred: true
		}));
	};

	function loadReturnRequests() {
		self.isLoading(true);
		restClient.getResource("/api/points-of-sale/current/return-requests", {
			pageSize: PAGE_SIZE,
			page: self.pagination.page(),
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			statusId: self.selectedStatus()
		}).done(function(resp) {
			self.returnRequests(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.ReturnRequest(dto);
			}));
			self.returnRequestsCount(resp.count);
		}).always(function() {
			self.isLoading(false);
		});
	}
	
	self.openReturn = function(id) {
		//TODO add details to the setHash so the redirect is to the particular return and remove the console.log which is an eslint hack
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "return-request-open/" + id);
	};
	self.createReturnRequest = function() {
		oga.router.setHash(oga.authenticatedUser().hashPrefix + "return-request-create");
	};
	
	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
