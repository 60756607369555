namespace("oga.model");

oga.model.Game = function(dto) {
	var self = this;
	this.id = dto.id;
	this.name = dto.name;
	this.status = ko.observable(oga.nomenclature.GameStatus[dto.status.id]);
	this.settingsProfileCount = dto.settingsProfileCount;
	this.singleTicketPrice = dto.singleTicketPrice;
	this.lots = $.map(dto.lots, function(lotDto) {
		return new oga.model.GameLot(lotDto);
	});

	this.isChecked = ko.observable(self.status().dbDescription == "ACTIVE");
	this.isStatusModifiable = ko.observable(self.settingsProfileCount > 0);

	this.lotsConcat = function() {
		var result = "";
		var lotsLen = self.lots.length;
		$.each(self.lots, function(lotIndex, lot) {
			result += lot.number;
			if (lotsLen - 1 > lotIndex) {
				result += ",";
			}
		});

		return result;
	};
};
