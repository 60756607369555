namespace("oga.model");

oga.model.User = function(dto) {
	var self = this;
	this.fullName = dto.fullName;
	this.email = dto.email;

	//for compatibility with the security binding and future additions to authorities
	this.authority = dto.authority;
	this.authorities = [self.authority];

	this.pointOfSale = dto.pointOfSale !== undefined ? new oga.model.PointOfSaleLight(dto.pointOfSale) : null;

	this.hashPrefix = "";
	this.locationName = "";
	this.optionalTitleAndName = "";

	setRoleSpecifics();

	function setRoleSpecifics() {
		var prefix = "#";
		switch (self.authority) {
			case oga.nomenclature.Roles.ROLE_POINT_OF_SALE_ADMIN:
				prefix += "pos-admin";
				self.locationName = self.pointOfSale.name;
				self.optionalTitleAndName = i18next.t("admin") + ": " + self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_POINT_OF_SALE_CASHIER:
				prefix += "pos-cashier";
				self.locationName = self.pointOfSale.name;
				self.optionalTitleAndName = i18next.t("cashier") + ": " + self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION:
				prefix += "lotto-org";
				self.locationName = i18next.t("lottery.organization");
				self.optionalTitleAndName = self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT:
				prefix += "lotto-org";
				self.locationName = i18next.t("lottery.organization.accountant");
				self.optionalTitleAndName = self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS:
				prefix += "lotto-org";
				self.locationName = i18next.t("lottery.organization.logistics");
				self.optionalTitleAndName = self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE:
				prefix += "lotto-org";
				self.locationName = i18next.t("lottery.organization.representative");
				self.optionalTitleAndName = self.fullName;
				break;
			case oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR:
				prefix += "lotto-org";
				self.locationName = i18next.t("lottery.organization.regulator");
				self.optionalTitleAndName = self.fullName;
				break;
		}
		prefix += "/";
		self.hashPrefix = prefix;
	}
};
