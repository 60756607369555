namespace("oga");

oga.Application = function() {

	this.run = function() {
		oga.logger = function(msg) {
			console.log(msg);
		};

		oga.cache = {};
		oga.settings = {
			NAIRA_SYMBOL: "AED ",
			momentDateTimeFormat: "DD/MM/YYYY h:mm a",
			momentDateFormat: "DD/MM/YYYY",
			momentRequestFormat: "YYYY-MM-DD"
		};

		oga.events = new pastel.util.EventQueue();

		oga.restClient = new pastel.web.AjaxRestClient();

		oga.popupManager = setUpPopupManager(new oga.util.SimplePopupManager());
		oga.formatNumber = function(number) {
			if (number != undefined && number != null) {
				return number.toLocaleString().replaceAll(",", " ");
			}
		};

		setupAjax(oga.restClient);

		pastel.knockout.KnockoutHelper.registerLibraryBindings();
		oga.util.setupI18n().then(posti18Init);
	};

	function posti18Init() {
		translateNomenclatures();
		ou.language.subscribe(translateNomenclatures);

		registerCustomComponents();
		setUpMoment();
		setUpKoValidation();
		setUpCustomBindings();

		oga.restClient.getResource("api/users/current").done(function(resp) {

			oga.authenticatedUser = ko.observable(new oga.model.User(resp));

			var layoutVM = new oga.viewmodel.LayoutVM();
			layoutVM.init();
			ko.applyBindings(layoutVM);

			var router = setUpRouter(oga.restClient);
			oga.router = router;
			oga.brand = {
				name:"Mabrook"
			};
			document.getElementsByTagName("title")[0].innerText = oga.brand.name;
			setUpRouterListener(oga.router);
			oga.router.startRouting();
		}).fail(function(resp) {
			oga.logger(resp);
			window.location = "/login.html";
		});
	}

	function setUpPopupManager(popupManager) {
		popupManager.info = function(message, title) {
			var settings = {
				type: "alert",
				title: i18next.t(title ? title : "success"),
				message: i18next.t(message),
				popupDiv: "success"
			};

			return popupManager.addPopupToQue(settings);
		};

		popupManager.error = function(message, title) {
			var settings = {
				type: "alert",
				title: i18next.t(title ? title : "error"),
				message: i18next.t(message),
				popupDiv: "error"
			};

			return popupManager.addPopupToQue(settings);
		};

		popupManager.confirm = function(message, title) {
			var settings = {
				type: "confirm",
				title: i18next.t(title ? title : "confirm"),
				message: i18next.t(message),
				popupDiv: "error"
			};

			return popupManager.addPopupToQue(settings);
		};

		return popupManager;
	}

	function setUpMoment() {
		pastel.moment.MomentHelper.registerJacksonPlugin();
	}

	function setUpKoValidation() {
		if (ou.language() == "pt") {
			ko.validation.locale("pt-PT");
		}

		ko.validation.init({
			insertMessages: false,
			errorsAsTitle: false,
			errorElementClass: "has-error",
			errorClass: "has-error",
			grouping: {
				deep: true,
				observable: true,
				live: true
			}
		});

	}

	function translateNomenclatures() {
		var ns = oga.nomenclature;	    
		var noms = [
			{
				ar: ns.BarcodeUnitType,
				valProps: [["name"]]
			},
			{
				ar: ns.GameStatus.ALL,
				valProps: [["dropdownName"], ["displayName", ou.upper]]
			},
			{
				ar: ns.OrderStatusType.ALL,
				valProps: [["dropdownName"], ["dbName", ou.upper]]
			},
			{
				ar: ns.PosEmployeeType.ALL,
				valProps: [["typeName"], ["dbTypeName", ou.upper]]
			},
			{
				ar: ns.RequestStatusType.ALL,
				valProps: [["dropdownName"], ["dbName", ou.upper]]
			},
			{
				ar: ns.TransactionType.ALL,
				valProps: [["dropdownName"], ["dbName", ou.upper]]
			}
		];

		for (var i = 0; i < noms.length; i++) {
			var nom = noms[i];
			for (var j = 0; j < nom.ar.length; j++) {
				var el = nom.ar[j];
				for (var r = 0; r < nom.valProps.length; r++) {
					var valProp = nom.valProps[r];
					var translation = i18next.t(el.transKey);
					if (valProp.length == 2) {
						translation = valProp[1](translation);
					}
					el[valProp[0]] = translation;
				}
			}
		}
	}

	function setUpCustomBindings() {
		ko.bindingHandlers.dateRangePicker = oga.binding.DateRangePickerBinding;
		ko.bindingHandlers.transactionTypeLabel = oga.binding.TransactionTypeLabelBinding;
		ko.bindingHandlers.hasAnyRole = oga.binding.hasAnyRole;
		ko.bindingHandlers.hasNoRole = oga.binding.hasNoRole;
		ko.bindingHandlers.tagInput = oga.binding.TagInputBinding;
		ko.bindingHandlers.nameIdInput = oga.binding.NameIdInputBinding;
		ko.bindingHandlers.loader = oga.binding.LoaderBinding;
		ko.bindingHandlers.currency = oga.binding.CurrencyBinding;
		ko.bindingHandlers.numberSpaceFormat = oga.binding.NumberSpaceFormatBinding;
		ko.bindingHandlers.switchery = oga.binding.Switchery;
		ko.bindingHandlers.pageSorting = oga.binding.PageSortingBinding;
	}

	function setupAjax(pastelRestClient) {
		jQuery.ajaxSettings.traditional = true;
		function attachErrorHandler(promise, ajaxOptions) {
			promise.ogaHandleErrors = {
				Exception: function() {
					oga.popupManager.error("unexpected.error");
				},
				PointOfSaleHasNoAdminException: function () {
					oga.popupManager.error("pos.must.have.one.admin", "warning");
				}

			};

			promise.handleErrors = function(handledErrors) {
				$.extend(promise.ogaHandleErrors, handledErrors);
				return promise;
			};
			promise.fail(function(xhr) {
				if (xhr.responseJSON !== undefined && xhr.responseJSON.error !== undefined) {
					var error = xhr.responseJSON.error;

					if ($.isFunction(promise.ogaHandleErrors[error])) {
						promise.ogaHandleErrors[error](xhr.responseJSON.message, xhr.responseJSON);
					} else {
						oga.logger("An unhandled error has occured.");
					}
					oga.logger("Error details: " + JSON.stringify(ajaxOptions));
				} else if (xhr.status === 403) {
					$.get("api/users/current").fail(function() {
						oga.popupManager.error("session.has.expired", "warning")
							.always(function () {
								window.location = "/login.html";
							});
					});
				} else {
					if ((xhr.status === 0 && xhr.statusText !== "abort") || xhr.status >= 502) {
						oga.logger("Lost connection");
						oga.popupManager.error("try.again.later", "connection.lost");
						return;
					}
				}
			});
			return promise;
		}

		pastelRestClient.addInterceptor({
			lastAjaxOptions: null,
			beforeExecution: function(ajaxOptions) {
				this.lastAjaxOptions = ajaxOptions;
			},
			afterExecution: function(promise) {
				attachErrorHandler(promise, this.lastAjaxOptions);
			}
		});

		pastelRestClient.addInterceptor({
			beforeExecution: function(options) {
				if (options.method === "GET") {
					return;
				}

				var csrfToken = $.cookie("XSRF-TOKEN");
				options.headers = {
					"X-XSRF-TOKEN": csrfToken
				};
			}
		});
	}

	function setUpRouter(pastelRestClient) {
		var router = new pastel.routing.Router();
		registerRoutes(router);

		var binderListener = new pastel.routing.KnockoutBinderListener({
			contentHolder: "#content",
			layouts: [{
				category: "posProfile",
				template: "views/lotto_org/layouts/pos_profile/pos-profile-view.html",
				contentHolder: "#template-holder",
				viewModel: oga.viewmodel.lottoOrg.PosProfileVM
			}]
		});
		binderListener.setRestClient(pastelRestClient);


		router.addListener(new oga.routing.ParametersInjectingRouterListener());
		router.addListener(binderListener);

		return router;
	}

	function setUpRouterListener(router) {
		router.addListener(new oga.security.SecurityRouterListerner());
		router.addListener({
			onRouteChange: function(route) {
				// args: route, parameters, router
				oga.events.publish(oga.Event.ROUTE_CHANGED, route);
			}
		});
	}

	function registerCustomComponents() {
		ko.components.loaders.unshift(new pastel.knockout.AjaxComponentTemplateLoader());

		ko.components.register("paginator", {
			viewModel: oga.component.pagination.Paginator,
			template: {
				url: "./views/components/paginator.html"
			}
		});
		ko.components.register("TransactionCreationDialog", {
			viewModel: oga.component.TransactionCreationDialog,
			template: {
				url: "./views/components/transaction-creation-dialog.html"
			}
		});
		ko.components.register("loader", {
			template: {
				url: "./views/components/loader.html"
			}
		});

		ko.components.register("pos-users", {
			viewModel: oga.component.PosUsers,
			template: {
				url: "./views/components/pos-users.html"
			}
		});
		ko.components.register("white-message-box", {
			template: {
				url: "./views/components/white-message-box.html"
			}
		});
		ko.components.register("request-open-head", {
			template: {
				url: "./views/components/request-open-head.html"
			}
		});
		ko.components.register("scanner-box", {
			viewModel: oga.component.ScannerBoxComponent,
			template: {
				url: "./views/components/scanner-box.html"
			}
		});
		ko.components.register("order-creation-item", {
			viewModel: oga.component.OrderCreationItem,
			template: {
				url: "./views/components/order-creation-item.html"
			}
		});
	}

	function registerRoutes(router) {
		var viewsDir = "./views/";

		var posViewsDir = viewsDir + "pos/";
		var adminTemplatesDir = posViewsDir + "admin/";
		var posVMs = oga.viewmodel.pos;
		var adminVMs = posVMs.admin;

		var lottoOrgViewsDir = viewsDir + "lotto_org/";
		var lottoOrgVMs = oga.viewmodel.lottoOrg;

		var Roles = oga.nomenclature.Roles;

		var landingPage = "";
		switch (oga.authenticatedUser().authority) {
			case Roles.ROLE_POINT_OF_SALE_ADMIN:
				landingPage = "bulk-sell";
				break;
			case Roles.ROLE_POINT_OF_SALE_CASHIER:
				landingPage = "bulk-sell";
				break;
			case Roles.ROLE_LOTTERY_ORGANIZATION:
				landingPage = "pos";
				break;
			case Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT:
				landingPage = "transactions";
				break;
			case Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS:
				landingPage = "availability";
				break;
			case Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE:
				landingPage = "pos";
				break;
			case Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR:
				landingPage = "reports";
				break;
			default:
				landingPage = "availability";
		}

		router.redirect("", oga.authenticatedUser().hashPrefix + landingPage);

		var commonPosRoutes = [{
			url: "dashboard",
			view: posViewsDir + "dashboard-view.html",
			viewModel: posVMs.DashboardVM
		}, {
			url: "sell",
			view: posViewsDir + "sell-view.html",
			viewModel: posVMs.SellVM
		}, {
			url: "bulk-sell",
			view: posViewsDir + "bulk-sell-view.html",
			viewModel: posVMs.BulkSellVM
		}, {
			url: "check",
			view: "views/check-view.html",
			viewModel: oga.viewmodel.CheckVM
		}, {
			url: "transfers",
			view: posViewsDir + "transfers-view.html",
			viewModel: posVMs.TransfersVM
		}, {
			url: "transfer-open/{id}",
			rules: {
				id: /[0-9]+/
			},
			view: posViewsDir + "transfer-open-view.html",
			viewModel: posVMs.TransferOpenVM
		}, {
			url: "transfer-create",
			view: posViewsDir + "transfer-create-view.html",
			viewModel: posVMs.TransferCreateVM
		}, {
			url: "availability",
			view: "views/availability-view.html",
			viewModel: oga.viewmodel.AvailabilityVM
		}, {
			url: "inventorizations",
			view: viewsDir + "inventorizations-view.html",
			viewModel: oga.viewmodel.InventorizationsVM
		}, {
			url: "inventorization-open/{id}",
			rules: {
				id: /[0-9]+/
			},
			view: viewsDir + "inventorization-open-view.html",
			viewModel: oga.viewmodel.InventorizationOpenVM
		}, {
			url: "reports",
			view: posViewsDir + "reports-view.html",
			viewModel: posVMs.ReportsVM
		}, {
			url: "pos-profile/{id}",
			rules: {
				id: /[0-9]+/
			},
			category: "posProfile",
			children: [{
				url: "requests",
				access: Roles.ROLE_POINT_OF_SALE_ADMIN,
				view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-requests-view.html",
				viewModel: lottoOrgVMs.PosRequestsVM
			}, {
				url: "tickets",
				access: Roles.ROLE_POINT_OF_SALE_ADMIN,
				view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-tickets-view.html",
				viewModel: lottoOrgVMs.PosTicketsVM
			}, {
				url: "winnings",
				view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-winnings-view.html",
				viewModel: lottoOrgVMs.PosWinningsVM
			}, {
				url: "balance",
				access: Roles.ROLE_POINT_OF_SALE_ADMIN,
				view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-balance-view.html",
				viewModel: lottoOrgVMs.PosBalanceVM
			}, {
				url: "settings",
				access: Roles.ROLE_POINT_OF_SALE_ADMIN,
				view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-settings-view.html",
				viewModel: lottoOrgVMs.PosSettingsVM
			}]
		}];

		var posAdminRoutes = [{
			url: "redemption-requests",
			view: adminTemplatesDir + "redemption-requests-view.html",
			viewModel: adminVMs.RedemptionRequestsVM
		}, {
			url: "redemption-request-create",
			view: adminTemplatesDir + "redemption-request-create-view.html",
			viewModel: adminVMs.RedemptionRequestCreateVM
		}, {
			url: "redemption-request-open/{id}",
			rules: {
				id: /[0-9]+/
			},
			view: viewsDir + "redemption-request-open-view.html",
			viewModel: oga.viewmodel.RedemptionRequestOpenVM

		}, {
			url: "return-requests",
			view: adminTemplatesDir + "return-requests-view.html",
			viewModel: adminVMs.ReturnRequestsVM
		}, {
			url: "return-request-create",
			view: adminTemplatesDir + "return-request-create-view.html",
			viewModel: adminVMs.ReturnRequestCreateVM
		}, {
			url: "return-request-open/{id}",
			rules: {
				id: /[0-9]+/
			},
			view: viewsDir + "return-request-open-view.html",
			viewModel: oga.viewmodel.ReturnRequestOpenVM
		}, {
			url: "orders",
			view: adminTemplatesDir + "orders-view.html",
			viewModel: adminVMs.OrdersVM
		}, {
			url: "order-create",
			view: adminTemplatesDir + "order-create-view.html",
			viewModel: adminVMs.OrderCreateVM
		}, {
			url: "order-open/{id}",
			rules: {
				id: /[0-9]+/
			},
			view: adminTemplatesDir + "order-open-view.html",
			viewModel: adminVMs.OrderOpenVM
		}, {
			url: "cashiers",
			view: adminTemplatesDir + "cashiers-view.html",
			viewModel: adminVMs.CashiersVM
		}, {
			url: "blocklist",
			view: lottoOrgViewsDir + "blacklist-view.html",
			viewModel: lottoOrgVMs.BlacklistVM
		}];

		var posAdminRoute = {
			url: "pos-admin",
			access: Roles.ROLE_POINT_OF_SALE_ADMIN,
			children: commonPosRoutes.concat(posAdminRoutes)
		};

		var posCashierRoute = {
			url: "pos-cashier",
			access: [Roles.ROLE_POINT_OF_SALE_ADMIN, Roles.ROLE_POINT_OF_SALE_CASHIER],
			children: commonPosRoutes
		};

		router.addRoute(posAdminRoute);
		router.addRoute(posCashierRoute);

		router.addRoute({
			url: "lotto-org",
			access: Roles.ROLE_LOTTERY_ORGANIZATION,
			children: [{
				//unimplemented
				url: "dashboard",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "dashboard-view.html",
				viewModel: lottoOrgVMs.DashboardVM
			}, {
				url: "availability",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS,
					Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR],
				view: "views/availability-view.html",
				viewModel: oga.viewmodel.AvailabilityVM
			}, {
				url: "blocklist",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "blacklist-view.html",
				viewModel: lottoOrgVMs.BlacklistVM
			}, {
				url: "check",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: "views/check-view.html",
				viewModel: oga.viewmodel.CheckVM
			}, {
				url: "games",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "games-view.html",
				viewModel: lottoOrgVMs.GamesVM
			}, {
				url: "game-winnings",
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR],
				view: lottoOrgViewsDir + "game-winnings-view.html",
				viewModel: lottoOrgVMs.GameWinningsVM
			}, {
				url: "game-profiles",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "game-settings-profile-view.html",
				viewModel: lottoOrgVMs.GameSettingsProfileVM
			}, {
				url: "inventorizations",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION,
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS
				],
				view: viewsDir + "inventorizations-view.html",
				viewModel: oga.viewmodel.InventorizationsVM
			}, {
				url: "inventorization-open/{id}",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION,
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS
				],
				rules: {
					id: /[0-9]+/
				},
				view: viewsDir + "inventorization-open-view.html",
				viewModel: oga.viewmodel.InventorizationOpenVM
			}, {
				url: "order-open/{id}",
				rules: {
					id: /[0-9]+/
				},
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS],
				view: lottoOrgViewsDir + "order-open-view.html",
				viewModel: lottoOrgVMs.OrderOpenVM
			}, {
				url: "orders",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS,
					Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE
				],
				view: lottoOrgViewsDir + "orders-view.html",
				viewModel: lottoOrgVMs.OrdersVM
			}, {
				url: "pos-profile/{id}",
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE],
				rules: {
					id: /[0-9]+/
				},
				category: "posProfile",
				children: [{
					url: "requests",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-requests-view.html",
					viewModel: lottoOrgVMs.PosRequestsVM
				}, {
					url: "settings",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-settings-view.html",
					viewModel: lottoOrgVMs.PosSettingsVM
				}, {
					url: "users",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-users-view.html",
					viewModel: lottoOrgVMs.PosUsersVM
				}, {
					url: "tickets",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-tickets-view.html",
					viewModel: lottoOrgVMs.PosTicketsVM
				}, {
					url: "winnings",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-winnings-view.html",
					viewModel: lottoOrgVMs.PosWinningsVM
				}, {
					url: "balance",
					view: lottoOrgViewsDir + "layouts/pos_profile/templates/pos-profile-balance-view.html",
					viewModel: lottoOrgVMs.PosBalanceVM
				}]
			}, {
				url: "pos",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT,
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS,
					Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE
				],
				view: lottoOrgViewsDir + "pos-view.html",
				viewModel: lottoOrgVMs.PointsOfSaleVM
			}, {
				url: "redemption-request-open/{id}",
				rules: {
					id: /[0-9]+/
				},
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS],
				view: viewsDir + "redemption-request-open-view.html",
				viewModel: oga.viewmodel.RedemptionRequestOpenVM
			}, {
				url: "redemption-requests",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS,
					Roles.ROLE_LOTTERY_ORGANIZATION_REPRESENTATIVE
				],
				view: lottoOrgViewsDir + "redemption-requests-view.html",
				viewModel: lottoOrgVMs.RedemptionRequestsVM
			}, {
				url: "reports",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_REGULATOR],
				view: lottoOrgViewsDir + "reports-regulator-view.html",
				viewModel: lottoOrgVMs.ReportsRegulatorVM
			}, {
				//unimplemented
				url: "reports-open",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "reports-open-view.html",
				viewModel: lottoOrgVMs.PointsOfSaleVM
			}, {
				url: "return-requests",
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS],
				view: lottoOrgViewsDir + "return-requests-view.html",
				viewModel: lottoOrgVMs.ReturnRequestsVM
			}, {
				url: "return-request-open/{id}",
				rules: {
					id: /[0-9]+/
				},
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS],
				view: viewsDir + "return-request-open-view.html",
				viewModel: oga.viewmodel.ReturnRequestOpenVM
			}, {
				url: "transactions",
				access: [Roles.ROLE_LOTTERY_ORGANIZATION, Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT],
				view: lottoOrgViewsDir + "transactions-view.html",
				viewModel: lottoOrgVMs.TransactionsVM
			}, {
				url: "users",
				access: Roles.ROLE_LOTTERY_ORGANIZATION,
				view: lottoOrgViewsDir + "lotto-org-users-view.html",
				viewModel: lottoOrgVMs.LottoOrgUsersVM
			}, {
				url: "barcode-unit-log",
				access: [
					Roles.ROLE_LOTTERY_ORGANIZATION, 
					Roles.ROLE_LOTTERY_ORGANIZATION_ACCOUNTANT, 
					Roles.ROLE_LOTTERY_ORGANIZATION_LOGISTICS
				],
				view: lottoOrgViewsDir + "barcode-unit-check-logs-view.html",
				viewModel: lottoOrgVMs.BarcodeUnitLogsVM
			}]
		});

		router.addRoute({
			url: "account",
			view: viewsDir + "account-view.html",
			viewModel: oga.viewmodel.AccountVM
		});

	}
};
