namespace("oga.model");

oga.model.TransferUser = function(dto) {
	this.id = dto.id;
	this.fullName = dto.fullName;
};

oga.model.Transfer = function(dto) {
	this.id = dto.id;
	this.senderUser = new oga.model.TransferUser(dto.senderUser);
	this.recipientUser = new oga.model.TransferUser(dto.recipientUser);
	this.ticketsCount = dto.ticketsCount;
	this.createdAt = moment.fromJacksonDateTimeArray(dto.createdAt);

	if (dto.barcodeUnits !== undefined) {
		this.barcodeUnits = ko.utils.arrayMap(dto.barcodeUnits, function(barcodeUnitDto) {
			return new oga.model.CheckableBarcodeUnit(barcodeUnitDto);
		});
	}
};
