namespace("oga.viewmodel.pos");

oga.viewmodel.pos.BulkSellVM = function() {
	var self = this;
	var subscriptions = [];
	var restClient = oga.restClient;

	self.barcodeInput = ko.observable().extend({
		required: true,
		pattern: {
			message: i18next.t("must.be.at.least.eight.digits"),
			params: "^\\d{8,}$"
		}
	});
	self.barcodeToCheck = ko.observable().extend({notify: "always"});
	self.scannedBarcodeUnits = ko.observableArray([]);
	self.isLoading = ko.observable(false);
	self.isMessageBoxVisible = ko.observable(false);
	self.messageBoxText = ko.observable("");
	
	self.totalTicketsCount = ko.pureComputed(function() {
		var totalTicketsCount = 0;
		$.each(self.scannedBarcodeUnits(), function(index, barcodeUnit) {
			totalTicketsCount += barcodeUnit.ticketsCount;
		});
		return totalTicketsCount;
	});
	
	self.totalPrice = ko.pureComputed(function() {
		var total = 0;
		$.each(self.scannedBarcodeUnits(), function(index, barcodeUnit) {
			total += barcodeUnit.ticketsCount * barcodeUnit.game.ticketPrice;
		});
		return total;
	});
	
	this.init = function() {
		self.barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			minBarcodeLength: 12,
			nameSpace: "BulkSellVM",
			scannerInputStorer: self.barcodeToCheck
		});
		self.barcodeScannerMannager.start();

		subscriptions.push(self.barcodeToCheck.subscribe(function(newValue) {
			self.barcodeInput(newValue);
			checkBarcode();
		}));
	};

	function checkBarcode() {
		if (self.isLoading()) {
			return;
		}
		if (!self.barcodeInput.isValid()) {
			return;
		}
		if (isBarcodeScanned(self.barcodeInput())) {
			return;
		}
		self.isLoading(true);
		self.isMessageBoxVisible(false);
		var barcode = self.barcodeToCheck();
		var url = pastel.util.StringHelper.format("api/barcode-units/{0}/sellable", barcode);
		restClient.getResource(url).done(function(resp) {
			self.scannedBarcodeUnits.unshift(resp);
		}).handleErrors({
			BarcodeUnitNotFoundException: function() {
				self.messageBoxText(i18next.t("no.such.barcode.unit") + " - " + barcode);
			},
			InvalidBarcodeUnitException: function() {
				var msg = pastel.util.StringHelper.format(i18next.t("barcode.unit.could.not.be.sold"), barcode);
				self.messageBoxText(msg);
			}
		}).fail(function() {
			self.isMessageBoxVisible(true);
		}).always(function() {
			self.isLoading(false);
		});
	}

	self.setBarcodeToCheck = function() {
		self.barcodeToCheck(self.barcodeInput());
	};

	self.sellBarcodeUnit = function() {
		var barcodes = getAllBarcodesToSell();
		var dataToSend = JSON.stringify({
			barcodeNumbers: barcodes
		});
		self.isMessageBoxVisible(false);
		restClient.postResource("api/barcode-units/sale", dataToSend).done(function() {
			self.scannedBarcodeUnits([]);
			self.messageBoxText(i18next.t("bulk.sell.success"));
		}).handleErrors({
			BarcodeUnitNotFoundException: function(message) {
				var msg = i18next.t("barcode.unit.not.found") + " " + message;
				self.messageBoxText(msg);
			},
			InvalidBarcodeUnitException: function() {
				//TODO add more details used from the backend message?
				var msg = i18next.t("invalid.barcode.unit");
				self.messageBoxText(msg);
			}
		}).always(function() {
			self.barcodeInput("");
			self.isMessageBoxVisible(true);
			self.barcodeInput.isModified(false);
		});
	};

	self.deleteBarcodeUnit = function() {
		self.scannedBarcodeUnits.remove(this);
	};

	function isBarcodeScanned(barcodeNumber) {
		var temp = false;
		$.each(self.scannedBarcodeUnits(), function(index, barcodeUnit) {
			if (barcodeUnit.barcodeNumber == barcodeNumber) {
				temp = true;
				return;
			}
		});
		return temp;
	}

	function getAllBarcodesToSell() {
		var barcodes = [];
		$.each(self.scannedBarcodeUnits(), function(index, barcodeUnit) {
			barcodes.push(barcodeUnit.barcodeNumber);
		});
		return barcodes;
	}

	self.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
		self.barcodeScannerMannager.dispose();
	};
};
