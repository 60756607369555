namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosSettingsVM = function() {
	var self = this;
	var allGames = [];

	this.pos = null;
	this.posGamesSettings = ko.observableArray([]);
	this.newPaymentTypeDetails = {};
	this.isEdittingProfiles = ko.observable(false);
	this.isEdittingPaymentType = ko.observable(false);
	this.isEdittingRepresentative = ko.observable(false);
	this.isLoading = ko.observable(false);
	this.representatives = ko.observableArray([]);
	this.selectedRepresentative = ko.observable(null);

	this.preEditPosGamesSettings = ko.observable(null);


	this.init = function() {
		self.pos = oga.cache.posProfileVM.pos;
		loadAllGames().done(loadPosSettings);
		loadRepresentatives();
	};

	this.editRepresentative = function() {
		self.isEdittingRepresentative(true);
	};

	this.cancelEditRepresentative = function() {
		self.isEdittingRepresentative(false);
		self.selectedRepresentative(null);
	};

	this.saveRepresentative = function() {
		oga.restClient.patchResource("api/points-of-sale/" + self.pos().id + "/representative", {
			representativeId: self.selectedRepresentative() != undefined ? self.selectedRepresentative().id : null
		}).done(function() {
			if (self.selectedRepresentative() != undefined) {
				self.pos().representativeName(self.selectedRepresentative().fullName);
			} else {
				self.pos().representativeName(undefined);
			}
		}).always(function() {
			self.isEdittingRepresentative(false);
		});
	};

	function loadRepresentatives() {
		oga.restClient.getResource("api/users/representatives").done(function(response) {
			self.representatives(ko.utils.arrayMap(response.items, function(posEmployeeDto) {
				return new oga.model.PosEmployee(posEmployeeDto);
			}));
			var result = self.representatives().filter(rep => {
				return rep.id === self.pos().representativeId();
			});
			self.selectedRepresentative(result[0]);
		});
	}

	this.editPaymentType = function() {
		self.newPaymentTypeDetails = {
			creditLimit: ko.observable(self.pos().creditLimit()).extend({
				number: true,
				min: 1,
				maxLength: 12
			}),
			isNewStatusCredit: ko.observable(self.pos().creditLimit() > 0)
		};

		self.newPaymentTypeDetails.canSave = ko.pureComputed(function() {
			if (!self.newPaymentTypeDetails.isNewStatusCredit()) {
				return true;
			} else {
				return self.newPaymentTypeDetails.creditLimit.isValid();
			}
		});

		self.isEdittingPaymentType(true);
	};

	this.cancelEditPaymentType = function() {
		self.newCreditLimit = {};
		self.isEdittingPaymentType(false);
	};

	this.saveEdittedPaymentType = function() {
		var newCreditLimit = 0;
		if (self.newPaymentTypeDetails.isNewStatusCredit()) {
			newCreditLimit = parseFloat(self.newPaymentTypeDetails.creditLimit()).toFixed(2);
		}

		var settingsData = {
			creditLimit: newCreditLimit,
			gameSettingsProfileIds: getSettingsProfilesAr()
		};

		updatePosSettings(settingsData).done(function() {
			self.isEdittingPaymentType(false);
			self.pos().creditLimit(settingsData.creditLimit);
			self.newPaymentTypeDetails = {};
		});
	};

	this.editProfiles = function() {
		self.preEditPosGamesSettings(clonePosGamesSettings());
		self.isEdittingProfiles(true);
		getDefaultGameProfileForMissing().forEach(function(profile) {
			self.posGamesSettings.push(profile);
		});
	};

	this.cancelEditProfiles = function() {
		self.isEdittingProfiles(false);
		self.posGamesSettings(self.preEditPosGamesSettings());
	};

	this.saveEdittedProfiles = function() {
		var settingsData = {
			creditLimit:  self.pos().creditLimit(),
			gameSettingsProfileIds: getSettingsProfilesAr()
		};

		updatePosSettings(settingsData).done(function() {
			self.preEditPosGamesSettings(null);
			self.isEdittingProfiles(false);
		});
	};

	this.canEdit = ko.computed(function() {
		return oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION;
	});

	this.togglePosEnabledState = function() {
		var newEnabledState = !self.pos().isEnabled();
		var settingsData = {
			isEnabled: newEnabledState
		};

		self.isLoading(true);
		oga.restClient.patchResource("api/points-of-sale/" + self.pos().id + "/enabled", JSON.stringify(settingsData))
			.done(function() {
				self.pos().isEnabled(newEnabledState);
			}).always(function() {
				self.isLoading(false);
			});
	};

	function updatePosSettings(settingsData) {
		self.isLoading(true);
		var jsonSettingsData = JSON.stringify(settingsData);
		return oga.restClient.patchResource("api/points-of-sale/" + self.pos().id + "/settings", jsonSettingsData)
			.always(function() {
				self.isLoading(false);
			});
	}

	function getSettingsProfilesAr() {
		var gamesSettingsProfileIds = [];
		self.posGamesSettings().forEach(function(posGameSettings) {
			gamesSettingsProfileIds.push(posGameSettings.selectedProfile().id);
		});

		return gamesSettingsProfileIds;
	}

	function loadAllGames() {
		var promise = $.Deferred();
		oga.restClient.getResource("api/games/profiles/detailed").done(function(resp) {
			allGames = ko.utils.arrayMap(resp, function(gameDto) {
				return new oga.model.GameWithSettingsProfiles(gameDto);
			});
			promise.resolve();
		});
		return promise;
	}

	function loadPosSettings() {
		oga.restClient.getResource("api/points-of-sale/" + self.pos().id + "/game-settings-profiles").done(function(resp) {
			self.posGamesSettings([]);
			resp.forEach(function(profile) {
				self.posGamesSettings.push(getGameAndSelectedProfile(profile.id));
			});
		});
	}


	function getDefaultGameProfileForMissing() {
		var result = [];
		var selectedProfileIds = getSettingsProfilesAr();

		for (var i = 0; i < allGames.length; i++) {
			var game = allGames[i];
			var hasProfile = false;
			for (var j = 0; j < game.settingsProfiles().length; j++) {
				var profile = game.settingsProfiles()[j];
				if (selectedProfileIds.indexOf(profile.id) > -1) {
					hasProfile = true;
					break;
				}
			}
			if (!hasProfile && self.isEdittingProfiles()) {
				result.push({
					game: game,
					selectedProfile: ko.observable(game.settingsProfiles()[0])
				});
			}
		}

		return result;
	}

	function getGameAndSelectedProfile(profileId) {
		for (var i = 0; i < allGames.length; i++) {
			var game = allGames[i];
			for (var j = 0; j < game.settingsProfiles().length; j++) {
				var profile = game.settingsProfiles()[j];
				if (profile.id == profileId) {
					return {
						game: game,
						selectedProfile: ko.observable(profile)
					};
				}
			}
		}
	}

	function clonePosGamesSettings() {
		var posGamesSettingsClone = [];
		self.posGamesSettings().forEach(function(posGameSettings) {
			var gameClone = {};
			gameClone.game = posGameSettings.game;
			gameClone.selectedProfile = ko.observable(posGameSettings.selectedProfile());
			posGamesSettingsClone.push(gameClone);
		});
		return posGamesSettingsClone;
	}

};
