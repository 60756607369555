namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.PosBalanceVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var posId = null;
	var PAGE_SIZE = 10;

	this.pagination = new oga.component.pagination.Pagination({
		pageSize: PAGE_SIZE
	});
	this.numberOfResultsOnPreviousPages = ko.pureComputed(function() {
		return PAGE_SIZE * (self.pagination.page() - 1);
	});

	var transactionType = oga.nomenclature.TransactionType;
	
	self.transactions = ko.observable();
	self.transactionsCount = ko.observable();
	self.isLoading = ko.observable(false);
	self.hasPaymentHistory = ko.observable(true);

	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "timestamp",
		direction: "desc",
		pageSize: PAGE_SIZE
	});

	this.init = function(params) {
		posId = params.id;

		subscriptions.push(ko.computed(function() {
			loadTransactions();
		}).extend({
			deferred: true
		}));
	};

	function loadTransactions() {
		self.isLoading(true);
		restClient.getResource("api/points-of-sale/" + posId + "/transactions", {
			page: self.pagination.page(),
			pageSize: PAGE_SIZE,
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction()
		}).done(function(resp) {
			self.transactions(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.Transaction(dto);
			}));
			self.transactionsCount(resp.count);
			if (resp.count > 0 && self.hasPaymentHistory() == false) {
				self.hasPaymentHistory(true);
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	this.isBalancePositive = function(type) {
		return type == transactionType.DEPOSIT || type == transactionType.RETURN 
		|| type == transactionType.REDEMPTION || type == transactionType.CANCELED_ORDER;
	};

	this.open = function(request) {
		var endpoint = "";
		switch (request.type()) {
			case transactionType.ORDER:
				endpoint = "order-open/";
				break;
			case transactionType.RETURN:
				endpoint = "return-request-open/";
				break;
			case transactionType.REDEMPTIONS:
				endpoint = "redemption-request-open/";
				break;
		}
		var urlPrefix = "pos-admin/";
		if (oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION) {
			urlPrefix = "lotto-org/";
		}
		oga.router.setHash(urlPrefix + endpoint + request.requestId);
	};

	this.openRedemption = function(redemption) {
		oga.router.setHash("lotto-org/redemption-request-open/" + redemption.id);
	};
};
