namespace("oga.nomenclature");

oga.nomenclature.GameStatus = {
	1: {
		id: 1,
		dbDescription: "DRAFT",
		displayName: "", //DRAFT
		cssClass: "label-default",
		dropdownName: "", //draft
		transKey: "draft"
	},
	2: {
		id: 2,
		dbDescription: "AWAITING_ACTIVATION",
		displayName: "", //WAITING
		cssClass: "label-warning",
		dropdownName: "", //waiting
		transKey: "waiting"
	},
	3: {
		id: 3,
		dbDescription: "ACTIVE",
		displayName: "", //ACTIVE
		cssClass: "label-success",
		dropdownName: "", //active
		transKey: "active"
	},
	4: {
		id: 4,
		dbDescription: "INACTIVE",
		displayName: "", //INACTIVE
		cssClass: "label-default",
		dropdownName: "", //inactive
		transKey: "inactive"
	},
	get DRAFT() {
		return this[1];
	},
	get WAITING() {
		return this[2];
	},
	get ACTIVE() {
		return this[3];
	},
	get INACTIVE() {
		return this[4];
	},
	get ALL() {
		return [this[1], this[2], this[3], this[4]];
	}

};

ou.language.subscribe(function() {
	var GameStatus = oga.nomenclature.GameStatus;
	for (var i = 0; i < GameStatus.ALL.length; i++) {
		var gs = GameStatus.ALL[i];
		gs.displayName = i18next.t(gs.transKey).toUpperCase();
		gs.dropdownName = i18next.t(gs.transKey);
	}
});

