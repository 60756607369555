namespace("oga.model");

oga.model.GameStatistics = function(dto) {
	this.soldCount = dto.soldCount;
	this.redeemedCount = dto.redeemedCount;
	this.paidCount = dto.paidCount;
	this.orderedCount = dto.orderedCount;
	this.returnedCount = dto.returnedCount;
	this.canceledCount = dto.canceledCount;

};
