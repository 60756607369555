namespace("oga.viewmodel.lottoOrg");

oga.viewmodel.lottoOrg.TransactionsVM = function() {
	var self = this;
	var restClient = oga.restClient;
	var subscriptions = [];
	var PAGE_SIZE = 15;
	var transactionType = oga.nomenclature.TransactionType;
	
	self.transactionsOptions = ko.observableArray([
		transactionType.DEPOSIT, transactionType.WITHDRAW, transactionType.ORDER, transactionType.REDEMPTION, transactionType.RETURN
	]);
	
	self.transactions = ko.observable();
	self.transactionsCount = ko.observable();
	self.selectedType = ko.observable(undefined);
	self.dateRange = new oga.model.DateRange(moment().subtract(6, "days"), moment());
	self.tags = ko.observableArray([]);
	self.posNameIdParam = ko.observable();

	self.isLoading = ko.observable(false);
	self.isDialogVisible = ko.observable(false);
	self.hasPaymentHistory = ko.observable(true);

	self.pagination = new oga.component.pagination.Pagination({
		orderBy: "timestamp",
		direction: "desc",
		pageSize: PAGE_SIZE
	});

	this.init = function() {		
		checkForPaymentHistory();
		
		subscriptions.push(self.tags.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.selectedType.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.dateRange.fromDate.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(self.dateRange.toDate.subscribe(function() {
			self.pagination.page(1);
		}));
		subscriptions.push(ko.computed(function() {
			loadTransactions();
		}).extend({
			deferred: true
		}));
	};

	self.showDialog = function() {
		self.isDialogVisible(true);
	};
	self.onTransactionCreated = function() {
		resetFilters();
		self.isDialogVisible(false);
	};
	self.onCloseClicked = function() {
		self.isDialogVisible(false);
	};

	function loadTransactions() {
		self.isLoading(true);
		restClient.getResource("/api/transactions", {
			page: self.pagination.page(),
			pageSize: PAGE_SIZE,
			orderBy: self.pagination.orderBy(),
			direction: self.pagination.direction(),
			transactionTypeId: self.selectedType(),
			fromDate: self.dateRange.fromDate().format(oga.settings.momentRequestFormat),
			toDate: self.dateRange.toDate().format(oga.settings.momentRequestFormat),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam()
		}).done(function(resp) {
			self.transactions(ko.utils.arrayMap(resp.items, function(dto) {
				return new oga.model.Transaction(dto);
			}));
			self.transactionsCount(resp.count);
			if (resp.count > 0 && self.hasPaymentHistory() == false) {
				self.hasPaymentHistory(true);
			}
		}).always(function() {
			self.isLoading(false);
		});
	}

	function checkForPaymentHistory() {
		restClient.getResource("/api/transactions", {
			page: 1,
			pageSize: 1
		}).done(function(resp) {
			if (resp.count == 0) {
				self.hasPaymentHistory(false);
			}
		});
	}

	this.exportExcel = function() {
		var params = {
			transactionTypeId: self.selectedType(),
			fromDate: self.dateRange.fromDate().format(oga.settings.momentRequestFormat),
			toDate: self.dateRange.toDate().format(oga.settings.momentRequestFormat),
			pointOfSaleTags: self.tags(),
			posNameOrId: self.posNameIdParam(),
			lang: oga.util.i18n.getLanguage()
		};

		var urlQueryParams = $.param(params, true);
		location.href = "/api/transactions/xls?" + urlQueryParams;
	};

	function resetFilters() {	
		self.dateRange.fromDate(moment().subtract(1, "weeks"));
		self.dateRange.toDate(moment());
		self.selectedType(undefined);
		self.tags([]);
		self.pagination.page(1);
	}

	this.open = function(request) {
		var endpoint = "";
		switch (request.type()) {
			case transactionType.ORDER:
				endpoint = "order-open/";
				break;
			case transactionType.CANCELED_ORDER:
				endpoint = "order-open/";
				break;
			case transactionType.RETURN:
				endpoint = "return-request-open/";
				break;
			case transactionType.REDEMPTION:
				endpoint = "redemption-request-open/";
				break;
		}
		var urlPrefix = "pos-admin/";
		if (oga.authenticatedUser().authority == oga.nomenclature.Roles.ROLE_LOTTERY_ORGANIZATION) {
			urlPrefix = "lotto-org/";
		}
		oga.router.setHash(urlPrefix + endpoint + request.requestId);
	};

	this.openProfile = function(profileId) {
		oga.router.setHash("#lotto-org/pos-profile/" + profileId + "/tickets");
	};

	this.isBalancePositive = function(type) {
		return type == transactionType.DEPOSIT || type == transactionType.RETURN 
		|| type == transactionType.REDEMPTION || type == transactionType.CANCELED_ORDER;
	};

	this.dispose = function() {
		$.each(subscriptions, function(index, subscription) {
			subscription.dispose();
		});
	};
};
