namespace("oga.model");

oga.model.BlacklistedBarcodeUnit = function(dto) {
	this.id = dto.id;
	this.barcode = dto.barcodeNumber;

	this.type = ko.observable(oga.nomenclature.BarcodeUnitType.filter(function (type) {
		return type.dropdownValue == dto.packetType;
	})[0]);

	this.lotNumber = dto.lotNumber;
	this.gameName = dto.gameName;
	if (dto.reasonId !== undefined) {
		this.reason = ko.observable(oga.nomenclature.BlacklistReason[dto.reasonId]);
	} else {
		this.reason = ko.observable(oga.nomenclature.BlacklistReason[1]);

	}
	this.isChecked = ko.observable(false);
};
