namespace("oga.viewmodel.pos.admin");

oga.viewmodel.pos.admin.OrderOpenVM = function() {
	var self = this;
	var orderId = null;
	var barcodeScannerMannager = null;
	var nameSpace = "OrdersOpenVM";
	var BarcodeCheckStatus = oga.nomenclature.BarcodeCheckStatus;

	this.order = ko.observable(null);
	this.isAcceptingOrder = ko.observable(false);
	this.isCancelingOrder = ko.observable(false);
	this.isOrderNotFound = ko.observable(false);
	this.isOrderForbidden = ko.observable(false);

	this.enteredBarcode = ko.observable("");

	this.lastCheckBarcodeDetails = ko.observable({
		barcodeCheckStatus: BarcodeCheckStatus.STANDBY
	});
	this.orderItemOptions = ko.pureComputed(function() {
		var order = self.order();
		var gameOptions = [{
			name: "All",
			value: null
		}];

		if (order !== null) {
			order.orderItems.forEach(function(orderItem) {
				gameOptions.push({
					name: orderItem.game.name,
					value: orderItem
				});
			});
		}

		return gameOptions;

	});
	this.selectedOrderItem = ko.observable(self.orderItemOptions()[0]);


	this.filteredOrderItems = ko.pureComputed(function() {
		var order = self.order();
		var selectedOrderItem = self.selectedOrderItem();

		if (order !== null) {
			if (selectedOrderItem.value === null) {
				return order.orderItems;
			} else {
				for (var i = 0; i < order.orderItems.length; i++) {
					var orderItem = order.orderItems[i];
					if (orderItem.game.id == selectedOrderItem.value.game.id) {
						return [orderItem];
					}
				}

				return [];
			}
		}
	});

	this.init = function(params) {
		orderId = params.id;
		barcodeScannerMannager = new oga.util.BarcodeScannerManager({
			nameSpace: nameSpace,
			minBarcodeLength: 12,
			scannerInputStorer: self.enteredBarcode,
			onScanCallback: self.checkBarcodeUnit
		});

		barcodeScannerMannager.start();
		loadOrder();
	};

	this.canCancelOrder = ko.pureComputed(function() {
		var order = self.order();
		if (order === null) {
			return false;
		}
		return order.status() == oga.nomenclature.OrderStatusType.WAITING;
	});

	this.canEditOrder = ko.pureComputed(function() {
		var order = self.order();
		if (order === null) {
			return false;
		}
		return order.status() == oga.nomenclature.OrderStatusType.PROCESSED;
	});

	this.removeBarcodeUnit = function(barcodeUnit) {
		self.order().barcodeUnits.remove(barcodeUnit);
	};

	this.checkBarcodeUnit = function() {
		var barcode = self.enteredBarcode();
		var barcodeUnits = self.order().barcodeUnits();
		var barcodeCheckDetails = {
			barcode: barcode,
			barcodeCheckStatus: BarcodeCheckStatus.STANDBY
		};

		var hasNoSuchBarcodeUnit = true;
		for (var i = 0; i < barcodeUnits.length; i++) {
			var barcodeUnit = barcodeUnits[i];
			if (barcodeUnit.barcodeNumber == barcode) {
				barcodeUnit.isChecked(true);
				hasNoSuchBarcodeUnit = false;
				break;
			}
		}

		barcodeCheckDetails.barcodeCheckStatus = hasNoSuchBarcodeUnit ? BarcodeCheckStatus.NOT_FOUND : BarcodeCheckStatus.CHECKED;
		self.lastCheckBarcodeDetails(barcodeCheckDetails);

	};


	this.acceptOrder = function() {
		self.isAcceptingOrder(true);
		oga.restClient.postResource("api/orders/" + orderId + "/reception").done(function() {
			self.order().status(oga.nomenclature.OrderStatusType.ACCEPTED);
		}).always(function() {
			self.isAcceptingOrder(false);
		});
	};

	this.cancelOrder = function() {
		var msg = "cancel.order.confirm.msg";
		oga.popupManager.confirm(msg).done(function() {
			doCancelOrder();
		});
	};

	function doCancelOrder() {
		self.isCancelingOrder(true);
		oga.restClient.postResource("api/orders/" + orderId + "/cancel").done(function() {
			self.order().status(oga.nomenclature.OrderStatusType.CANCELED);
		}).always(function() {
			self.isCancelingOrder(false);
		});
	}

	function loadOrder() {
		oga.restClient.getResource("api/orders/" + orderId).done(function(resp) {
			self.order(new oga.model.Order(resp));
		}).fail(function(resp) {
			if (resp.responseJSON !== undefined) {
				switch (resp.responseJSON.error) {
					case "EntityNotFoundException":
						self.isOrderNotFound(true);
						break;
					case "PointOfSaleMismatchException":
						self.isOrderNotFound(true);
						break;
					default:
						oga.logger("unexpected load order error");
				}
			}
		});
	}

	this.dispose = function() {
		barcodeScannerMannager.dispose();
	};

};
